import Actions from "../Action/Actions";

const initialState = {
  Gener: [],
  BeverageType: [],
};

const MasterDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_ALL_GENER":
      return {
        ...state,
        Gener: [...action.payload],
      };
    case "GET_BEVERAGE_TYPE":
      return {
        BeverageType: action.payload.gener,
      };
    default:
      return state;
  }
};

export default MasterDataReducer;
