import React from "react";

const Pagination = ({
  currentPage,
  artistsPerPage,
  totalArtists,
  paginate,
  filter,
}) => {
  const pageNumbers = [];
  const totalPages = Math.ceil(totalArtists / artistsPerPage);

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination">
        <li className="page-item">
          <button
            onClick={() => currentPage > 1 && paginate(currentPage - 1, filter)}
            className={`page-link ${currentPage === 1 ? "disabled" : ""}`}
          >
            Previous
          </button>
        </li>
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`page-item ${currentPage === number ? "active" : ""}`}
          >
            <button
              onClick={() => paginate(number, filter)}
              className="page-link"
            >
              {number}
            </button>
          </li>
        ))}
        <li className="page-item">
          <button
            onClick={() =>
              currentPage < totalPages && paginate(currentPage + 1, filter)
            }
            className={`page-link ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
