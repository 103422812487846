import Actions from "./Actions";

export const startLoader = () => {
  return {
    type: Actions.START_LODER,
  };
};

export const stopLoader = () => {
  return {
    type: Actions.STOP_LOADER,
  };
};
