import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import IndivualReview from "../../../../Components/Body/Venues/TabsSection/Reviews/IndivualReview";
import RatingStars from "../../../../Components/Body/Venues/TabsSection/Reviews/RatingStars";
import Star from "../../../../Components/Body/Venues/TabsSection/Reviews/FilledStar";
import { getOverallReviewForArtistBasedOnIdAction } from "../../../../Redux/Action/Artists";

const ArtistReveiw = ({ id }) => {
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.Auth.authToken);
  const OverallReviewsData = useSelector((state) => state.Artists.overallReview);

  //way to find stars filled ratio i.e half, full, and empty
  const rating = OverallReviewsData?.ratings?.artist;
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating - fullStars >= 0.5;
  let blankStar = 5 - fullStars - (hasHalfStar ? 1 : 0);

  useEffect(() => {
    dispatch(getOverallReviewForArtistBasedOnIdAction(id, authToken));
  }, [id]);

  return (
    <>
      {OverallReviewsData?.ratings?.reviews_count > 0 ? (
        <section className="people-saying">
          <div className="container">
            <h2 className="saying-main-heading text-danger">
              What {OverallReviewsData?.ratings?.reviews_count} people are
              saying
            </h2>
            <div className="row ">
              <div className="col-md-8">
                <div>
                  <h4 className="saying-sub-heading">
                    Overall ratings and reviews
                  </h4>
                  <p className="saying-para">
                    Following are the average rating given by the user to this
                    venue
                  </p>
                  <div className="row align-items-baseline">
                    <div className="col-md-4 p-2">
                      <div className="">
                        <div>
                          <RatingStars
                            hasHalfStar={hasHalfStar}
                            blankStar={blankStar}
                            fullStars={fullStars}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="">
                        <p className="stars-heading">
                          {parseFloat(
                            OverallReviewsData?.ratings?.artist.toFixed(1)
                          )}{" "}
                          rating to the venue based on recent ratings
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="processbars mt-5">
                  <div className="d-flex progress-bar-list align-items-center">
                    <span>5</span> <Star />
                    <div className="progress">
                      <div
                        className="progress-bar bg-danger"
                        role="progressbar"
                        style={{
                          width: `${OverallReviewsData?.ratings?.["5"]}%`,
                        }}
                        aria-valuenow={`${OverallReviewsData?.ratings?.["5"]}%`}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="d-flex progress-bar-list align-items-center">
                    <span>4</span> <Star />
                    <div className="progress">
                      <div
                        className="progress-bar bg-danger"
                        role="progressbar"
                        style={{
                          width: `${OverallReviewsData?.ratings?.["4"]}%`,
                        }}
                        aria-valuenow={`${OverallReviewsData?.ratings?.["4"]}%`}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="d-flex progress-bar-list align-items-center">
                    <span>3</span> <Star />
                    <div className="progress">
                      <div
                        className="progress-bar bg-danger"
                        role="progressbar"
                        style={{
                          width: `${OverallReviewsData?.ratings?.["3"]}%`,
                        }}
                        aria-valuenow={`${OverallReviewsData?.ratings?.["3"]}%`}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="d-flex progress-bar-list align-items-center">
                    <span>2</span> <Star />
                    <div className="progress">
                      <div
                        className="progress-bar bg-danger"
                        role="progressbar"
                        style={{
                          width: `${OverallReviewsData?.ratings?.["2"]}%`,
                        }}
                        aria-valuenow={`${OverallReviewsData?.ratings?.["2"]}%`}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="d-flex progress-bar-list align-items-center">
                    <span>1</span> <Star />
                    <div className="progress">
                      <div
                        className="progress-bar bg-danger"
                        role="progressbar"
                        style={{
                          width: `${OverallReviewsData?.ratings?.["1"]}%`,
                        }}
                        aria-valuenow={`${OverallReviewsData?.ratings?.["1"]}%`}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <p className="px-3">No Overall ratings for this venue</p>
      )}

      <h6 className="px-2">Reviews by Customers</h6>
      {OverallReviewsData?.rating_message?.length ? (
        OverallReviewsData?.rating_message?.map((item, id) => {
          return <IndivualReview key={id} review={item} />;
        })
      ) : (
        <p className="px-4">No reviews submittion for this venue</p>
      )}
    </>
  );
};

export default ArtistReveiw;
