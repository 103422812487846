import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Fb from "../../Assets/fb.png";
import FacebookLogin from "react-facebook-login";
import { jwtDecode } from "jwt-decode";
import { set, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { LoginAction } from "../../Redux/Action/AuthAction";
import toast, { Toaster } from "react-hot-toast";
import LoaderImg from "../../Assets/loader.gif";
import {
  ButtonConstants,
  Constants,
  DisplayValidationErrors,
  InputNames,
  errorMessages,
} from "../../Utilities";
import { useGoogleLogin } from "@react-oauth/google";
const Login = () => {
  // const store =useStore()

  const navigate = useNavigate();
  const [hide, setHide] = useState(true);
  const loader = useSelector((state) => state.Loading.loading);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function handleSocialLogin(credentialResponse) {
    var userObject = jwtDecode(credentialResponse.credential);
    var isSocialLogin = true;
    if (Object.keys(userObject).length > 0) {
      const payload = {
        email: userObject["email"],
        password: userObject["sub"],
      };
      dispatch(
        LoginAction(
          payload,
          (type, message) => {
            toast[type](message);
            if (type === "success") {
              navigate("/upcoming_events");
            }
          },
          isSocialLogin
        )
      );
    }
  }

  // const login = useGoogleLogin({
  //   onSuccess: tokenResponse => handleGoogleLogin
  // });

  const submitHandler = (data) => {
    const payload = {
      email: data[InputNames.EMAIL],
      password: data[InputNames.PASSWORD],
    };
    var isSocialLogin = false;
    dispatch(
      LoginAction(
        payload,
        (type, message) => {
          toast[type](message);
          if (type === "success") {
            navigate("/upcoming_events");
          }
        },
        isSocialLogin
      )
    );
  };

  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
      callback: handleSocialLogin,
    });
    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      type: "button",
      theme: "filled_blue",
      size: "large",
      text: "signin_with",
      outerWidth: 300,
      innerHeight: 40,
      shape: "pill",
      logo_alignment: "left",
    });
  });

  return (
    <>
      <section className="login_backround">
        <div className=" container-fluid py-5">
          <div className="row d-flex justify-content-center">
            <div className=" col-md-4">
              <div className="card login-card">
                <div className=" card-title text-center login-head">
                  Welcome to BandsOnDemand
                </div>
                <div className=" card-body">
                  <form onSubmit={handleSubmit(submitHandler)}>
                    <div>
                      <label>{Constants.EMAIL}</label>
                      <input
                        placeholder={Constants.EMAIL}
                        className="login-field"
                        type="text"
                        {...register(InputNames.EMAIL, { required: true })}
                      />
                      {errors[InputNames.EMAIL] ? (
                        <DisplayValidationErrors
                          message={errorMessages.FILL_FIELD}
                        />
                      ) : null}
                    </div>
                    <div className="position-relative">
                      <label>{Constants.PASSWORD}</label>
                      <input
                        className="login-field"
                        placeholder={Constants.PASSWORD}
                        type={hide ? "password" : "text"}
                        {...register(InputNames.PASSWORD, { required: true })}
                      />
                      {errors[InputNames.PASSWORD] ? (
                        <DisplayValidationErrors
                          message={errorMessages.FILL_FIELD}
                        />
                      ) : null}
                      {!hide ? (
                        <span
                          onClick={() => {
                            setHide(true);
                          }}
                          className="pass-icon"
                        >
                          <i className="fa-solid fa-eye fa-sm"></i>
                        </span>
                      ) : (
                        <span
                          onClick={() => {
                            setHide(false);
                          }}
                          className="pass-icon"
                        >
                          <i className="fa-solid fa-eye-slash fa-sm"></i>
                        </span>
                      )}
                    </div>
                    <div>
                      <p style={{ marginTop: "-21px" }}>
                        <Link to="/forget_password" className="forgot-link">
                          Forgot your password?
                        </Link>
                      </p>
                    </div>
                    <div>
                      <button
                        disabled={loader}
                        type="submit"
                        className="login-btn"
                      >
                        {loader ? (
                          <img
                            className="loader-image"
                            src={LoaderImg}
                            alt=""
                          />
                        ) : (
                          ButtonConstants.SIGN_IN
                        )}
                      </button>
                    </div>
                    <p className="or-text">OR</p>
                    <div>
                      <button type="button" className="fb-btn">
                        <span className="me-2">
                          <img src={Fb} alt="" />
                        </span>
                        {ButtonConstants.CONTINUE_WITH_FACEBOOK}
                      </button>
                    </div>
                    <p className="or-text">OR</p>
                    <div id="signInDiv" />
                    <h3 className="already-class">
                      New user ?
                      <Link className="signin-text" to="/register">
                        {ButtonConstants.SIGN_UP}
                      </Link>
                    </h3>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Toaster />
    </>
  );
};

export default Login;
