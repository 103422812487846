import { useEffect, useState } from "react";
import AddVenue from "./AddVenue";

import { useDispatch, useSelector } from "react-redux";
import {
  DeleteVenueAction,
  GetAllVenuesForLoggedUserAction,
} from "../../../Redux/Action/Venues";
import Spinner from "../../../Assets/spinner.svg";
import DeleteVenue from "./DeleteVenue";
import MyIndivualVenue from "../../../Components/Body/Venues/MyIndivualVenue";
import { useNavigate } from "react-router-dom";

const VenuesList = () => {
  const navigate = useNavigate();
  const [showAddModal, setShowAddModal] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedVenueId, setSelectedVenueId] = useState(null);
  const authToken = useSelector((state) => state.Auth.authToken);
  const venues = useSelector((state) => state.Venues.indivualUserVenues);
  const loader = useSelector((state) => state.Loading.loading);
  const profile = useSelector((state) => state.Auth.profile);
  const dispatch = useDispatch();
  const [filter, SetFilters] = useState({
    id__in: "",
    venue_type__in: "",
    food_beverage__in: "",
    search: "",
    from: "",
    to: "",
    radius: "",
    review_rating: 0,
    rate_from: "",
    rate_to: "",
    only_venues_follow: false,
  });

  const SubmitFilters = () => {
    dispatch(GetAllVenuesForLoggedUserAction(authToken, filter));
  };

  const handleFilter = (e) => {
    const { name, value } = e.target;
    SetFilters({ ...filter, [name]: value });
  };

  if (profile.venue_owner !== true) {
    navigate("/upcoming_events");
  }

  //ADD Veneu Modals
  const openAddModal = () => {
    setShowAddModal(true);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
    dispatch(GetAllVenuesForLoggedUserAction(authToken, {}));
  };
  const cancelAddModal = () => {
    setShowAddModal(false);
  };

  //DELETE venue modal

  const openDeleteModal = (venueId) => {
    setSelectedVenueId(venueId);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    dispatch(
      DeleteVenueAction(selectedVenueId, authToken, (type, message) => {
        if (type === "success")
          dispatch(GetAllVenuesForLoggedUserAction(authToken));
      })
    );
  };
  const cancelDeleteModal = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    dispatch(GetAllVenuesForLoggedUserAction(authToken, {}));
  }, []);
  return (
    <>
      {loader ? (
        <div className="spinner-loader">
          <img className="spinner" src={Spinner} alt="" />
        </div>
      ) : null}

      <section className=" py-5 artist-list">
        <div className="container-fluid px-5">
          <div className="row d-flex justify-content-center">
            <div className=" d-flex justify-content-between artist-heading">
              <h3 className="text-danger">Venues</h3>
              <button onClick={openAddModal} className="btn btn-outline-danger">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-plus"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>
                <span className="my-2">New Venue </span>
              </button>
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <input
                type="search"
                placeholder="Search"
                name="search"
                onChange={handleFilter}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    SubmitFilters();
                  }
                }}
                className=" form-control event-search-input"
              />
              <button
                type="button"
                onClick={SubmitFilters}
                className="btn event-search-icon"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </button>
            </div>
            {venues.length && !loader ? (
              <div className="inner-artists mt-4">
                <div className="row">
                  {venues?.map((item) => {
                    return (
                      <MyIndivualVenue
                        key={item.id}
                        openDeleteModal={openDeleteModal}
                        venue={item}
                      />
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </section>

      {!loader && venues.length === 0 ? (
        <>
          <div className="row  ">
            <div className="col-md-12  text-center">
              <h4>No data found</h4>
            </div>
          </div>
        </>
      ) : null}

      {showAddModal && (
        <AddVenue
          cancel={cancelAddModal}
          showModal={showAddModal}
          closeModal={closeAddModal}
        />
      )}
      {/* wating untile reducer is not fill it will stop modal to open once values filed modal open */}
      {showDeleteModal && (
        <DeleteVenue
          cancel={cancelDeleteModal}
          showModal={showDeleteModal}
          closeModal={closeDeleteModal}
        />
      )}
    </>
  );
};
export default VenuesList;
