import React, { useEffect } from "react";
import Lg1 from "../../../Assets/lg1.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { getPhotosForArtistAction } from "../../../Redux/Action/Artists";
import { getPhotosForVenueAction } from "../../../Redux/Action/Venues";

const Photos = ({ id, openDeleteModal, roleType, isPublic }) => {
  const dispatch = useDispatch();
  const photos = useSelector((state) => roleType === "venue" ? state.Venues.venuePhotos : state.Artists.artistPhotos);
  const loader = useSelector((state) => state.Loading.loading);
  // state.Venues.venuePhotos

  const HandleImageId = (id) => {
    openDeleteModal(id);
  };
  useEffect(() => {
    if (roleType === "venue") {
      dispatch(getPhotosForVenueAction(id));
    } else {
      dispatch(getPhotosForArtistAction(id));
    }
  }, [id]);

  return (
    <>
      <div className="media-imgs-sec">
        <div className="container">
          <div className="row">
            {photos?.map((pic) => {
              return (
                <div className="col-md-3 m-0 p-0" key={pic?.id}>
                  <div className="media-gallary-size position-relative">
                    <img className="media-image" src={pic?.media} />
                    {!isPublic && <button
                      onClick={() => HandleImageId(pic.id)}
                      className=" media-delete-btn"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-trash3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                      </svg>
                    </button>}
                  </div>
                </div>
              );
            })}

            {!loader && !photos.length ? (
              <div className="row">
                <div className="col-md-12 text-center">
                  <p className="text-danger mt-4">No data found</p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Photos;
