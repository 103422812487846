import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  FollowArtistAction,
  GettingIndivualArtistsBasedOnIdAction,
  UnFollowArtistAction,
  UploadArtistMediaAction,
} from "../../../Redux/Action/Artists";
import Spinner from "../../../Assets/spinner.svg";
import toast, { Toaster } from "react-hot-toast";
import DummyImage from "../../../Assets/noimg.jpg";
import OverviewArtistTab from "./Tabs/OverviewArtist";
import BidArtistTab from "./Tabs/BidArtist";
import ArtistUpcomingEvents from "./Tabs/ArtistUpcomingEvents";
import ArtistCalendar from "./Tabs/ArtistCalendar";
import CalenderEventPopup from "../../../Components/Body/Venues/TabsSection/Calender/CalenderEventPopup";
import ArtistReveiw from "./Tabs/ArtistReviews";
import AddMedia from "../../../Components/Body/AddMedia";
import ArtistGallery from "./Tabs/ArtistGallery";
import Map from "../../Protected/Venues/Map";

const ArtistDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.Auth.authToken);
  const loader = useSelector((state) => state.Loading.loading);
  const artist = useSelector((state) => state.Artists.artistsBasedOnId);
  const profile = useSelector((state) => state.Auth.profile);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [eventDetailsPopup, setEventDetailsPopup] = useState(false);
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  if (profile.artist_or_band !== true) {
    navigate("/upcoming_events");
  }
  const openEditModal = (artists_Id) => {
    //hitting api to get artists based on id and fill value to reducer
    dispatch(GettingIndivualArtistsBasedOnIdAction(artists_Id, authToken));
    setShowEditModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };
  const cancelEditModal = () => {
    setShowEditModal(false);
  };

  //Show Calender  Events
  const openEventDetailsPopup = (val) => {
    setSelectedEvent(val);
    setEventDetailsPopup(true);
  };
  const closeEventDetailsPopup = () => {
    setEventDetailsPopup(false);
  };

  //ADD Media Modal
  const openMediaModal = () => {
    setShowMediaModal(true);
  };
  const closeMediaModal = (data) => {
    dispatch(
      UploadArtistMediaAction(data, authToken, (type, message) => {
        toast[type](`${message}`, {
          duration: 5000,
        });
        if (type === "success") {
          setShowMediaModal(false);
          dispatch(GettingIndivualArtistsBasedOnIdAction(id, authToken));
        }
      })
    );
  };
  const cancelMediaModal = () => {
    setShowMediaModal(false);
  };

  //follow unfollow functinality below
  const followArtistHandler = () => {
    const payload = { artist: id };
    dispatch(
      FollowArtistAction(payload, authToken, (type, message) => {
        toast[type](`${message}`, {
          duration: 5000,
        });
        if (type === "success")
          dispatch(GettingIndivualArtistsBasedOnIdAction(id, authToken));
      })
    );
  };

  const unFollowArtistHandler = () => {
    dispatch(
      UnFollowArtistAction(id, authToken, (type, message) => {
        toast[type](`${message}`, {
          duration: 5000,
        });
        if (type === "success")
          dispatch(GettingIndivualArtistsBasedOnIdAction(id, authToken));
      })
    );
  };
  useEffect(() => {
    dispatch(GettingIndivualArtistsBasedOnIdAction(id, authToken));
  }, [id]);

  return (
    <>
      {loader ? (
        <div className="spinner-loader">
          <img className="spinner" src={Spinner} alt="" />
        </div>
      ) : null}

      <section id="artist-section">
        <div className=" container">
          <div
            style={{
              backgroundImage: `url(${
                artist?.image ? artist.image : DummyImage
              })`,
              backgroundRepeat: "no-repeat",
              maxHeight: "280px",
              height: "100%",
              backgroundSize: "cover",
            }}
            className="artist-bg  position-relative p-5"
          >
            <div className="row align-items-center">
              <div className="col-md-2 z-index-999">
                <img
                  src={artist?.image ? artist.image : DummyImage}
                  className=" upcoming-event-card rounded-circle artist-detail-img"
                  alt=""
                />
              </div>
              <div className="col-md-8 z-index-999">
                <div>
                  <h2 className="name-artist-heading">
                    {artist?.name ? artist.name : "Not Available"}
                  </h2>
                </div>
              </div>

              <div className="col-md-2 z-index-999">
                <button
                  onClick={
                    artist?.follow ? unFollowArtistHandler : followArtistHandler
                  }
                  className="btn btn-danger rounded-2 follow-btn"
                >
                  {artist?.follow ? "UNFOLLOW" : "FOLLOW"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="header-tabs position-relative">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="tabs-section position-relative">
                <h6 className=" followers-venue bg-danger">
                  <span className="ms-2">Followers</span>
                  <span className="me-2"> {artist?.followers}</span>
                </h6>
                <nav className="headr-navs-bg">
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      className="nav-link active"
                      id="Overview-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      OVERVIEW
                    </button>
                    <button
                      className="nav-link"
                      id="Events-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                    >
                      UPCOMING EVENTS
                    </button>
                    <button
                      className="nav-link"
                      id="Calender-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-contact"
                      type="button"
                      role="tab"
                      aria-controls="nav-contact"
                      aria-selected="false"
                    >
                      CALENDAR
                    </button>
                    <button
                      className="nav-link"
                      id="Reviews-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-Reviews"
                      type="button"
                      role="tab"
                      aria-controls="nav-Reviews"
                      aria-selected="false"
                    >
                      REVIEW
                    </button>
                    <button
                      className="nav-link"
                      id="Bid-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-bids"
                      type="button"
                      role="tab"
                      aria-controls="nav-bidd"
                      aria-selected="false"
                    >
                      FIND GIG
                    </button>
                    <button
                      className="nav-link"
                      id="media-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-media"
                      type="button"
                      role="tab"
                      aria-controls="nav-media"
                      aria-selected="false"
                    >
                      MEDIA
                    </button>
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="Overview-tabs"
                  >
                    <OverviewArtistTab
                      isArtistPrivate={true}
                      artist={artist}
                      showEditModal={showEditModal}
                      openEditModal={openEditModal}
                      closeEditModal={closeEditModal}
                      cancelEditModal={cancelEditModal}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-profile"
                    role="tabpanel"
                    aria-labelledby="Events-tabs"
                  >
                    <ArtistUpcomingEvents artist={artist} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-contact"
                    role="tabpanel"
                    aria-labelledby="Calender-tabs"
                  >
                    <ArtistCalendar
                      isArtistPublic={true}
                      openEventDetailsPopup={openEventDetailsPopup}
                      id={id}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-Reviews"
                    role="tabpanel"
                    aria-labelledby="OverAllReview-tabs"
                  >
                    <ArtistReveiw id={id} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-bids"
                    role="tabpanel"
                    aria-labelledby="bid-tabs"
                  >
                    <BidArtistTab id={id} artistName={artist?.name} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-media"
                    role="tabpanel"
                    aria-labelledby="media-tabs"
                  >
                    <ArtistGallery id={id} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <button onClick={openMediaModal} className="btn btn-danger mt-2">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-plus"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>
                </span>
                Add Media
              </button>
              <div className="blank-box mt-4">
                <h6 className="updates">Updates</h6>
              </div>
              <div className="blank-box mt-4 h-auto">
                <h6 className="updates">Map</h6>
                <Map dataMap={artist} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Toaster />

      {eventDetailsPopup && (
        <CalenderEventPopup
          selectedEvent={selectedEvent}
          eventDetailsPopup={eventDetailsPopup}
          closeEventDetailsPopup={closeEventDetailsPopup}
        />
      )}

      {showMediaModal && (
        <AddMedia
          cancel={cancelMediaModal}
          showModal={showMediaModal}
          closeModal={closeMediaModal}
          id={artist?.id}
        />
      )}
    </>
  );
};

export default ArtistDetails;
