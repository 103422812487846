import {useEffect, React} from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { DisplayValidationErrors, errorMessages } from "../../../Utilities";
import { AddAdminUserAction } from "../../../Redux/Action/ProfileAction";
import { GetAllVenuesForLoggedUserAction } from "../../../Redux/Action/Venues";
import toast, { Toaster } from "react-hot-toast";
import LoaderImg from "../../../Assets/loader.gif";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function AddAdminUser({ showModal, closeModal, profile }) {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.Loading.loading);
  const authToken = useSelector((state) => state.Auth.authToken);
  const venues = useSelector((state) => state.Venues.indivualUserVenues);
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const submitHandler = (data) => {
    const payload = {
      email: data.email,
      first_name: data.fname,
      last_name: data.lname,  
      venues: data.venue,
    }

    dispatch(
      AddAdminUserAction(payload, authToken, (type, message) => {
        toast[type](message, {
          duration: 5000,
        });
        if (type === "success") {
          reset();
          closeModal();
        }
      })
    );
  };

  useEffect(() => {
    dispatch(GetAllVenuesForLoggedUserAction(authToken, {}));
  }, []);

  return (
    <>
      <Modal
        open={showModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-form">
          <form onSubmit={handleSubmit(submitHandler)}>
            <>
              <button
                type="button"
                onClick={closeModal}
                className="btn close-btn"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </button>
              <div className="row">
                <div className="col-md-6">
                  <label>First name</label>
                  <input
                    placeholder="First name"
                    className="login-field"
                    type="text"
                    {...register("fname", { required: true })}
                  />
                  {errors["fname"] ? (
                    <DisplayValidationErrors
                      message={errorMessages.FILL_FIELD}
                    />
                  ) : null}
                </div>

                <div className="col-md-6">
                  <label>Last name</label>
                  <input
                    placeholder="Last name"
                    className="login-field"
                    type="text"
                    {...register("lname", { required: true })}
                  />
                  {errors["lname"] ? (
                    <DisplayValidationErrors
                      message={errorMessages.FILL_FIELD}
                    />
                  ) : null}
                </div>

                <div className="col-md-6">
                  <label>Email</label>
                  <input
                    placeholder="Email"
                    className="login-field"
                    type="email"
                    {...register("email", { required: true })}
                  />
                  {errors["email"] ? (
                    <DisplayValidationErrors
                      message={errorMessages.FILL_FIELD}
                    />
                  ) : null}
                </div>
                <div className="col-md-6">
                  <label>Venue</label>
                  <Controller
                    control={control}
                    name="venue"
                    rules={{ required: true }}
                    render={({ field: { onChange, value = [], ref } }) => (
                      <Select
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={venues}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        value={venues.filter((venue) =>
                          value.includes(venue.id)
                        )}
                        onChange={(val) => onChange(val.map((item) => item.id))}
                        isMulti={true}
                        closeMenuOnSelect={false} // Keep the menu open after selection (useful for multi-select)
                      />
                    )}
                  />

                  {errors.venue ? <p>Please select this field</p> : ""}
                </div>
              </div>
            </>

            <div className="submission-form-popup">
              <button disabled={loader} type="submit" className="login-btn ">
                {loader ? (
                  <img className="loader-image" src={LoaderImg} alt="" />
                ) : (
                  "Add Admin User"
                )}
              </button>

              <button onClick={closeModal} type="button" className="login-btn">
                Cancel
              </button>
            </div>
          </form>
        </Box>
      </Modal>
      <Toaster />
    </>
  );
}
export default AddAdminUser;
