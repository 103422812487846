import React from "react";
import DummyImage from "../../../Assets/noimg.jpg";
import { Link } from "react-router-dom";

const IndivualVenue = ({ venue }) => {
  return (
    <div className="col-md-4">
      <div className="upcoming-event-card mt-5">
        <div className="artist-img">
          <img
            src={`${venue.image ? venue.image : DummyImage}`}
            className="card-img-top"
            alt="Artist"
          />
        </div>
        <div className="artist-info">
          <h6 className="card-title text-capitalize mb-3">{venue?.name ? venue.name : "NA"}</h6>
          <p className=" mt-0 mb-2">
            <span className="font-weight-bold">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                className="bi bi-envelope-fill me-3"
                viewBox="0 0 16 16"
              >
                <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
              </svg>
            </span>
            {venue?.email ? venue.email : "NA"}
          </p>
          <p className="">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                className="bi bi-telephone-fill me-3"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                />
              </svg>
            </span>
            {venue?.phone ? venue.phone: "NA"}
          </p>
          <Link
            to={`/venue-detail/${venue.id}`}
            className="btn btn-light btn-sm"
          >
            View details
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-right"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
              />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default IndivualVenue;
