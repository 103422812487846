import { Outlet } from "react-router-dom";
import Header from "../../Components/Header/Navigation";
import Footer from "../../Components/Footer/Footer";

const Root = () => {
  return (
    <>
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};
export default Root;
