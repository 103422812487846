import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { GettingEventBasedOnIdPublicAction } from "../../Redux/Action/UpcomingEvents";
import HeaderRandomImages from "./HeaderRandomImages";
import moment from "moment";

const EventDetailsPublic = () => {
  const dispatch = useDispatch();
  const EventDetail = useSelector((state) => state.Events.indivualEventDetail);

  const { id } = useParams();

  useEffect(() => {
    dispatch(GettingEventBasedOnIdPublicAction(id));
  }, [id]);

  return (
    <>
      <HeaderRandomImages />
      <section
        className="hero d-flex align-items-center justify-content-start event-dynamic-img"
        style={{
          background: `url(${EventDetail?.image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></section>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-md-12 p-0 m-0">
            <div className="content p-5">
              <h1 className="text-black">
                {" "}
                <Link
                  className="text-black"
                  to={`/artist-detail/${EventDetail?.artist?.id}`}
                >
                  {EventDetail?.artist?.name}
                </Link>
              </h1>
              <h6 className="text-black">{EventDetail?.description}</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-md-12 p-0 m-0">
            <div className="content p-5">
              <ul>
                <li>
                  <label>Venue:&ensp;</label>
                  <Link
                    className="text-black"
                    to={`/venue-detail/${EventDetail?.venue?.id}`}
                  >
                    {EventDetail.venue?.name}
                  </Link>
                </li>

                <li>
                  <label>Entry price:&ensp;</label>
                  {EventDetail?.rate}
                </li>

                <li>
                  <label>Genre:&ensp;</label>
                  {EventDetail?.genre?.name}
                </li>
                <li>
                  <label> Start At:&ensp; </label>
                  {moment(EventDetail?.start_time).format("YYYY-MM-DD HH:mm a")}
                </li>
                <li>
                  <label> End At:&ensp; </label>
                  {moment(EventDetail?.end_time).format("YYYY-MM-DD HH:mm a")}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventDetailsPublic;
