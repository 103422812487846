import Actions from "./Actions";

export const BidOnArtistAction = (payload, auth, callback) => {
  return {
    type: Actions.BIDDING_ARTIST,
    payload,
    auth,
    callback,
  };
};

export const GetAllGigsForUserAction = (auth) => {
  return {
    type: Actions.GET_ALL_GIGS_FOR_USER,
    auth,
  };
};

export const SaveAllGigsAction = (payload) => {
  return {
    type: Actions.SAVE_ALL_GIGS_FOR_USER,
    payload,
  };
};

export const AcceptGigActionByArtistManager = (payload, auth, callback) => {
  return {
    type: Actions.ACCEPT_GIG_ARTIST_MANAGER,
    auth,
    payload,
    callback,
  };
};

export const RejectGigActionByArtistManager = (payload, auth, callback) => {
  return {
    type: Actions.REJECT_GIG_ARTIST_MANAGER,
    auth,
    payload,
    callback,
  };
};

export const NegotiationGigPriceAction = (id, payload, auth, callback) => {
  return {
    type: Actions.NEGOTIATE_GIG_PRICE,
    id,
    payload,
    auth,
    callback,
  };
};

export const GetMyBidsAction = (auth) => {
  return {
    type: Actions.GET_MY_BIDS,
    auth,
  };
};

export const SaveAllBidsAction = (payload) => {
  return {
    type: Actions.SAVE_MY_BIDS,
    payload,
  };
};

export const AcceptGigActionByVeneuManger = (payload, auth, callback) => {
  return {
    type: Actions.ACCEPT_GIG_VENUE_MANAGER,
    auth,
    payload,
    callback,
  };
};

export const SaveAgreementContentByVeneuManger = (
  id,
  payload,
  auth,
  callback
) => {
  return {
    type: Actions.SAVE_AGREEMENT_CONTENT_VENUE_MANAGER,
    id,
    auth,
    payload,
    callback,
  };
};

export const SendAgreementContentByVeneuManger = (
  id,
  payload,
  auth,
  callback
) => {
  return {
    type: Actions.SEND_AGREEMENT_CONTENT_VENUE_MANAGER,
    id,
    auth,
    payload,
    callback,
  };
};

export const RejectGigActionByVenueManger = (payload, auth, callback) => {
  return {
    type: Actions.REJECT_GIG_VENUE_MANAGER,
    auth,
    payload,
    callback,
  };
};

export const SaveBidableBidsAction = (payload) => {
  return {
    type: Actions.SAVE_BIDABLE_BIDS,
    payload,
  };
};

export const BidOnEventByArtistManager = (payload, auth, callback) => {
  return {
    type: Actions.BID_ON_EVENT_BY_ARTIST_MANAGER,
    payload,
    auth,
    callback,
  };
};
