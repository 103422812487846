import Actions from "./Actions";

export const RegisterAction = (payload, callback) => {
  return {
    type: Actions.REGISTER_USER,
    payload,
    callback,
  };
};

export const LoginAction = (payload, callback, isSocialLogin) => {
  return {
    type: Actions.LOGIN_USER,
    payload,
    callback,
    isSocialLogin,
  };
};

export const SaveAuth = (payload) => {
  return {
    type: Actions.SAVE_AUTH,
    payload,
  };
};

export const RemoveAuth = () => {
  return {
    type: Actions.REMOVE_AUTH,
  };
};

export const SendOtpAction = (payload, callback) => {
  return {
    type: Actions.SEND_OTP,
    payload,
    callback,
  };
};

export const ResetPassword = (payload, callback) => {
  return {
    type: Actions.RESET_PASSWORD,
    payload,
    callback,
  };
};

export const ChangePasswordAction = (payload, auth, callback) => {
  return {
    type: Actions.CHANGE_PASSWORD,
    payload,
    auth,
    callback,
  };
};
