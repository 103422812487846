import React, { useEffect } from "react";
import { DisplayValidationErrors, errorMessages } from "../../../Utilities";
import Select from "react-select";
const EditForm = ({
  register,
  errors,
  Controller,
  control,
  venues,
  options,
}) => {
  useEffect(() => {}, [venues]);
  return (
    <>
      <div className="col-md-6">
        <label>Name</label>
        <input
          placeholder="Venue name"
          className="login-field"
          type="text"
          {...register("name", { required: true })}
        />
        {errors["name"] ? (
          <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
        ) : null}
      </div>

      <div className="col-md-6">
        <label>Email</label>
        <input
          placeholder="Email"
          className="login-field"
          type="email"
          {...register("email", { required: true })}
        />
        {errors["email"] ? (
          <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
        ) : null}
      </div>

      <div className="col-md-6">
        <label>Phone number</label>
        <input
          placeholder="Phone number"
          className="login-field"
          type="number"
          {...register("phone", {
            required: true,
            minLength: 10,
            maxLength: 10,
          })}
        />
        {errors?.phone?.type == "required" ? (
          <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
        ) : null}
        {errors?.phone?.type == "maxLength" ||
        errors?.phone?.type == "minLength" ? (
          <DisplayValidationErrors message={errorMessages.VALID_NUMBER} />
        ) : null}
      </div>

      <div className="col-md-6">
        <label>Address</label>
        <input
          placeholder="Address"
          className="login-field"
          type="text"
          {...register("address", { required: true })}
        />
        {errors["address"] ? (
          <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
        ) : null}
      </div>

      <div className="col-md-6">
        <label>City</label>
        <input
          placeholder="City"
          className="login-field"
          type="text"
          {...register("city", { required: true })}
        />
        {errors["city"] ? (
          <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
        ) : null}
      </div>

      <div className="col-md-6">
        <label>State</label>
        <input
          placeholder="State"
          className="login-field"
          type="text"
          {...register("state", { required: true })}
        />
        {errors["state"] ? (
          <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
        ) : null}
      </div>

      <div className="col-md-6">
        <label>Zip code</label>
        <input
          placeholder="Zip code"
          className="login-field"
          type="number"
          {...register("zip_code", { required: true })}
        />
        {errors["zip_code"] ? (
          <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
        ) : null}
      </div>

      <div className="col-md-6">
        <label>Pitcture</label>
        <input
          placeholder="Picture"
          className="login-field"
          type="file"
          {...register("image")}
        />
      </div>

      <div className="col-md-6">
        <label>Introduction</label>
        <input
          placeholder="Introduction"
          className="login-field"
          type="text"
          {...register("introduction")}
        />
      </div>
      <div className="col-md-6">
        <label>Bio</label>
        <input
          placeholder="Bio"
          className="login-field"
          type="text"
          {...register("bio")}
        />
      </div>
      <div className="col-md-6">
        <label>Key Information</label>
        <input
          placeholder="Key Information"
          className="login-field"
          type="text"
          {...register("key_information")}
        />
      </div>
      <div className="col-md-6">
        <label>More Information</label>
        <input
          placeholder="More Information"
          className="login-field"
          type="text"
          {...register("more_information")}
        />
      </div>
      <div className="col-md-6">
        <label>Rate From</label>
        <input
          placeholder="Rate from"
          className="login-field"
          type="number"
          {...register("rate_from")}
        />
      </div>
      <div className="col-md-6">
        <label>Rate To</label>
        <input
          placeholder="Rate from"
          className="login-field"
          type="number"
          {...register("rate_to")}
        />
      </div>
      <div className="col-md-6">
        <label>Setup hours</label>
        <input
          placeholder="Setup hours"
          className="login-field"
          type="number"
          {...register("setup_hours")}
        />
      </div>
      <div className="col-md-6">
        <label>Website url</label>
        <input
          placeholder="Website url"
          className="login-field"
          type="url"
          {...register("website")}
        />
      </div>
      <div className="col-md-6">
        <label>Facebook url</label>
        <input
          placeholder="Facebook url"
          className="login-field"
          type="url"
          {...register("facebook")}
        />
      </div>
      <div className="col-md-6">
        <label>Twitter url</label>
        <input
          placeholder="Twitter url"
          className="login-field"
          type="url"
          {...register("twitter")}
        />
      </div>
      <div className="col-md-6">
        <label>Instagram url</label>
        <input
          placeholder="Instagram url"
          className="login-field"
          type="url"
          {...register("instagram")}
        />
      </div>
      <div className="col-md-6">
        <label>Published</label>
        <Controller
          control={control}
          name="published"
          //rules={{ required: true }}
          render={({ field: { onChange, value, name, ref } }) => (
            <Select
              inputRef={ref}
              classNamePrefix="addl-class"
              options={options}
              value={
                venues.published == "" ||
                venues.published == null ||
                venues.published == undefined
                  ? options.find((c) => c.value === value)
                  : venues.published
                  ? { label: "Yes", value: "Yes" }
                  : { label: "No", value: "No" }
              }
              onChange={(val) => onChange(val.value)}
              // isMulti
            />
          )}
        />
        {errors.genre ? <p>Please slecte this field</p> : ""}
      </div>
    </>
  );
};

export default EditForm;
