import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  FollowVenueAction,
  GettingIndivualVenueBasedOnIdPrivateAction,
  GettingIndivualVenueBasedOnIdAction,
  UnFollowVenueAction,
  UploadVenueMediaAction,
} from "../../../Redux/Action/Venues";
import OverallReview from "../../../Components/Body/Venues/TabsSection/Reviews/OverallReview";
import Events from "../../../Components/Body/Venues/TabsSection/Events";
import OverviewTab from "../../../Components/Body/Venues/TabsSection/OverviewTab";
import SliderPopup from "../../../Components/Body/Venues/TabsSection/SliderPopup";
import AddEvent from "./AddEvent";
import { Toaster, toast } from "react-hot-toast";
import HeaderImages from "../../../Components/Body/Venues/HeaderImages";
import Calender from "../../../Components/Body/Venues/TabsSection/Calender/Calender";
import CalenderEventPopup from "../../../Components/Body/Venues/TabsSection/Calender/CalenderEventPopup";
import Bid from "../../../Components/Body/Venues/Bids";
import Map from "./Map";
import AddMedia from "../../../Components/Body/AddMedia";
import VanueGallery from "./Tabs/VanuesGallery";

const VenueDetailsPrivate = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const venueDetail = useSelector((state) => state.Venues.venueBasedOnId);
  const authToken = useSelector((state) => state.Auth.authToken);
  const [showSliderPopup, setShowSliderPopup] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedEvent, setSelectedEvent] = useState({});
  const [eventDetailsPopup, setEventDetailsPopup] = useState(false);
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const profile = useSelector((state) => state.Auth.profile);

  if (profile.venue_owner !== true) {
    navigate("/upcoming_events");
  }
  //EDIT vneue modal
  const openEditModal = (venueId) => {
    //hitting api to get veneu based on id and fill value to reducer
    dispatch(GettingIndivualVenueBasedOnIdAction(venueId, authToken));
    setShowEditModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };
  const cancelEditModal = () => {
    setShowEditModal(false);
  };
  //Opend Slider popup
  const openSliderPopup = () => {
    setShowSliderPopup(true);
  };
  const closeSliderPopup = () => {
    setShowSliderPopup(false);
  };

  //ADD Event Modal
  const openAddModal = (val) => {
    setSelectedDate(val); // this is in case of we click calender we send clicked date and time
    setShowAddModal(true);
  };
  const closeAddModal = () => {
    setSelectedDate();
    setShowAddModal(false);
  };
  const cancelAddModal = () => {
    setSelectedDate();
    setShowAddModal(false);
  };

  //Show Calender  Events
  const openEventDetailsPopup = (val) => {
    setSelectedEvent(val);
    setEventDetailsPopup(true);
  };
  const closeEventDetailsPopup = () => {
    setEventDetailsPopup(false);
  };

  //ADD Media Modal
  const openMediaModal = () => {
    setShowMediaModal(true);
  };
  const closeMediaModal = (data) => {
    dispatch(
      UploadVenueMediaAction(data, authToken, (type, message) => {
        toast[type](`${message}`, {
          duration: 5000,
        });
        if (type === "success") {
          setShowMediaModal(false);
          dispatch(GettingIndivualVenueBasedOnIdPrivateAction(id, authToken));
        }
      })
    );
  };
  const cancelMediaModal = () => {
    setShowMediaModal(false);
  };

  //follow unfollow handlers
  const followVenueHandler = () => {
    const payload = { venue: id };
    dispatch(
      FollowVenueAction(payload, authToken, (type, message) => {
        toast[type](`${message}`, {
          duration: 5000,
        });
        if (type === "success")
          dispatch(GettingIndivualVenueBasedOnIdPrivateAction(id, authToken));
      })
    );
  };

  const unFollowVenueHandler = () => {
    dispatch(
      UnFollowVenueAction(id, authToken, (type, message) => {
        toast[type](`${message}`, {
          duration: 5000,
        });
        if (type === "success")
          dispatch(GettingIndivualVenueBasedOnIdPrivateAction(id, authToken));
      })
    );
  };

  //useffects hook
  useEffect(() => {
    dispatch(GettingIndivualVenueBasedOnIdPrivateAction(id, authToken));
  }, [id, authToken, dispatch]);

  return (
    <>
      <section className="photo-gallary-slide">
        <div className="container-fluid">
          <div className="row p-2  bg-dark">
            <HeaderImages
              openSliderPopup={openSliderPopup}
              photos={venueDetail?.media?.filter(
                (item) => item.media_type === "Photo"
              )}
            />
          </div>
        </div>
      </section>

      <section className="header-tabs position-relative">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="tabs-section position-relative">
                <h6 className=" followers-venue bg-danger">
                  <span className="me-2">Followers</span>
                  <span className="ms-2"> {venueDetail.followers}</span>
                </h6>
                <nav className="headr-navs-bg">
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      className="nav-link active"
                      id="Overview-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      OVERVIEW
                    </button>
                    <button
                      className="nav-link"
                      id="Events-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                    >
                      UPCOMING EVENTS
                    </button>
                    <button
                      className="nav-link"
                      id="Calender-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-contact"
                      type="button"
                      role="tab"
                      aria-controls="nav-contact"
                      aria-selected="false"
                    >
                      CALENDAR
                    </button>
                    <button
                      className="nav-link"
                      id="Reviews-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-Reviews"
                      type="button"
                      role="tab"
                      aria-controls="nav-Reviews"
                      aria-selected="false"
                    >
                      REVIEWS
                    </button>
                    <button
                      className="nav-link"
                      id="Bid-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-bids"
                      type="button"
                      role="tab"
                      aria-controls="nav-bidd"
                      aria-selected="false"
                    >
                      INVITE ARTISTS
                    </button>
                    <button
                      className="nav-link"
                      id="media-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-media"
                      type="button"
                      role="tab"
                      aria-controls="nav-media"
                      aria-selected="false"
                    >
                      MEDIA
                    </button>
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="Overview-tabs"
                  >
                    <OverviewTab
                      venueDetail={venueDetail}
                      showEditModal={showEditModal}
                      openEditModal={openEditModal}
                      closeEditModal={closeEditModal}
                      cancelEditModal={cancelEditModal}
                      isVenuePrivate={true}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-profile"
                    role="tabpanel"
                    aria-labelledby="Events-tabs"
                  >
                    <Events id={id} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-contact"
                    role="tabpanel"
                    aria-labelledby="Calender-tabs"
                  >
                    <Calender
                      isVenuePublic={false}
                      openEventDetailsPopup={openEventDetailsPopup}
                      id={id}
                      venueDetail={venueDetail}
                      selectedDate={selectedDate}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-Reviews"
                    role="tabpanel"
                    aria-labelledby="OverAllReview-tabs"
                  >
                    <OverallReview id={id} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-bids"
                    role="tabpanel"
                    aria-labelledby="bid-tabs"
                  >
                    <Bid id={id} venueName={venueDetail.name} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-media"
                    role="tabpanel"
                    aria-labelledby="media-tabs"
                  >
                    <VanueGallery id={id} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="d-flex justify-content-between mt-4 flex-wrap">
                <button
                  onClick={() => openAddModal(venueDetail)}
                  className="btn btn-danger d-flex align-items-center py-0 new-event-btns"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="currentColor"
                    className="bi bi-plus"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>
                  <span className="my-2">New Events </span>
                </button>

                <button
                  onClick={
                    venueDetail?.follow
                      ? unFollowVenueHandler
                      : followVenueHandler
                  }
                  className="btn btn-danger new-event-btns rounded-1 d-flex align-items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="white"
                    className="bi bi-person-fill-add me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    <path d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z" />
                  </svg>
                  {venueDetail?.follow ? "Unfollow" : "Follow"}
                </button>

                <button
                  onClick={openMediaModal}
                  className="btn btn-danger new-event-btns rounded-1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="#fff"
                    className="bi bi-plus"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>
                  Add media
                </button>
              </div>

              <div className="blank-box mt-4 h-auto">
                <h6 className="updates">Map</h6>
                <Map dataMap={venueDetail} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {showSliderPopup && (
        <SliderPopup
          showSliderPopup={showSliderPopup}
          closeSliderPopup={closeSliderPopup}
          media={venueDetail?.media}
        />
      )}
      {showAddModal && (
        <AddEvent
          selectedDate={selectedDate}
          cancel={cancelAddModal}
          showModal={showAddModal}
          closeModal={closeAddModal}
        />
      )}
      {eventDetailsPopup && (
        <CalenderEventPopup
          selectedEvent={selectedEvent}
          eventDetailsPopup={eventDetailsPopup}
          closeEventDetailsPopup={closeEventDetailsPopup}
        />
      )}

      {showMediaModal && (
        <AddMedia
          cancel={cancelMediaModal}
          showModal={showMediaModal}
          closeModal={closeMediaModal}
          id={venueDetail?.id}
        />
      )}

      <Toaster />
    </>
  );
};

export default VenueDetailsPrivate;
