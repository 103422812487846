import React, { useState } from "react";
import ArtistListingForBid from "./ArtistListingForBid";
import BiddingPopup from "./BidingPopup";
import { Toaster } from "react-hot-toast";

const Bid = ({ id, venueName }) => {
  const [bidingPopup, setBiddingPopup] = useState(false);
  const [bidData, setBidData] = useState({});

  //Show Calender  Events
  const openBidingPopup = (val) => {
    setBidData({
      artist: val,
      venueId: id,
      venueName: venueName,
    });
    setBiddingPopup(true);
  };
  const closeBidingPopup = () => {
    setBiddingPopup(false);
  };

  return (
    <>
      <section className="review">
        <div className="container">
          <div className="row py-3">
            <div className="col-md-12">
              <h6>Invite artists to bid on events</h6>
              <ArtistListingForBid openBidingPopup={openBidingPopup} />
            </div>
          </div>
        </div>
      </section>
      <BiddingPopup
        bidingPopup={bidingPopup}
        closeBidingPopup={closeBidingPopup}
        bidData={bidData}
      />
      <Toaster />
    </>
  );
};

export default Bid;
