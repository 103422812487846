import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { DisplayValidationErrors, errorMessages } from "../../../Utilities";
import toast, { Toaster } from "react-hot-toast";
import { AddVenueAction } from "../../../Redux/Action/Venues";
import LoaderImg from "../../../Assets/loader.gif";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddVenue = ({ showModal, closeModal, cancel }) => {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.Loading.loading);
  const [loading, setLoading] = useState(false);
  const authToken = useSelector((state) => state.Auth.authToken);
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();

  

  const submitHandler = async (data) => {
    const address = `${data?.address} ${data?.city} ${data?.state}`;
    const encodedAddress = encodeURIComponent(address);
    //finding latitude and longitude for entred textual address
    try {
      setLoading(true);
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?q=${encodedAddress}&format=json&polygon=1&addressdetails=1`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            
          },
        }
      ); 
      const result = await response.json();
      if (result.length > 0) {
          //if received lat and long then we dispatch the full detials of the venue to backend       
        const val = Object.values(data.postImage);
        const formData = new FormData();
        formData.append("name", data["name"]);
        formData.append("email", data["email"]);
        formData.append("image", val[0]);
        formData.append("address", data["address"]);
        formData.append("city", data["city"]);
        formData.append("state", data["state"]);
        formData.append("zip_code", data["zip_code"]);
        formData.append("phone", data["phone"]);
        formData.append("latitude", result[0]?.lat);
        formData.append("longitude", result[0]?.lon);

        dispatch(
          AddVenueAction(formData, authToken, (type, message) => {
            toast[type](message);
            if (type == "success") {
              reset();
              closeModal();
            }
          })
        ); 
      } else {
        toast.error("Please fill exact zipcode or Place not found");
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        open={showModal}
        fill
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-form">
          <form onSubmit={handleSubmit(submitHandler)}>
            <>
              <p className=" text-danger text-uppercase h6">
                Register New Venue
              </p>

              <button
                type="button"
                disabled={loader || loading}
                onClick={cancel}
                className="btn close-btn"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </button>
              <div className="row">
                <div className="col-md-6">
                  <label>Name</label>
                  <input
                    placeholder="Venue name"
                    className="login-field"
                    type="text"
                    {...register("name", { required: true })}
                  />
                  {errors["name"] ? (
                    <DisplayValidationErrors
                      message={errorMessages.FILL_FIELD}
                    />
                  ) : null}
                </div>

                <div className="col-md-6">
                  <label>Email</label>
                  <input
                    placeholder="Email"
                    className="login-field"
                    type="email"
                    {...register("email", { required: true })}
                  />
                  {errors["email"] ? (
                    <DisplayValidationErrors
                      message={errorMessages.FILL_FIELD}
                    />
                  ) : null}
                </div>

                <div className="col-md-6">
                  <label>Phone number</label>
                  <input
                    placeholder="Phone number"
                    className="login-field"
                    type="number"
                    {...register("phone", {
                      required: true,
                      minLength: 10,
                      maxLength: 10,
                    })}
                  />
                  {errors?.phone?.type == "required" ? (
                    <DisplayValidationErrors
                      message={errorMessages.FILL_FIELD}
                    />
                  ) : null}
                  {errors?.phone?.type == "maxLength" ||
                  errors?.phone?.type == "minLength" ? (
                    <DisplayValidationErrors
                      message={errorMessages.VALID_NUMBER}
                    />
                  ) : null}
                </div>

                <div className="col-md-6">
                  <label>Address</label>
                  <input
                    placeholder="Address"
                    className="login-field"
                    type="text"
                    {...register("address", { required: true })}
                  />
                  {errors["address"] ? (
                    <DisplayValidationErrors
                      message={errorMessages.FILL_FIELD}
                    />
                  ) : null}
                </div>

                <div className="col-md-6">
                  <label>City</label>
                  <input
                    placeholder="City"
                    className="login-field"
                    type="text"
                    {...register("city", { required: true })}
                  />
                  {errors["city"] ? (
                    <DisplayValidationErrors
                      message={errorMessages.FILL_FIELD}
                    />
                  ) : null}
                </div>

                <div className="col-md-6">
                  <label>State</label>
                  <input
                    placeholder="State"
                    className="login-field"
                    type="text"
                    {...register("state", { required: true })}
                  />
                  {errors["state"] ? (
                    <DisplayValidationErrors
                      message={errorMessages.FILL_FIELD}
                    />
                  ) : null}
                </div>

                <div className="col-md-6">
                  <label>
                    Zip code{" "}
                    <span className=" text-danger fw-bold">
                      {" "}
                      ( fill exact zipcode )
                    </span>
                  </label>
                  <input
                    placeholder="Zip code"
                    className="login-field"
                    type="number"
                    {...register("zip_code", { required: true })}
                  />
                  {errors["zip_code"] ? (
                    <DisplayValidationErrors
                      message={errorMessages.FILL_FIELD}
                    />
                  ) : null}
                </div>

                <div className="col-md-6">
                  <label>Pitcture</label>
                  <input
                    placeholder="Picture"
                    className="login-field"
                    type="file"
                    {...register("postImage", { required: true })}
                  />
                  {errors["postImage"] ? (
                    <DisplayValidationErrors
                      message={errorMessages.FILL_FIELD}
                    />
                  ) : null}
                </div>
              </div>
            </>

            <div className="submission-form-popup">
              <button
                disabled={loading || loader}
                type="submit"
                className="login-btn "
              >
                {loading || loader ? (
                  <img className="loader-image" src={LoaderImg} />
                ) : (
                  "Add"
                )}
              </button>

              <button
                disabled={loading || loader}
                onClick={cancel}
                type="button"
                className="login-btn"
              >
                Cancel
              </button>
            </div>
          </form>
        </Box>
      </Modal>
      <Toaster />
    </>
  );
};
export default AddVenue;
