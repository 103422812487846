const initialState ={
    allBids:[],
    bidableEvents:[]
}

const BidsReducer =(state=initialState,action)=>{
    
    switch(action.type){
  
        case "SAVE_MY_BIDS":
            return{
                allBids: [...action.payload]
            }
        
        case "SAVE_BIDABLE_BIDS":
                return{
                    ...state,
                    bidableEvents: [...action.payload]
                }

        default:
            return state;
    }
}
export default BidsReducer;