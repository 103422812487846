import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import LoaderImg from "../../../Assets/loader.gif";

import {
  GettingIndivualVenueBasedOnIdPrivateAction,
  UpdateVenueAction,
} from "../../../Redux/Action/Venues";
import EditForm from "./EditForm";

const options = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditVenue = ({ showModal, closeModal, cancel }) => {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.Loading.loading);
  const venues = useSelector((state) => state.Venues.venueBasedOnId);
  const authToken = useSelector((state) => state.Auth.authToken);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
  } = useForm({
    values: {
      name: venues.name,
      email: venues.email,
      image: venues.image,
      address: venues.address,
      state: venues.state,
      city: venues.city,
      zip_code: venues.zip_code,
      phone: venues.phone,
      introduction: venues.introduction,
      bio: venues.bio,
      key_information: venues.key_information,
      more_information: venues.more_information,
      rate_from: venues.rate_from,
      rate_to: venues.rate_to,
      setup_hours: venues.setup_hours,
      website: venues.website,
      facebook: venues.facebook,
      twitter: venues.twitter,
      instagram: venues.instagram,
      published: venues.published,
    },
  });

  const submitHandler = (data) => {
    const val = Object.values(data.image);
    const formData = new FormData();
    formData.append("name", data["name"]);
    formData.append("email", data["email"]);
    if (data.image != venues.image) {
      formData.append("image", val[0]);
    }
    formData.append("address", data["address"]);
    formData.append("city", data["city"]);
    formData.append("state", data["state"]);
    formData.append("zip_code", data["zip_code"]);
    formData.append("phone", data["phone"]);
    formData.append("introduction", data["introduction"]);
    formData.append("bio", data["bio"]);
    formData.append("key_information", data["key_information"]);
    formData.append("more_information", data["more_information"]);
    formData.append("rate_from", data["rate_from"]);
    formData.append("rate_to", data["rate_to"]);
    formData.append("setup_hours", data["setup_hours"]);
    formData.append("website", data["website"]);
    formData.append("facebook", data["facebook"]);
    formData.append("twitter", data["twitter"]);
    formData.append("instagram", data["instagram"]);
    formData.append("published", data["published"]);
    dispatch(
      UpdateVenueAction(formData, authToken, venues.id, (type, message) => {
        toast[type](message, {
          duration: 5000,
        });
        if (type == "success") {
          closeModal();
          dispatch(
            GettingIndivualVenueBasedOnIdPrivateAction(venues?.id, authToken)
          );
        }
      })
    );
  };

  useEffect(() => {}, [venues]);

  return (
    <>
      <Modal
        open={showModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="edit-venue-page"
      >
        <Box sx={style} className="modal-form">
          <form onSubmit={handleSubmit(submitHandler)}>
            <>
              <p className=" text-danger text-uppercase h6">Edit Venue</p>

              <button type="button" onClick={cancel} className="btn close-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </button>
              <div className="row">
                <EditForm
                  options={options}
                  venues={venues}
                  Controller={Controller}
                  control={control}
                  register={register}
                  errors={errors}
                />
              </div>
            </>

            <div className="submission-form-popup">
              <button disabled={loader} type="submit" className="login-btn ">
                {loader ? (
                  <img className="loader-image" src={LoaderImg} />
                ) : (
                  "Update"
                )}
              </button>

              <button onClick={cancel} type="button" className="login-btn">
                Cancel
              </button>
            </div>
          </form>
        </Box>
      </Modal>
      <Toaster />
    </>
  );
};
export default EditVenue;
