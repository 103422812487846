import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import OverviewTab from "../../Components/Body/Venues/TabsSection/OverviewTab";
import Events from "../../Components/Body/Venues/TabsSection/Events";
import { useDispatch, useSelector } from "react-redux";
import {
  FollowVenueAction,
  GettingIndivualVenueBasedOnIdPublicAction,
  UnFollowVenueAction,
} from "../../Redux/Action/Venues";
import SliderPopup from "../../Components/Body/Venues/TabsSection/SliderPopup";
import { Toaster, toast } from "react-hot-toast";
import HeaderImages from "../../Components/Body/Venues/HeaderImages";
import Calendar from "../../Components/Body/Venues/TabsSection/Calender/Calender";
import CalenderEventPopup from "../../Components/Body/Venues/TabsSection/Calender/CalenderEventPopup";
import AddReviewModal from "../../Components/Body/ReviewModal";
import { AddReviewAction } from "../../Redux/Action/UpcomingEvents";
import Map from "../Protected/Venues/Map";
import VanueGallery from "./../Protected/Venues/Tabs/VanuesGallery";

//import Geocode from "react-geocode";
// Set your API key here
//Geocode.setApiKey('AIzaSyBHkfmgOCG70ph1A4JNmv0rwq97PxfBMag');

const VenueDetailsPublic = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const venueDetail = useSelector((state) => state.Venues.venueBasedOnId);
  const [showSliderPopup, setShowSliderPopup] = useState(false);
  const authToken = useSelector((state) => state.Auth.authToken);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [eventDetailsPopup, setEventDetailsPopup] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  //ADD Review Modal
  const openAddModal = () => {
    setShowAddModal(true);
  };

  const closeAddReviewModal = (data, id) => {
    setShowAddModal(false);
    dispatch(
      AddReviewAction(id, authToken, data, (type, message) => {
        toast[type](`${message}`, {
          duration: 5000,
        });
      })
    );
  };
  const cancelAddReviewModal = () => {
    setShowAddModal(false);
  };

  //Opend Slider popup
  const openSliderPopup = () => {
    setShowSliderPopup(true);
  };
  const closeSliderPopup = () => {
    setShowSliderPopup(false);
  };

  //Show Calender  Events
  const openEventDetailsPopup = (val) => {
    setSelectedEvent(val);
    setEventDetailsPopup(true);
  };
  const closeEventDetailsPopup = () => {
    setEventDetailsPopup(false);
  };

  //follwow unfollow code
  const followVenueHandler = () => {
    const payload = { venue: id };
    dispatch(
      FollowVenueAction(payload, authToken, (type, message) => {
        toast[type](`${message}`, {
          duration: 5000,
        });
        if (type === "success")
          dispatch(GettingIndivualVenueBasedOnIdPublicAction(id, authToken));
      })
    );
  };

  const unFollowVenueHandler = () => {
    dispatch(
      UnFollowVenueAction(id, authToken, (type, message) => {
        toast[type](`${message}`, {
          duration: 5000,
        });
        if (type === "success")
          dispatch(GettingIndivualVenueBasedOnIdPublicAction(id, authToken));
      })
    );
  };

  /*   const handleGetCoordinates = () => {
    Geocode.fromAddress(`${venueDetail.address}, ${venueDetail.city}, ${venueDetail.state}, ${venueDetail.zip_code}`).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        //setCoordinates({ lat, lng });
       
      },
      (error) => {
        console.error('Error fetching coordinates:', error);
      }
    );
  };
 */

  //useeffects hook
  useEffect(() => {
    dispatch(GettingIndivualVenueBasedOnIdPublicAction(id, authToken));
  }, []);

  return (
    <>
      <section className="photo-gallary-slide">
        <div className="container-fluid">
          <div className="row p-2  bg-dark">
            <HeaderImages
              openSliderPopup={openSliderPopup}
              photos={venueDetail?.media?.filter(
                (item) => item.media_type === "Photo"
              )}
            />
          </div>
        </div>
      </section>

      <section className="header-tabs position-relative">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="tabs-section position-relative">
                <h6 className=" followers-venue bg-danger">
                  <span className="me-3">Followers</span>
                  <span className="ms-3"> {venueDetail.followers}</span>
                </h6>
                <nav className="headr-navs-bg">
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      className="nav-link active"
                      id="Overview-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      OVERVIEW
                    </button>
                    <button
                      className="nav-link"
                      id="Events-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                    >
                      UPCOMING EVENTS
                    </button>
                    <button
                      className="nav-link"
                      id="Calender-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-contact"
                      type="button"
                      role="tab"
                      aria-controls="nav-contact"
                      aria-selected="false"
                    >
                      CALENDAR
                    </button>
                    <button
                      className="nav-link"
                      id="media-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-media"
                      type="button"
                      role="tab"
                      aria-controls="nav-media"
                      aria-selected="false"
                    >
                      MEDIA
                    </button>
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="Overview-tabs"
                  >
                    <OverviewTab
                      venueDetail={venueDetail}
                      isVenuePrivate={false}
                    />
                  </div>

                  <div
                    className="tab-pane fade"
                    id="nav-profile"
                    VanueGallery
                    role="tabpanel"
                    aria-labelledby="Events-tabs"
                  >
                    <Events id={id} />
                  </div>

                  <div
                    className="tab-pane fade"
                    id="nav-contact"
                    role="tabpanel"
                    aria-labelledby="Calender-tabs"
                  >
                    <Calendar
                      isVenuePublic={true}
                      openEventDetailsPopup={openEventDetailsPopup}
                      id={id}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-media"
                    role="tabpanel"
                    aria-labelledby="media-tabs"
                  >
                    <VanueGallery id={id} isPublic={true} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {authToken ? (
                <div className="d-flex justify-content-center">
                  <button
                    onClick={
                      venueDetail?.follow
                        ? unFollowVenueHandler
                        : followVenueHandler
                    }
                    className="btn btn-danger rounded-1 ms-1 mt-3"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-person-fill-add me-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                      <path d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z" />
                    </svg>
                    {venueDetail?.follow ? "Unfollow" : "Follow"}
                  </button>
                  <button
                    onClick={openAddModal}
                    className="btn btn-danger mt-3 py-2 ms-2  follow-btn"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      fill="currentColor"
                      className="bi bi-plus"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                    Add reviews
                  </button>
                </div>
              ) : null}

              <div className="blank-box mt-4 h-auto">
                <h6 className="updates">Map</h6>
                <Map dataMap={venueDetail} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {showSliderPopup && (
        <SliderPopup
          showSliderPopup={showSliderPopup}
          closeSliderPopup={closeSliderPopup}
          media={venueDetail?.media}
        />
      )}
      {eventDetailsPopup && (
        <CalenderEventPopup
          selectedEvent={selectedEvent}
          eventDetailsPopup={eventDetailsPopup}
          closeEventDetailsPopup={closeEventDetailsPopup}
        />
      )}
      <Toaster />
      {showAddModal && (
        <AddReviewModal
          selectedId={id}
          cancel={cancelAddReviewModal}
          showModal={showAddModal}
          closeModal={closeAddReviewModal}
          reviewType={"venue_rating"}
        />
      )}
    </>
  );
};
export default VenueDetailsPublic;
