import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RegisterAction } from "../../Redux/Action/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import FacebookLogin from "react-facebook-login";
import {
  ButtonConstants,
  Constants,
  InputNames,
  errorMessages,
  fbButtonUI,
} from "../../Utilities";
import { DisplayValidationErrors } from "../../Utilities";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import LoaderImg from "../../Assets/loader.gif";
import { jwtDecode } from "jwt-decode";

const Register = () => {
  const [hidePass, setHidePass] = useState(true);
  const [hideConPass, setHideConPass] = useState(true);
  const [registerWithEmail, setRegisterWithEmail] = useState(false);
  const loader = useSelector((state) => state.Loading.loading);
  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const responseFacebook = (response) => {};

  const registerMethodHandler = (e) => {
    e.preventDefault();
    setRegisterWithEmail(!registerWithEmail);
  };

  function handleSocialLogin(credentialResponse) {
    var userObject = jwtDecode(credentialResponse.credential);
    if (Object.keys(userObject).length > 0) {
      const payload = {
        email: userObject["email"],
        password: userObject["sub"],
        first_name: userObject["given_name"],
        last_name: userObject["family_name"],
      };
      dispatch(
        RegisterAction(payload, (type, message) => {
          toast[type](message);
          if (type === "success") {
            navigate("/upcoming_events");
          }
        })
      );
    }
  }

  const submitHandler = (data) => {
    const payload = {
      email: data[InputNames.EMAIL],
      password: data[InputNames.PASSWORD],
      first_name: data[InputNames.FNAME],
      last_name: data[InputNames.LNAME],
      artist_or_band: data[InputNames.IAM_ARTIST_BRAND],
      venue_owner: data[InputNames.IAM_VANUE_WONNER],
      looking_for_local_music: data[InputNames.LOOKING_FOR_LOCAL_MUSIC],
    };

    dispatch(
      RegisterAction(payload, (type, message) => {
        toast[type](message);
        if (type === "success") {
          navigate("/upcoming_events");
        }
        if (type !== "error") {
          reset();
        }
      })
    );
  };

  useEffect(() => {
    if (window.google) {
      /* global google */
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
        callback: handleSocialLogin,
      });
      google.accounts.id.renderButton(document.getElementById("signInDiv"), {
        type: "button",
        theme: "filled_blue",
        size: "large",
        text: "signup_with",
        width: "100%",
        shape: "pill",
        logo_alignment: "right",
      });
    }
  });

  return (
    <>
      <section className="login-page login_backround">
        <div className=" container-fluid py-5">
          <div className="row d-flex justify-content-center">
            <div className=" col-md-5">
              <div className="card login-card">
                <div className=" card-title text-center login-head">
                  Welcome to BandsOnDemand
                </div>
                <div className=" card-body">
                  <form onSubmit={handleSubmit(submitHandler)}>
                    {registerWithEmail && (
                      <>
                        <div>
                          <label>{Constants.FIRST_NAME}</label>
                          <input
                            placeholder={Constants.FIRST_NAME}
                            className="login-field"
                            type="text"
                            {...register(InputNames.FNAME, { required: true })}
                          />
                          {errors[InputNames.FNAME] ? (
                            <DisplayValidationErrors
                              message={errorMessages.FILL_FIELD}
                            />
                          ) : null}
                        </div>

                        <div>
                          <label>{Constants.LAST_NAME}</label>
                          <input
                            placeholder={Constants.LAST_NAME}
                            className="login-field"
                            type="text"
                            {...register(InputNames.LNAME, { required: true })}
                          />
                          {errors[InputNames.LNAME] ? (
                            <DisplayValidationErrors
                              message={errorMessages.FILL_FIELD}
                            />
                          ) : null}
                        </div>

                        <div>
                          <label>{Constants.EMAIL}</label>
                          <input
                            placeholder={Constants.EMAIL}
                            className="login-field"
                            type="email"
                            {...register(InputNames.EMAIL, { required: true })}
                          />
                          {errors[InputNames.EMAIL] ? (
                            <DisplayValidationErrors
                              message={errorMessages.FILL_FIELD}
                            />
                          ) : null}
                        </div>

                        <div className="position-relative">
                          <label>{Constants.PASSWORD}</label>

                          <input
                            placeholder={Constants.PASSWORD}
                            className="login-field"
                            type={hidePass ? "password" : "text"}
                            {...register(InputNames.PASSWORD, {
                              required: true,
                              pattern:
                                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/,
                            })}
                          />
                          {!hidePass ? (
                            <span
                              onClick={() => {
                                setHidePass(true);
                              }}
                              className="pass-icon"
                            >
                              <i className="fa-solid fa-eye fa-sm"></i>
                            </span>
                          ) : (
                            <span
                              onClick={() => {
                                setHidePass(false);
                              }}
                              className="pass-icon"
                            >
                              <i className="fa-solid fa-eye-slash fa-sm"></i>
                            </span>
                          )}
                          {errors[InputNames.PASSWORD]?.type == "required" ? (
                            <DisplayValidationErrors
                              message={errorMessages.FILL_FIELD}
                            />
                          ) : null}
                          {errors[InputNames.PASSWORD]?.type == "pattern" ? (
                            <DisplayValidationErrors
                              message={errorMessages.PASSWORD_STRENGTH_MESSAGE}
                            />
                          ) : null}
                        </div>
                        <div className="position-relative">
                          <label>{Constants.CONFIRM_PASSWORD}</label>
                          <input
                            placeholder={Constants.CONFIRM_PASSWORD}
                            className="login-field"
                            type={hideConPass ? "password" : "text"}
                            {...register(InputNames.CONFIRM_PASSWORD, {
                              required: true,
                              validate: (val) => {
                                if (watch(InputNames.PASSWORD) != val) {
                                  return errorMessages.PASSWORD_NOT_MATCH;
                                }
                              },
                            })}
                          />
                          {!hideConPass ? (
                            <span
                              onClick={() => {
                                setHideConPass(true);
                              }}
                              className="pass-icon"
                            >
                              <i className="fa-solid fa-eye fa-sm"></i>
                            </span>
                          ) : (
                            <span
                              onClick={() => {
                                setHideConPass(false);
                              }}
                              className="pass-icon"
                            >
                              <i className="fa-solid fa-eye-slash fa-sm"></i>
                            </span>
                          )}

                          {errors[InputNames.CONFIRM_PASSWORD]?.type ==
                          "validate" ? (
                            <DisplayValidationErrors
                              message={errorMessages.PASSWORD_NOT_MATCH}
                            />
                          ) : null}
                          {errors[InputNames.CONFIRM_PASSWORD]?.type ==
                          "required" ? (
                            <DisplayValidationErrors
                              message={errorMessages.FILL_FIELD}
                            />
                          ) : null}
                        </div>

                        <div className="ps-3 pb-3">
                          <div className="position-relative mt-1 d-flex align-items-center">
                            <input
                              type="checkbox"
                              defaultChecked
                              checked
                              onClick={(e) => e.preventDefault()}
                              {...register(InputNames.LOOKING_FOR_LOCAL_MUSIC)}
                            />
                            <span className="signin-check-box-lable">
                              {Constants.LOOKING_FOR_LOCAL_MUSIC}
                            </span>
                          </div>

                          <div className="position-relative d-flex align-items-center">
                            <input
                              type="checkbox"
                              {...register(InputNames.IAM_VANUE_WONNER, {
                                required: false,
                              })}
                            />
                            <span className="signin-check-box-lable">
                              {Constants.VANUE_WONNERS}
                            </span>
                          </div>

                          <div className="position-relative mt-1 mb-2 d-flex align-items-center">
                            <input
                              type="checkbox"
                              {...register(InputNames.IAM_ARTIST_BRAND, {
                                required: false,
                              })}
                            />
                            <span className="signin-check-box-lable">
                              {Constants.ARTIST_BANDS}
                            </span>
                          </div>
                          {errors[InputNames.USER_TYPE]?.type == "required" ? (
                            <DisplayValidationErrors
                              message={errorMessages.FILL_FIELD}
                            />
                          ) : null}
                        </div>
                      </>
                    )}

                    <div>
                      {!registerWithEmail ? (
                        <button
                          type="button"
                          onClick={registerMethodHandler}
                          className="login-btn"
                        >
                          {ButtonConstants.CONTINUE_WITH_EMAIL}
                        </button>
                      ) : (
                        <button
                          disabled={loader}
                          type="submit"
                          className="login-btn"
                        >
                          {loader ? (
                            <img className="loader-image" src={LoaderImg} />
                          ) : (
                            "Sign Up"
                          )}
                        </button>
                      )}
                    </div>
                    <p className="or-text">OR</p>
                    <div>
                      <FacebookLogin
                        appId={process.env.REACT_APP_FACEBOOK_API_ID}
                        autoLoad={false}
                        fields="name,email,picture"
                        buttonStyle={{
                          width: "100%",
                          padding: "8px",
                          fontSize: "17px",
                          color: "#fff",
                          border: "none",
                          borderRadius: "30px",
                          background: "#00367c",
                        }}
                        textButton={fbButtonUI()}
                        callback={responseFacebook}
                      />
                    </div>
                    <p className="or-text">OR</p>
                    <div id="signInDiv" />
                    <h3 className="already-class">
                      Already a member?
                      <Link className="signin-text" to="/login">
                        {ButtonConstants.SIGN_IN}
                      </Link>
                    </h3>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Toaster />
    </>
  );
};

export default Register;
