import React from "react";
import moment from "moment";
import RatingStars from "./RatingStars";
import { useSelector } from "react-redux";
const IndivualReview = ({ review }) => {
  const profile = useSelector((state)=>state.Auth.profile)
 
  
   let rating=""
  //code for finding full, half and empty
  if(profile.venue_owner==true){
    rating = review.venue_rating;
  }else{
    rating = review.artist_rating
  }
  
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating - fullStars >= 0.5;
  let blankStar = 5 - fullStars - (hasHalfStar ? 1 : 0);
   
  const shortName =  review?.user.split(" ")
  return (
    <section className="review">
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-2">
            <div className="d-flex justify-content-center">
              <h4 className="ot text-uppercase">{`${shortName?.[0]?.slice(0,1)}${shortName?.[1] !=undefined ?shortName?.[1].slice(0,1):""} `}</h4>
            </div>
            <h5 className="opentable text-capitalize">{review?.user}</h5>
          </div>
          <div className="col-md-10">
            <div className="row align-items-baseline">
              <div className="col-md-6 p-0"> 
                 <RatingStars hasHalfStar={hasHalfStar} blankStar={blankStar} fullStars={fullStars}/>        
              </div>
              <div className="col-md-3">
               
                  <div className="m-0 p-0 review-para fw-bold d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-clock mx-1" viewBox="0 0 16 16">
                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                  </svg>
                   <span > {moment(review?.created_at).startOf("hour").fromNow()}</span>
                  </div>
               
              </div>
            </div>
            <h4 className="food-was mt-3 text-capitalize">{review?.review}</h4>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IndivualReview;

{/*    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" className="bi bi-star-half mx-1" viewBox="0 0 16 16">
          <path d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z"/>
      </svg>
*/}