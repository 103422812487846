import "react-big-calendar/lib/css/react-big-calendar.css";
//import './toolbar.css'
import moment from "moment";
import { useEffect, useState } from "react";
import { momentLocalizer, Navigate } from "react-big-calendar";
import "../../../../Calendar/fields/DateTimePicker.css";

const localizer = momentLocalizer(moment);

const CustomToolbar = (ToolbarProps) => {
  const [viewState, setViewState] = useState("month");

  const goToDayView = () => {
    ToolbarProps.onView("day");
    setViewState("day");
  };
  const goToWeekView = () => {
    ToolbarProps.onView("week");
    setViewState("week");
  };
  const goToMonthView = () => {
    ToolbarProps.onView("month");
    setViewState("month");
  };

  const goToBack = () => {
    ToolbarProps.onNavigate(Navigate.PREVIOUS);
  };

  const goToNext = () => {
    ToolbarProps.onNavigate(Navigate.NEXT);
  };

  const goToToday = () => {
    ToolbarProps.onNavigate(Navigate.TODAY);
  };

  return (
    <div>
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          <button type="button" onClick={goToBack}>
            back
          </button>
          <button type="button" onClick={goToToday}>
            today
          </button>
          <button type="button" onClick={goToNext}>
            next
          </button>
        </span>
        <span className="rbc-toolbar-label">{ToolbarProps.label}</span>
        <span className="rbc-btn-group">
        </span>
      </div>
    </div>
  );
};

export default CustomToolbar;
