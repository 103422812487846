import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { SendOtpAction } from "../../Redux/Action/AuthAction";
import { DisplayValidationErrors } from "../../Utilities";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import LoaderImg from "../../Assets/loader.gif";
const SendOtp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = useSelector((state) => state.Loading.loading);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const submitHandler = (data) => {
    const payload = {
      email: data.email,
    };
    dispatch(
      SendOtpAction(payload, (type, message) => {
        toast[type](message);
        localStorage.setItem("reset_email", payload.email);
        if (type == "success") {
          setTimeout(() => {
            navigate("/reset_password");
          }, 100);
        }
      })
    );
  };


  return (
    <section className="login_backround">
      <div className=" container-fluid py-5">
        <div className="row d-flex justify-content-center">
          <div className=" col-md-4">
            <div className="card login-card">
              <div className=" card-title text-center login-head">
                Welcome to BandsOnDemand
              </div>
              <div className=" card-body">
                <form onSubmit={handleSubmit(submitHandler)}>
                  <div>
                    <label>Email</label>
                    <input
                      placeholder="Email"
                      className="login-field"
                      type="text"
                      {...register("email", { required: true })}
                    />
                    {errors["email"] ? (
                      <DisplayValidationErrors message="Please fill field" />
                    ) : null}
                  </div>

                  <div>
                    <button
                      disabled={loader}
                      type="submit"
                      className="login-btn"
                    >
                      {loader ? <img className="loader-image" src={LoaderImg} /> : "Reset passwords"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </section>
  );
};

export default SendOtp;
