import { applyMiddleware, createStore } from "redux";
import RootReducer from "./Reducer/RootReducer";
import createSagaMiddleware from "redux-saga";
import { WatcherFunction } from "./Saga";
import { persistReducer , persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig ={
    key:'root',
    storage,
   // blacklist : ["Auth"],
   whitelist :["Auth"]
}
const perstReducer = persistReducer(persistConfig, RootReducer)
const sagaMiddleware = createSagaMiddleware();
const store = createStore(perstReducer, applyMiddleware(sagaMiddleware));
export const perstore = persistStore(store)
sagaMiddleware.run(WatcherFunction);
export default store;