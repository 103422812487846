import React from 'react'

const HeaderImages = ({photos ,openSliderPopup}) => {
    const photosArray = photos?.slice(0,5)
  return (
    <>
       {photosArray?.length==1 && <>
            <div className='row p-0 m-0' >
                <div className='col-md-12 p-0 pe-1' >
                    <img className="lightbox6-img sec3-istimg-height" src={photosArray[0]?.media } style={{cursor:'pointer'}} onClick={openSliderPopup}/> 
                </div>
            </div>  
          </> 
        }
        {photosArray?.length==2 && <>
            <div className='row p-0 m-0' >
                <div className='col-md-6 p-0 pe-1' >
                    <img className="lightbox6-img sec3-istimg-height" src={photosArray[0]?.media } style={{cursor:'pointer'}} onClick={openSliderPopup}/> 
                </div>
                <div className='col-md-6 p-0' >
                    <img className="lightbox6-img sec3-istimg-height" src={photosArray[1]?.media } style={{cursor:'pointer'}} onClick={openSliderPopup}/> 
                </div>
               
            </div>  
          </> 
        }
       {photosArray?.length==3 && <>
            <div className='row p-0 m-0' >
                <div className='col-md-8 p-0 pe-1' >
                    <img className="lightbox6-img sec3-istimg-height" src={photosArray[0]?.media } style={{cursor:'pointer'}} onClick={openSliderPopup}/> 
                </div>
              
                <div className='col-md-4 p-0' >
                <img className="lightbox6-img slider-img-height mb-1" src={photosArray[1]?.media } style={{cursor:'pointer'}} onClick={openSliderPopup} />
                <img className="lightbox6-img slider-img-height" src={photosArray[2]?.media }  style={{cursor:'pointer'}} onClick={openSliderPopup}/>  
                </div>
            </div>  
          </> 
        }
        {photosArray?.length==4 && <>
            <div className='row p-0 m-0' >
                <div className='col-md-4 p-0 pe-1' >
                    <img className="lightbox6-img sec3-istimg-height" src={photosArray[0]?.media } style={{cursor:'pointer'}} onClick={openSliderPopup}/> 
                </div>
                <div className='col-md-4 p-0 pe-1' > 
                <img className="lightbox6-img sec3-istimg-height    " src={photosArray[1]?.media } style={{cursor:'pointer'}} onClick={openSliderPopup}/> 
                </div>
                <div className='col-md-4 p-0' >
                <img className="lightbox6-img slider-img-height mb-1" src={photosArray[2]?.media } style={{cursor:'pointer'}} onClick={openSliderPopup} />
                <img className="lightbox6-img slider-img-height" src={photosArray[3]?.media }  style={{cursor:'pointer'}} onClick={openSliderPopup}/>  
                </div>
            </div>  
          </> 
        }
        
        {photosArray?.length==5 && <>
            <div className='row p-0 m-0' >
                <div className='col-md-6 p-0 pe-1' >
                <img className="lightbox6-img sec5-istimg-height" src={photosArray[0]?.media } style={{cursor:'pointer'}} onClick={openSliderPopup}/> 
                </div>
                <div className='col-md-3 p-0 pe-1' >
                <img className="lightbox6-img slider-img-height mb-1" src={photosArray[1]?.media } style={{cursor:'pointer'}} onClick={openSliderPopup} />
                <img className="lightbox6-img slider-img-height" src={photosArray[2]?.media } style={{cursor:'pointer'}} onClick={openSliderPopup} />  
                </div>
                <div className='col-md-3 p-0' >
                <img className="lightbox6-img slider-img-height mb-1" src={photosArray[3]?.media } style={{cursor:'pointer'}} onClick={openSliderPopup} />
                <img className="lightbox6-img slider-img-height" src={photosArray[4]?.media }  style={{cursor:'pointer'}} onClick={openSliderPopup}/>  
                </div>
            </div> 
          </> 
        }
    </>
  )
}

export default HeaderImages
