import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import DummyImage from "../../../../Assets/noimg.jpg";
import { Link } from "react-router-dom";

const ArtistUpcomingEvents = ({ artist }) => {
  const loader = useSelector((state) => state.Loading.loading);

  return (
    <section className="venue-events">
      <div className="container">
        <div className="row py-3">
          <div className="col-md-12">
            {artist?.upcoming_events?.length && !loader ? (
              <div>
                <div className="row">
                  {artist?.upcoming_events?.map((item) => {
                    return (
                      <div key={item.id} className=" mb-3">
                        <div className="bid-cards">
                          <div className="row g-0">
                            <div className="col-md-6">
                              <img
                                src={
                                  item?.venue.image
                                    ? item.venue.image
                                    : DummyImage
                                }
                                className="venue-event-img p-3"
                                alt="..."
                              />
                            </div>
                            <div className="col-md-5">
                              <div className="card-body">
                                <div className="table-responsive ">
                                  <table className=" table table-bordered mt-2">
                                    <tbody>
                                      <tr>
                                        <th className="text-danger table-text-font-size">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-buildings me-2"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022ZM6 8.694 1 10.36V15h5V8.694ZM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15Z" />
                                            <path d="M2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-2 2h1v1H2v-1Zm2 0h1v1H4v-1Zm4-4h1v1H8V9Zm2 0h1v1h-1V9Zm-2 2h1v1H8v-1Zm2 0h1v1h-1v-1Zm2-2h1v1h-1V9Zm0 2h1v1h-1v-1ZM8 7h1v1H8V7Zm2 0h1v1h-1V7Zm2 0h1v1h-1V7ZM8 5h1v1H8V5Zm2 0h1v1h-1V5Zm2 0h1v1h-1V5Zm0-2h1v1h-1V3Z" />
                                          </svg>
                                          Venue
                                        </th>
                                        <td className="text-capitalize table-text-font-size ">
                                          {item.venue.name
                                            ? item.venue.name
                                            : "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="text-danger table-text-font-size ">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-geo-alt-fill me-2"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                          </svg>{" "}
                                          Address
                                        </th>
                                        <td className="text-capitalize table-text-font-size ">
                                          {item.venue.address
                                            ? item.venue.address
                                            : "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="text-danger table-text-font-size ">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-music-note me-2"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M9 13c0 1.105-1.12 2-2.5 2S4 14.105 4 13s1.12-2 2.5-2 2.5.895 2.5 2z" />
                                            <path
                                              fillRule="evenodd"
                                              d="M9 3v10H8V3h1z"
                                            />
                                            <path d="M8 2.82a1 1 0 0 1 .804-.98l3-.6A1 1 0 0 1 13 2.22V4L8 5V2.82z" />
                                          </svg>
                                          Genre
                                        </th>
                                        <td className="text-capitalize  table-text-font-size">
                                          {item.genre.name
                                            ? item.genre.name
                                            : "NA"}
                                          </td>
                                      </tr>
                                      <tr>
                                        <th className="text-danger table-text-font-size ">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-clock-fill me-2"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                          </svg>{" "}
                                          Start
                                        </th>
                                        <td className="table-text-font-size">
                                          {moment(item?.start_time).format(
                                            "MMMM Do YYYY, h a"
                                          )}
                                        </td>
                                      </tr>

                                      <tr>
                                        <th className=" text-danger table-text-font-size ">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-clock-fill me-2"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                          </svg>{" "}
                                          End
                                        </th>
                                        <td className=" table-text-font-size">
                                          {moment(item?.end_time).format(
                                            "MMMM Do YYYY, h a"
                                          )}
                                        </td>
                                      </tr>
                                      <tr className=" border-0">
                                        <td
                                          className=" justify-content-center table-text-font-size border-0 "
                                          colSpan={2}
                                        >
                                          <Link
                                            to={`/event-details/${item.id}`}
                                            className="btn btn-danger mb-2  d-grid gap-2 center"
                                          >
                                            <span className=""> View more</span>
                                          </Link>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="row">
                 <div className="col-md-12">
                    <p className="no-data-found">No upcoming event found</p>
                 </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ArtistUpcomingEvents;
