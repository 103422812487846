import React from "react";

const Benifits = () => {
  return (
    <>
      <section id="benifits" className=" py-5 border-bottom border-black ">
        <div className="container px-5 my-5">
          <div className="text-center mb-5">
            <h2 className="fw-bolder">Benefits of BandsOnDemand</h2>
            <p className="lead mb-0">What can BOD do for me?</p>
          </div>
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-6 col-xl-4 ">
              <div className="card mb-5 mb-xl-0 ">
                <div className="card-body p-5">
                  <div className="mb-3">
                    <span className="display-5 fw-bold">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="currentColor"
                        className="bi bi-stars me-1 text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828l.645-1.937zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.734 1.734 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.734 1.734 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.734 1.734 0 0 0 3.407 2.31l.387-1.162zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L10.863.1z" />
                      </svg>
                      Fans
                    </span>
                  </div>
                  <ul className="list-unstyled mb-4">
                    <li className="mb-2">
                      <i className="bi bi-check text-primary"></i>
                      <strong>Find Live Entertainment Nearby</strong>
                    </li>
                    <li className="mb-2">
                      <i className="bi bi-check text-primary"></i>
                      Filter by Venue type
                    </li>
                    <li className="mb-2">
                      <i className="bi bi-check text-primary"></i>
                      Filter by Entertainment type
                    </li>
                    <li className="mb-2">
                      <i className="bi bi-check text-primary"></i>
                      Follow your favorites
                    </li>
                    <li className="mb-2">
                      <i className="bi bi-check text-primary"></i>
                      Notifications of upcoming events
                    </li>
                  </ul>
                  <div className="d-grid">
                    <a className="btn btn-danger " href="#!">
                      Sign Me Up!
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-xl-4">
              <div className="card mb-5 mb-xl-0 ">
                <div className="card-body p-5">
                  <div className="mb-3">
                    <span className="display-5 fw-bold ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="currentColor"
                        className="bi bi-buildings me-1 text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022ZM6 8.694 1 10.36V15h5V8.694ZM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15Z" />
                        <path d="M2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-2 2h1v1H2v-1Zm2 0h1v1H4v-1Zm4-4h1v1H8V9Zm2 0h1v1h-1V9Zm-2 2h1v1H8v-1Zm2 0h1v1h-1v-1Zm2-2h1v1h-1V9Zm0 2h1v1h-1v-1ZM8 7h1v1H8V7Zm2 0h1v1h-1V7Zm2 0h1v1h-1V7ZM8 5h1v1H8V5Zm2 0h1v1h-1V5Zm2 0h1v1h-1V5Zm0-2h1v1h-1V3Z" />
                      </svg>
                      Venues
                    </span>
                  </div>
                  <ul className="list-unstyled mb-4">
                    <li className="mb-2">
                      <i className="bi bi-check text-primary"></i>
                      <strong>Manage your events calendar!</strong>
                    </li>
                    <li className="mb-2">
                      <i className="bi bi-check text-primary"></i>
                      Automate booking events
                    </li>
                    <li className="mb-2">
                      <i className="bi bi-check text-primary"></i>
                      Advertise upcoming events
                    </li>
                    <li className="mb-2">
                      <i className="bi bi-check text-primary"></i>
                      Delight your Fans
                    </li>
                    <li className="mb-2">
                      <i className="bi bi-check text-primary"></i>
                      Drive attendance
                    </li>
                  </ul>
                  <div className="d-grid">
                    <a className="btn btn-danger mt-4 " href="#!">
                      Sign Me Up!
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-xl-4">
              <div className="card">
                <div className="card-body p-5">
                  <div className="mb-3">
                    <span className="display-5 fw-bold">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="currentColor"
                        className="bi bi-file-earmark-music-fill me-1 text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM11 6.64v1.75l-2 .5v3.61c0 .495-.301.883-.662 1.123C7.974 13.866 7.499 14 7 14c-.5 0-.974-.134-1.338-.377-.36-.24-.662-.628-.662-1.123s.301-.883.662-1.123C6.026 11.134 6.501 11 7 11c.356 0 .7.068 1 .196V6.89a1 1 0 0 1 .757-.97l1-.25A1 1 0 0 1 11 6.64z" />
                      </svg>
                      Artists
                    </span>
                  </div>

                  <ul className="list-unstyled mb-4">
                    <li className="mb-2">
                      <i className="bi bi-check text-primary"></i>
                      <strong>Find New Gigs!</strong>
                    </li>
                    <li className="mb-2">
                      <i className="bi bi-check text-primary"></i>
                      Build a fan base
                    </li>
                    <li className="mb-2">
                      <i className="bi bi-check text-primary"></i>
                      Simplify finding &amp; booking
                    </li>
                    <li className="mb-2">
                      <i className="bi bi-check text-primary"></i>
                      Advertise upcoming events
                    </li>
                    <li className="mb-2">
                      <i className="bi bi-check text-primary"></i>
                      Automatic payments
                    </li>
                  </ul>
                  <div className="d-grid">
                    <a className="btn btn-danger mt-5" href="#!">
                      Sign Me Up!
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Benifits;
