import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import toast, { Toaster } from "react-hot-toast";
import LoaderImg from "../../Assets/loader.gif";

import { DisplayValidationErrors, errorMessages } from "../../Utilities";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}; 

const AddMedia = ({ showModal, closeModal, cancel, id }) => {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.Loading.loading);
  const authToken = useSelector((state) => state.Auth.authToken);
  const profile = useSelector((state) => state.Auth.profile);
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();

  const submitHandler = (data) => {
    const val = Object.values(data.postImage);
    if (
      val[0].type.includes("image") ||
      val[0].type.includes("audio") ||
      val[0].type.includes("video") 
    ) {
      const media_type = val[0].type.split("/")[0];

      const formData = new FormData();
      if (profile.artist_or_band == true) {
        formData.append("artist", id);
      }
      if (profile.venue_owner == true) {
        formData.append("venue", id);
      }
      formData.append("media_type",media_type == "image"? "Photo":media_type == "audio"? "Audio":"Video");
      formData.append("media", val[0]);
      closeModal(formData)

    } else {
      toast.error("Please upload only Images, audios and videos");
      reset();
    }
  };

  return (
    <>
      <Modal
        className="media-modal"
        open={showModal}
        fill
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-form">
          <form onSubmit={handleSubmit(submitHandler)}>
            <>
              <p className=" text-danger text-uppercase h6">Add Media Files</p>

              <button
                type="button"
                disabled={loader}
                onClick={cancel}
                className="btn close-btn"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </button>
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <label>Media File</label>
                  <input
                    placeholder="Picture"
                    className="login-field"
                    type="file"
                    {...register("postImage", { required: true })}
                  />
                  {errors["postImage"] ? (
                    <DisplayValidationErrors
                      message={errorMessages.FILL_FIELD}
                    />
                  ) : null}
                </div>
              </div>
              <p className="text-success note text-center">
                Note: Only upload Video, Audio and Image file.
              </p>
            </>

            <div className="submission-form-popup">
              <button disabled={loader} type="submit" className="login-btn ">
                {loader ? (
                  <img className="loader-image" src={LoaderImg} />
                ) : (
                  "Add"
                )}
              </button>

              <button
                disabled={loader}
                onClick={cancel}
                type="button"
                className="login-btn"
              >
                Cancel
              </button>
            </div>
          </form>
        </Box>
      </Modal>
      <Toaster />
    </>
  );
};
export default AddMedia;
