import Fb from "../Assets/fb.png";
import moment from "moment";

export const fbButtonUI = () => {
  return (
    <>
      <span className="me-2">
        <img src={Fb} />
      </span>
      Continue with facebook
    </>
  );
};

export const DisplayValidationErrors = ({ message }) => {
  return <p className="error_register">{message}</p>;
};

export const errorMessages = {
  FILL_FIELD: "Please fill the field.",
  VALID_NUMBER: "Please fill valid number",
  PASSWORD_STRENGTH_MESSAGE:
    "Password must have Minimum eight characters, at \
  least one letter and one number and one sepcial\
  character.",
  PASSWORD_NOT_MATCH: "Password not matched",
};

export const Constants = {
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  NAME: "NAME",
  EMAIL: "Email",
  PHONE_NUMBER: "Phone Number",
  ADDRESS: "ADDRESS",
  CITY: "City",
  STATE: "State",
  ZIP_CODE: "Zip Code",
  PASSWORD: "Password",
  CONFIRM_PASSWORD: "Confirm Password",
  LOOKING_FOR_LOCAL_MUSIC: "I’m looking for local live music events",
  VANUE_WONNERS: "I own a Venue and want to manage events calendar",
  ARTIST_BANDS: "I am an Artist/Band and I’d like to book local gigs",
};

export const InputNames = {
  LNAME: "lname",
  FNAME: "fname",
  NAME: "name",
  EMAIL: "email",
  PASSWORD: "password",
  ADDRESS: "address",
  CITY: "city",
  STATE: "state",
  ZIP_CODE: "zip_code",
  PHONE: "phone",
  CONFIRM_PASSWORD: "confirm_password",
  USER_TYPE:"userType",
  IAM_VANUE_WONNER: "is_vanue_wonner",
  LOOKING_FOR_LOCAL_MUSIC: "looking_for_local",
  IAM_ARTIST_BRAND: "iam_artist_brand",
};
export const ButtonConstants = {
  CONTINUE_WITH_EMAIL: "Continue with email",
  SIGN_UP: "Sign Up",
  SIGN_IN: "Sign In",
  CONTINUE_WITH_FACEBOOK: "Continue with facebook",
  ADD: "ADD",
  CANCEL: "Cancel",
};

export const Artistsoptions = [
  { value: 18, label: "Artist 15" },
  { value: 2, label: "Artist 2" },
];

export const Venueoptions = [
  { value: 11, label: "Venue 9" },
  { value: 2, label: "Venue 2" },
];

export const Genreoptions = [
  { value: 1, label: "Genre 1" },
  { value: 2, label: "Genre 2" },
];

export const data = [
  {
    id: 39,
    artist: {
      id: 17,
      name: "raj",
      image: null,
    },
    venue: {
      id: 10,
      name: "venue",
      image:
        "http://127.0.0.1:8000/media/venue_images/pexels-pixabay-60597_p4HxqYn.jpg",
    },
    start_time: "2023-08-11T07:05:17Z",
    end_time: "2023-08-15T07:05:20Z",
    status: "Accepted",
    venue_bidding_amount: null,
    artist_band_bidding_amount: 200,
    accepted_bid_amount: null,
  },
  {
    id: 38,
    artist: {
      id: 17,
      name: "vijay",
      image: null,
    },
    venue: {
      id: 10,
      name: "venue",
      image:
        "http://127.0.0.1:8000/media/venue_images/pexels-pixabay-60597_p4HxqYn.jpg",
    },
    start_time: "2023-08-11T07:05:17Z",
    end_time: "2023-08-15T07:05:20Z",
    status: "Pending",
    venue_bidding_amount: null,
    artist_band_bidding_amount: 200,
    accepted_bid_amount: null,
  },
  {
    id: 37,
    artist: {
      id: 17,
      name: "raj",
      image: null,
    },
    venue: {
      id: 10,
      name: "venue",
      image:
        "http://127.0.0.1:8000/media/venue_images/pexels-pixabay-60597_p4HxqYn.jpg",
    },
    start_time: "2023-08-11T07:05:17Z",
    end_time: "2023-08-15T07:05:20Z",
    status: "Rejected",
    venue_bidding_amount: null,
    artist_band_bidding_amount: 200,
    accepted_bid_amount: null,
  },
  {
    id: 35,
    artist: {
      id: 15,
      name: "sohan",
      image: "http://127.0.0.1:8000/media/artist_images/1_NWjnCrh.jpg",
    },
    venue: {
      id: 10,
      name: "venue",
      image:
        "http://127.0.0.1:8000/media/venue_images/pexels-pixabay-60597_p4HxqYn.jpg",
    },
    start_time: "2023-08-14T07:37:25Z",
    end_time: "2023-08-15T07:37:25Z",
    status: "Ongoing",
    venue_bidding_amount: 260,
    artist_band_bidding_amount: 1000,
    accepted_bid_amount: null,
  },
];
