import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {  ChangePasswordAction, RemoveAuth, SendOtpAction } from "../../Redux/Action/AuthAction";
import { DisplayValidationErrors } from "../../Utilities";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoaderImg from "../../Assets/loader.gif";
import toast, { Toaster } from "react-hot-toast";



const ChangePassword = () => {
  const dispatch = useDispatch();
  const [hide, setHide] = useState(true);
  const [hideConfirm, setHideConfirm] = useState(true);
  const authToken = useSelector(state=>state.Auth.authToken)
  const loader = useSelector((state) => state.Loading.loading);
 
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const submitHandler = (data) => {
    const payload = {
      old_password :data.old_password,
      new_password:data.new_password
    };
    dispatch(ChangePasswordAction(payload,authToken,(type,message)=>{
      toast[type](`${message}`,{
        duration: 5000,
      });
    })) 
  
  };

  return (
    <section className="">
      <div className=" container-fluid py-5">
        <div className="row d-flex justify-content-center">
          <div className=" col-md-4">
            <div className="card login-card">
              <div className=" card-title text-center text-danger login-head">
                Change Password
              </div>
              <div className=" card-body">
                <form onSubmit={handleSubmit(submitHandler)}>
                  <div className="position-relative">
                    <label>Old Password</label>
                    <input
                      className="login-field"
                      placeholder="Password"
                      type={hide ? "password" : "text"}
                      {...register("old_password", { required: true })}
                    />
                    {errors["old_password"] ? (
                      <>
                        <p className="error_register">Please fill this field</p>
                      </>
                    ) : null}
                    {!hide ? (
                      <span
                        onClick={() => {
                          setHide(true);
                        }}
                        className="pass-icon"
                      >
                        <i className="fa-solid fa-eye fa-sm"></i>
                      </span>
                    ) : (
                      <span
                        onClick={() => {
                          setHide(false);
                        }}
                        className="pass-icon"
                      >
                        <i className="fa-solid fa-eye-slash fa-sm"></i>
                      </span>
                    )}
                  </div>


                  <div className="position-relative">
                    <label>New Password</label>
                    <input
                      className="login-field"
                      placeholder="New Password"
                      type={hideConfirm ? "password" : "text"}
                      {...register("new_password", { required: true , pattern:
                        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/,
                 })}
                    />
                    {errors?.new_password?.type == "required" ? (
                            <DisplayValidationErrors message="Please fill this field" />
                          ) : null}
                          {errors?.new_password?.type == "pattern" ? (
                            <DisplayValidationErrors
                              message="Password must have Minimum eight characters, at
                              least one letter and one number and one sepcial
                              character."
                            />
                          ) : null}
                    {!hideConfirm ? (
                      <span
                        onClick={() => {
                            setHideConfirm(true);
                        }}
                        className="pass-icon"
                      >
                        <i className="fa-solid fa-eye fa-sm"></i>
                      </span>
                    ) : (
                      <span
                        onClick={() => {
                          setHideConfirm(false);
                        }}
                        className="pass-icon"
                      >
                        <i className="fa-solid fa-eye-slash fa-sm"></i>
                      </span>
                    )}
                  </div>

                  <div>
                    <button disabled={loader} type="submit" className="login-btn">
                     {loader? <img className="loader-image" alt="changing..." src={LoaderImg} />  : "Change password"}   
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </section>
  );
};

export default ChangePassword;
