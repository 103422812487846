import React from "react";

const HeroSection = () => {
  return (
    <>
      <section className="bg-dark py-5 heroSection">
        <div className="container px-5">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-6">
              <div className="text-center my-5">
                <h1 className="display-5 fw-bolder text-white mb-2">
                  BandzOnDemand, the world’s most popular app for live
                  entertainment!
                </h1>
                <p className="lead text-white-50 mb-4">
                  Venues can book live entertainment, Artists can find new gigs,
                  and Fans can find popular venues and live entertainment!
                </p>
                <div className="d-grid gap-3 d-sm-flex justify-content-sm-center">
                  <a
                    className="btn btn-primary btn-lg px-4 me-sm-3"
                    href="#features"
                  >
                    Get Started
                  </a>
                  <a className="btn btn-outline-light btn-lg px-4" href="#!">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSection;