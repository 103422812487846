import Actions from "./Actions";

export const GetUserProfile = (payload) => {
  return {
    type: Actions.GET_PROFILE,
    payload,
  };
};

export const EditUserProfile = (payload, auth, callback) => {
  return {
    type: Actions.EDIT_PROFILE,
    payload,
    auth,
    callback,
  };
};

export const GetUserProfileFromApi = (auth) => {
  return {
    type: Actions.GET_USER_PROFILE_FROM_API,
    auth,
  };
};

export const SaveProfileDetails = (payload) => {
  return {
    type: Actions.SAVE_USER_PROFILE_DETAILS,
    payload,
  };
};

export const AddAdminUserAction = (payload, auth, callback) => {
  return {
    type: Actions.ADD_ADMIN_USER,
    payload,
    auth,
    callback,
  };
};
