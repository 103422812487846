import React from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import EditVenue from "../../../../Pages/Protected/Venues/EditVenue";

const OverviewTab = ({
  venueDetail,
  showEditModal,
  openEditModal,
  cancelEditModal,
  closeEditModal,
  isVenuePrivate,
}) => {
  const authToken = useSelector((state) => state.Auth.authToken);
  return (
    <>
      <section className="review">
        <div className="container">
          {authToken && isVenuePrivate ? (
            <div className="row py-3">
              <div className="col-md-12">
                <button
                  onClick={() => openEditModal(venueDetail?.id)}
                  className=" btn btn-outline-dark"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22  "
                    fill="currentColor"
                    className="bi bi-pencil-square me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fillRule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                  <span>Edit venue</span>
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="row py-3">
            <div className="col-md-12">
              <h5 className="text-danger">INTRODUCTION</h5>
              <p>
                {venueDetail?.introduction
                  ? venueDetail.introduction
                  : "Not available"}
              </p>
              <h5 className="text-danger">BIO</h5>
              <p>{venueDetail?.bio ? venueDetail.bio : "Not available"}</p>

              <table className=" table table-responsive table-bordered">
                <tbody>
                  <tr>
                    <th>Name</th>
                    <td>
                      {venueDetail?.name ? venueDetail.name : "Not available"}
                    </td>
                  </tr>
                  <tr>
                    <th>Address</th>
                    <td>
                      {venueDetail.address
                        ? venueDetail.address
                        : "Not availbale "}
                    </td>
                  </tr>
                  <tr>
                    <th>City</th>
                    <td>
                      {venueDetail?.city ? venueDetail.city : "Not available"}
                    </td>
                  </tr>
                  <tr>
                    <th>State</th>
                    <td>
                      {venueDetail?.state ? venueDetail.state : "Not available"}
                    </td>
                  </tr>
                  <tr>
                    <th>Phone</th>
                    <td>
                      {venueDetail?.phone ? venueDetail.phone : "Not availbale"}
                    </td>
                  </tr>
                  <tr>
                    <th>Rate</th>
                    <td>
                      {" "}
                      {venueDetail?.rate_from
                        ? venueDetail.rate_from
                        : "Not available"}
                      -{" "}
                      {venueDetail?.rate_to
                        ? venueDetail.rate_to
                        : "Not available"}
                    </td>
                  </tr>
                  <tr>
                    <th>Setup hours</th>
                    <td>
                      {venueDetail?.setup_hours
                        ? venueDetail.setup_hours
                        : "Not available"}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="card shadow-sm p-4">
                <h5 className=" text-uppercase text-danger">Social Links </h5>
                <div className=" d-flex ">
                  <button className="svg-social-icons">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="red"
                      className="bi bi-instagram "
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                    </svg>
                  </button>
                  <span className="py-2 px-2 ">
                    {venueDetail?.instagram
                      ? venueDetail.instagram
                      : "Not available"}
                  </span>
                </div>
                <div className=" d-flex ">
                  <button className="svg-social-icons">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="blue"
                      className="bi bi-facebook"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                    </svg>
                  </button>
                  <span className="py-2 px-2">
                    {venueDetail?.facebook
                      ? venueDetail.facebook
                      : "Not available"}
                  </span>
                </div>
                <div className=" d-flex ">
                  <button className="svg-social-icons">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="skyblue"
                      className="bi bi-twitter"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                    </svg>
                  </button>
                  <span className="py-2 px-2">
                    {venueDetail?.twitter
                      ? venueDetail.twitter
                      : "Not available"}
                  </span>
                </div>
                <div className="d-flex">
                  <button className="svg-social-icons">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="black"
                      className="bi bi-globe-asia-australia"
                      viewBox="0 0 16 16"
                    >
                      <path d="m10.495 6.92 1.278-.619a.483.483 0 0 0 .126-.782c-.252-.244-.682-.139-.932.107-.23.226-.513.373-.816.53l-.102.054c-.338.178-.264.626.1.736a.476.476 0 0 0 .346-.027ZM7.741 9.808V9.78a.413.413 0 1 1 .783.183l-.22.443a.602.602 0 0 1-.12.167l-.193.185a.36.36 0 1 1-.5-.516l.112-.108a.453.453 0 0 0 .138-.326ZM5.672 12.5l.482.233A.386.386 0 1 0 6.32 12h-.416a.702.702 0 0 1-.419-.139l-.277-.206a.302.302 0 1 0-.298.52l.761.325Z" />
                      <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM1.612 10.867l.756-1.288a1 1 0 0 1 1.545-.225l1.074 1.005a.986.986 0 0 0 1.36-.011l.038-.037a.882.882 0 0 0 .26-.755c-.075-.548.37-1.033.92-1.099.728-.086 1.587-.324 1.728-.957.086-.386-.114-.83-.361-1.2-.207-.312 0-.8.374-.8.123 0 .24-.055.318-.15l.393-.474c.196-.237.491-.368.797-.403.554-.064 1.407-.277 1.583-.973.098-.391-.192-.634-.484-.88-.254-.212-.51-.426-.515-.741a6.998 6.998 0 0 1 3.425 7.692 1.015 1.015 0 0 0-.087-.063l-.316-.204a1 1 0 0 0-.977-.06l-.169.082a1 1 0 0 1-.741.051l-1.021-.329A1 1 0 0 0 11.205 9h-.165a1 1 0 0 0-.945.674l-.172.499a1 1 0 0 1-.404.514l-.802.518a1 1 0 0 0-.458.84v.455a1 1 0 0 0 1 1h.257a1 1 0 0 1 .542.16l.762.49a.998.998 0 0 0 .283.126 7.001 7.001 0 0 1-9.49-3.409Z" />
                    </svg>
                  </button>
                  <span className="py-2 px-2">
                    {venueDetail?.website
                      ? venueDetail.website
                      : "Not available"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <EditVenue
        cancel={cancelEditModal}
        showModal={showEditModal}
        closeModal={closeEditModal}
      />
    </>
  );
};

export default OverviewTab;
