import React from "react";
import {
  Artistsoptions,
  DisplayValidationErrors,
  Genreoptions,
  Venueoptions,
  errorMessages,
} from "../../../Utilities";
import Select from "react-select";
const EditForm = ({ register, errors, Controller, control, events }) => {
  return (
    <>
      <div className="col-md-6">
        <label>Start Date</label>
        <input
          className="login-field"
          type="datetime-local"
          {...register("start", { required: true })}
        />
        {errors["start"] ? (
          <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
        ) : null}
      </div>

      <div className="col-md-6">
        <label>End Date</label>
        <input
          className="login-field"
          type="datetime-local"
          {...register("end", { required: true })}
        />
        {errors["end"] ? (
          <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
        ) : null}
      </div>

      <div className="col-md-6">
        <label>Introduction</label>
        <input
          placeholder="Introduction"
          className="login-field"
          type="text"
          {...register("introduction", {
            required: true,
          })}
        />
        {errors?.introduction?.type === "required" ? (
          <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
        ) : null}
      </div>

      <div className="col-md-6">
        <label>Description</label>
        <input
          placeholder="Description"
          className="login-field"
          type="text"
          {...register("description", { required: true })}
        />
        {errors["description"] ? (
          <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
        ) : null}
      </div>

      <div className="col-md-6">
        <label>Minimum Rate</label>
        <input
          placeholder="Minium Rate"
          className="login-field"
          type="number"
          {...register("rate_from", { required: true })}
        />
        {errors["rate_from"] ? (
          <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
        ) : null}
      </div>

      <div className="col-md-6">
        <label>Maximum Rate</label>
        <input
          placeholder="Maxiumum Rate"
          className="login-field"
          type="number"
          {...register("rate_to", { required: true })}
        />
        {errors["rate_to"] ? (
          <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
        ) : null}
      </div>
      {/*
      <div className="col-md-6">
        <label>Rate</label>
        <input
          placeholder="Rate"
          className="login-field"
          type="number"
          {...register("rate", { required: true })}
        />
        {errors["rate"] ? (
          <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
        ) : null}
      </div>
      */}

      <div className="col-md-6">
        <label>Venue</label>
        <Controller
          control={control}
          name="venue"
          //rules={{ required: true }}
          render={({ field: { onChange, value, name, ref } }) => (
            <Select
              inputRef={ref}
              classNamePrefix="addl-class"
              options={Venueoptions}
              defaultValue={Venueoptions.find(
                (c) => c.value === events.venue.id
              )}
              value={Venueoptions.find((c) => c.value === value)}
              onChange={(val) => onChange(val.value)}
            />
          )}
        />
        {/* {errors.venue ? <p>Please slecte this field</p> : ""} */}
      </div>

      <div className="col-md-6">
        <label>Genre</label>
        <Controller
          control={control}
          name="genre"
          //rules={{ required: true }}
          render={({ field: { onChange, value, name, ref } }) => (
            <Select
              inputRef={ref}
              classNamePrefix="addl-class"
              options={Genreoptions}
              defaultValue={Genreoptions.find(
                (c) => c.value === events.genre.id
              )}
              value={Genreoptions.find((c) => c.value === value)}
              onChange={(val) => onChange(val.value)}
            />
          )}
        />
        {/*  {errors.genre ? <p>Please slecte this field</p> : ""} */}
      </div>
      <div className="col-md-6">
        <label>Artist</label>

        <Controller
          control={control}
          name="artist"
          //rules={{ required: true }}
          render={({ field: { onChange, value, name, ref } }) => (
            <Select
              inputRef={ref}
              classNamePrefix="addl-class"
              options={Artistsoptions}
              defaultValue={Artistsoptions.find(
                (c) => c.value === events.artist.id
              )}
              value={Artistsoptions.find((c) => c.value === value)}
              onChange={(val) => onChange(val.value)}
            />
          )}
        />
        {/*   {errors.artist ? <p>Please slecte this field</p> : ""} */}
      </div>
      <div className="col-md-6">
        <label>Pitcture</label>
        <input
          placeholder="Picture"
          className="login-field"
          type="file"
          {...register("image")}
        />
      </div>
    </>
  );
};

export default EditForm;
