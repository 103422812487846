import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { BidOnArtistAction } from "../../../../Redux/Action/Bidding";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
};

const BiddingPopup = ({ bidingPopup, closeBidingPopup, bidData }) => {
  const { artist, venueId, venueName } = bidData;
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.Auth.authToken);
  const venueDetail = useSelector((state) => state.Venues.venueBasedOnId);

  const events = useSelector((state) => state.Venues.EventsBasedOnVenueId);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({});

  const submitHandler = (data) => {
    const formData = new FormData();
    formData.append("venue", venueDetail?.id);
    formData.append("artist", artist?.id);
    formData.append("venue_bidding_offer_price_low", data["offer_price_low"]);
    formData.append("venue_bidding_offer_price_high", data["offer_price_high"]);
    formData.append("event", JSON.stringify(data["event"]));
    formData.append("venue_message", data["message"]);

    dispatch(
      BidOnArtistAction(formData, authToken, (type, message) => {
        toast[type](message, {
          duration: 5000,
        });
        if (type == "success") {
          reset();
          closeBidingPopup();
        }
      })
    );
  };

  return (
    <>
      <Modal
        open={bidingPopup}
        onClose={closeBidingPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-bid-popup">
          <button
            type="button"
            onClick={closeBidingPopup}
            className="btn close-btn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
            </svg>
          </button>

          <div className="  table-responsive py-4 px-4">
            <h5 className=" text-danger mb-4">
              Invite artist to bid on event(s)
            </h5>
            <form onSubmit={handleSubmit(submitHandler)}>
              <div className="position-relative">
                <div className="bid-fields">
                  <label className=" fw-bold px-2">Offered Price - Low</label>

                  <input
                    className="bid-fields-input"
                    placeholder="Low Price"
                    type={"number"}
                    {...register("offer_price_low", { required: true })}
                  />
                  {errors["offer_price_low"] ? (
                    <>
                      <p className="error_register">Please fill this field</p>
                    </>
                  ) : null}
                </div>
              </div>
              <div className="position-relative">
                <div className="bid-fields">
                  <label className=" fw-bold px-2">Offered Price - High</label>

                  <input
                    className="bid-fields-input"
                    placeholder="High Price"
                    type={"number"}
                    {...register("offer_price_high", { required: true })}
                  />
                  {errors["offer_price_high"] ? (
                    <>
                      <p className="error_register">Please fill this field</p>
                    </>
                  ) : null}
                </div>
              </div>

              <div className="position-relative">
                <div className="bid-fields">
                  <label className=" fw-bold px-2">Available Events</label>
                  <Controller
                    control={control}
                    name="event"
                    rules={{ required: true }}
                    render={({ field: { onChange, value = [], ref } }) => (
                      <Select
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={events}
                        getOptionLabel={(option) => option.introduction}
                        getOptionValue={(option) => option.id}
                        value={events.filter((event) =>
                          value.includes(event.id)
                        )}
                        onChange={(val) => onChange(val.map((item) => item.id))}
                        isMulti={true}
                        closeMenuOnSelect={false}
                      />
                    )}
                  />

                  {errors.event ? <p>Please select this field</p> : ""}
                </div>
              </div>
              <div className="position-relative">
                <div className="bid-fields">
                  <label className=" fw-bold px-2">Message</label>

                  <textarea
                    className="bid-fields-input"
                    placeholder="Message for artist"
                    type={"text"}
                    {...register("message", { required: true })}
                  />
                  {errors["message"] ? (
                    <>
                      <p className="error_register">Please fill this field</p>
                    </>
                  ) : null}
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <button type="submit" className="btn btn-danger">
                  Send Invite
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
      <Toaster />
    </>
  );
};
export default BiddingPopup;
