import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { ApiEndpoint } from "./../../Utilities/ApiEndpoints";

// const options = [
//   { value: "Rock", label: "Rock" },
//   { value: "Metal", label: "Metal" },
//   { value: "Jazz", label: "Jazz" },
// ];

const options1 = [
  { value: "Indoor", label: "Indoor" },
  { value: "Outdoor", label: "Outdoor" },
  { value: "Large Parties", label: "Large Parties" },
  { value: "Kid Friendly", label: "Kid Friendly" },
  { value: "Dog Friendly", label: "Dog Friendly" },
  { value: "Fire pit", label: "Fire pit" },
  { value: "Over 21", label: "Over 21" },
  { value: "Under 21", label: "Under 21" },
  { value: "Reservations", label: "Reservations" },
  { value: "Late Night", label: "Late Night" },
  { value: "Day Time", label: "Day Time" },
];

const options2 = [
  { value: "Has Food  Options", label: "Has Food Options" },
  { value: "Full Bar", label: "Full Bar" },
  { value: "Craft Beer", label: "Craft Beer" },
  { value: "Wine", label: "Wine" },
  { value: "BYOB", label: "BYOB" },
];

const Filter = ({
  isActive,
  filterButton = () => {},
  hitFilters = () => {},
  clearFilters = () => {},
  isComingEventsPage = false,
  isVenuePage = false,
  isArtistPage = false,
}) => {
  const { register, handleSubmit, control, watch, reset } = useForm({
    defaultValues: {
      radius: 5,
      rating: 3,
      minPrice: 1,
      maxPrice: 10000,
      venue: [],
      gener: [],
      beverageType: [],
      onlyVenuesFollow: false,
      onlyArtistsFollow: false,
    },
  });
  const [onlyArtistsFollowChecked, setOnlyArtistsFollowChecked] =
    useState(false);
  const [onlyVenuesFollowChecked, setOnlyVenuesFollowChecked] = useState(false);

  const handleCheckboxOnlyArtistsFollow = (event) => {
    setOnlyArtistsFollowChecked(event.target.checked);
  };

  const handleCheckboxOnlyVenuesFollow = (event) => {
    setOnlyVenuesFollowChecked(event.target.checked);
  };

  const [selectedRadiusOption, setSelectedRadiusOption] = useState(5);

  const handleSelectedRadiusChange = (event) => {
    setSelectedRadiusOption(Number(event.target.value));
  };

  const submitHandler = (data) => {
    hitFilters(data);
  };

  const clear = () => {
    reset();
    clearFilters();
    setKey((prev) => prev + 1);
  };

  const [venueOption, setVenueOption] = useState([]);
  const [genreOption, setGenreOption] = useState([]);
  const [artistTypeOption, setArtistTypeOption] = useState([]);
  const [key, setKey] = useState(0);

  const selectedRadius = watch("radius");
  const selectedRating = watch("rating");
  const selectedMinPrice = watch("minPrice");
  const selectedMaxPrice = watch("maxPrice");

  const transformedData = (arr) => {
    const finalArr = arr?.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });

    return finalArr;
  };

  useEffect(() => {
    async function getData() {
      try {
        const venueOptionres = await fetch(ApiEndpoint.GET_VENUE_SEARCH_OPTION);
        const venueOption = await venueOptionres.json();
        setVenueOption(transformedData(venueOption.data));
        const genreOptionres = await fetch(ApiEndpoint.GET_GENRE_SEARCH_OPTION);
        const genreOption = await genreOptionres.json();
        setGenreOption(transformedData(genreOption.data));
        const artistTypeOptionres = await fetch(
          ApiEndpoint.GET_ARTIST_TYPE_SEARCH_OPTION
        );
        const artistTypeOption = await artistTypeOptionres.json();
        setArtistTypeOption(transformedData(artistTypeOption.data));
      } catch (error) {
        console.log(error.message);
      }
    }
    getData();
  }, []);

  return (
    <section
      className={`filter-section ${isActive ? "active" : ""}`}
      id="filter-section"
    >
      <div className="filterSidebar" id="filterSidebar" key={key}>
        <form className="h-100" onSubmit={handleSubmit(submitHandler)}>
          <div className="inner-filter">
            <h4 className="d-flex justify-content-between align-items-center">
              Filter{" "}
              <span className="close_btn_filter" onClick={filterButton}>
                &times;
              </span>
            </h4>
            <div className="inner-form-filter">
              {isComingEventsPage === true ||
              (isVenuePage === false && isArtistPage === true) ? (
                <div>
                  <label>Genre</label>
                  <Controller
                    control={control}
                    name="genre"
                    //rules={{ required: true }}
                    render={({
                      field: { onChange, value = [], name, ref },
                    }) => (
                      <Select
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={genreOption ? genreOption : []}
                        isMulti={true}
                        value={genreOption.filter((genre) =>
                          value.includes(genre.value)
                        )}
                        onChange={(val) =>
                          onChange(val.map((item) => item.value))
                        }
                      />
                    )}
                  />

                  <hr className="mb-0 divider" />
                </div>
              ) : (
                ""
              )}
              {isComingEventsPage === true &&
              isVenuePage === false &&
              isArtistPage === false ? (
                <div>
                  <label>Venue</label>
                  <Controller
                    control={control}
                    name="venue"
                    //rules={{ required: true }}
                    render={({
                      field: { onChange, value = [], name, ref },
                    }) => (
                      <Select
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={venueOption ? venueOption : []}
                        isMulti={true}
                        value={venueOption.filter((venue) =>
                          value.includes(venue.value)
                        )}
                        onChange={(val) =>
                          onChange(val.map((item) => item.value))
                        }
                      />
                    )}
                  />

                  <hr className="mb-0 divider" />
                </div>
              ) : (
                ""
              )}
              {/* venueType and beverageType */}
              {isComingEventsPage === true ||
              (isVenuePage === true && isArtistPage === false) ? (
                <div>
                  <label>Venue Type</label>
                  <Controller
                    control={control}
                    name="venueType"
                    //rules={{ required: true }}
                    render={({
                      field: { onChange, value = [], name, ref },
                    }) => (
                      <Select
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={options1}
                        isMulti={true}
                        value={options1.filter((venue_type) =>
                          value.includes(venue_type.value)
                        )}
                        onChange={(val) =>
                          onChange(val.map((item) => item.value))
                        }
                      />
                    )}
                  />
                  <hr className="mb-0 divider" />
                </div>
              ) : (
                ""
              )}
              {/* artistType*/}
              {isComingEventsPage === false ||
              (isVenuePage === false && isArtistPage === true) ? (
                <div>
                  <label>Artist Type</label>
                  <Controller
                    control={control}
                    name="artistType"
                    //rules={{ required: true }}
                    render={({
                      field: { onChange, value = [], name, ref },
                    }) => (
                      <Select
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={artistTypeOption}
                        isMulti={true}
                        value={artistTypeOption.filter((artist_type) =>
                          value.includes(artist_type.value)
                        )}
                        onChange={(val) =>
                          onChange(val.map((item) => item.value))
                        }
                      />
                    )}
                  />
                  <hr className="mb-0 divider" />
                </div>
              ) : (
                ""
              )}

              {isComingEventsPage === true &&
              isVenuePage === false &&
              isArtistPage === false ? (
                <div>
                  <label>Beverage Type</label>
                  <Controller
                    control={control}
                    name="beverageType"
                    //rules={{ required: true }}
                    render={({
                      field: { onChange, value = [], name, ref },
                    }) => (
                      <Select
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={options2}
                        isMulti={true}
                        value={options2.filter((genre) =>
                          value.includes(genre.value)
                        )}
                        onChange={(val) =>
                          onChange(val.map((item) => item.value))
                        }
                      />
                    )}
                  />
                  <hr className="mb-0 divider" />
                </div>
              ) : (
                ""
              )}
              {isComingEventsPage === true &&
              isVenuePage === false &&
              isArtistPage === false ? (
                <div>
                  <label>Date</label>
                  <div className="row">
                    <div className="col-6">
                      <div>
                        <label>From</label>
                        <input
                          {...register("from")}
                          type="date"
                          className="form-control px-1"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <label>To</label>
                        <input
                          {...register("to")}
                          type="date"
                          className="form-control px-1"
                        />
                      </div>
                    </div>
                  </div>
                  <hr className="mb-0 divider" />
                </div>
              ) : (
                ""
              )}
              {isComingEventsPage === true ||
              (isVenuePage === true && isArtistPage === false) ? (
                <div className="d-flex">
                  <label htmlFor="customRange2" className="form-label">
                    Radius (Miles)
                  </label>
                  <div className="px-1">
                    <label htmlFor="customRange2" className="form-label">
                      5
                    </label>
                    <input
                      type="radio"
                      value={5}
                      {...register("radius")}
                      id="customRange2"
                      checked={selectedRadiusOption === 5}
                      onChange={handleSelectedRadiusChange}
                    />
                  </div>
                  <div className="px-1">
                    <label htmlFor="customRange2" className="form-label">
                      10
                    </label>
                    <input
                      type="radio"
                      value={10}
                      {...register("radius")}
                      id="customRange2"
                      checked={selectedRadiusOption === 10}
                      onChange={handleSelectedRadiusChange}
                    />
                  </div>
                  <div className="px-1">
                    <label htmlFor="customRange2" className="form-label">
                      15
                    </label>
                    <input
                      type="radio"
                      value={15}
                      {...register("radius")}
                      id="customRange2"
                      checked={selectedRadiusOption === 15}
                      onChange={handleSelectedRadiusChange}
                    />
                  </div>
                  <div className="px-1">
                    <label htmlFor="customRange2" className="form-label">
                      20
                    </label>
                    <input
                      type="radio"
                      value={20}
                      {...register("radius")}
                      id="customRange2"
                      checked={selectedRadiusOption === 20}
                      onChange={handleSelectedRadiusChange}
                    />
                  </div>
                  <div className="px-1">
                    <label htmlFor="customRange2" className="form-label">
                      50
                    </label>
                    <input
                      type="radio"
                      value={50}
                      {...register("radius")}
                      id="customRange2"
                      checked={selectedRadiusOption === 50}
                      onChange={handleSelectedRadiusChange}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {isComingEventsPage === true ||
              isVenuePage === true ||
              isArtistPage === true ? (
                <div>
                  <label htmlFor="customRating2" className="form-label">
                    Rating
                  </label>
                  <div className="d-flex">
                    <input
                      type="range"
                      className="form-range range blue"
                      min="3"
                      max="5"
                      step="0.5"
                      {...register("rating")}
                      id="customRating2"
                    />
                  </div>
                  <div className="ratings-labels">
                    <span
                      style={{
                        marginTop: "11px",
                        marginRight: "1px",
                        fontSize: "15px",
                      }}
                    >
                      3
                    </span>
                    <span
                      style={{
                        marginTop: "11px",
                        marginRight: "1px",
                        fontSize: "15px",
                      }}
                    >
                      3.5
                    </span>
                    <span
                      style={{
                        marginTop: "11px",
                        marginRight: "1px",
                        fontSize: "15px",
                      }}
                    >
                      4
                    </span>
                    <span
                      style={{
                        marginTop: "11px",
                        marginRight: "1px",
                        fontSize: "15px",
                      }}
                    >
                      4.5
                    </span>
                    <span
                      style={{
                        marginTop: "11px",
                        marginRight: "1px",
                        fontSize: "15px",
                      }}
                    >
                      5
                    </span>
                  </div>
                  <span className=" offset-5"></span>
                </div>
              ) : (
                ""
              )}
              {isComingEventsPage === true ||
              (isVenuePage === true && isArtistPage === false) ? (
                <div>
                  <label>Price</label>
                  <div className="d-flex">
                    <div className="px-1">
                      <label htmlFor="minPrice" className="form-label">
                        Min Price
                      </label>
                      <div className="d-flex">
                        <span
                          style={{
                            marginTop: "11px",
                            marginRight: "1px",
                            fontSize: "15px",
                          }}
                        ></span>
                        <input
                          type="range"
                          className="form-range range blue"
                          min="0"
                          max="10000"
                          {...register("minPrice")}
                          id="minPrice"
                        />
                        <span
                          style={{
                            marginTop: "11px",
                            marginRight: "1px",
                            fontSize: "15px",
                          }}
                        ></span>
                      </div>
                      <span className=" offset-5">{selectedMinPrice}</span>
                    </div>
                    <div className="px-1">
                      <label htmlFor="maxPrice" className="form-label">
                        Max Price
                      </label>
                      <div className="d-flex">
                        <span
                          style={{
                            marginTop: "11px",
                            marginRight: "1px",
                            fontSize: "15px",
                          }}
                        ></span>
                        <input
                          type="range"
                          className="form-range range blue"
                          min="0"
                          max="10000"
                          {...register("maxPrice")}
                          id="maxPrice"
                        />
                        <span
                          style={{
                            marginTop: "11px",
                            marginRight: "1px",
                            fontSize: "15px",
                          }}
                        ></span>
                      </div>
                      <span className=" offset-5">{selectedMaxPrice}</span>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="d-flex">
                {isComingEventsPage === true ||
                (isVenuePage === true && isArtistPage === false) ? (
                  <div className="px-1">
                    <label htmlFor="onlyVenuesFollow" className="form-label">
                      Only Venues I Follow
                    </label>
                    <span
                      style={{
                        marginTop: "11px",
                        marginRight: "1px",
                        fontSize: "15px",
                      }}
                    ></span>
                    <input
                      type="checkbox"
                      {...register("onlyVenuesFollow")}
                      id="onlyVenuesFollowed"
                      checked={onlyVenuesFollowChecked}
                      onChange={handleCheckboxOnlyVenuesFollow}
                    />
                    <span
                      style={{
                        marginTop: "11px",
                        marginRight: "1px",
                        fontSize: "15px",
                      }}
                    ></span>
                  </div>
                ) : (
                  ""
                )}
                {isComingEventsPage === true ||
                (isVenuePage === false && isArtistPage === true) ? (
                  <div className="px-1">
                    <label htmlFor="onlyArtistsFollow" className="form-label">
                      Only Artists I Follow
                    </label>
                    <span
                      style={{
                        marginTop: "11px",
                        marginRight: "1px",
                        fontSize: "15px",
                      }}
                    ></span>
                    <input
                      type="checkbox"
                      {...register("onlyArtistsFollow")}
                      id="onlyArtistsFollow"
                      checked={onlyArtistsFollowChecked}
                      onChange={handleCheckboxOnlyArtistsFollow}
                    />
                    <span
                      style={{
                        marginTop: "11px",
                        marginRight: "1px",
                        fontSize: "15px",
                      }}
                    ></span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-danger">
                Apply Filters
              </button>
              <button
                onClick={clear}
                type="button"
                className="btn btn-danger mx-2"
              >
                Clear Filters
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Filter;
