import Actions from "./Actions";

export const GetAllGener = () => {
  return {
    type: Actions.GET_ALL_GENER,
  };
};

export const SaveAllGener = (payload) => {
  return {
    type: Actions.SAVE_ALL_GENER,
    payload,
  };
};

export const GetAllBeverageType = (payload, callback) => {
  return {
    type: Actions.GET_BEVERAGE_TYPE,
    payload,
    callback,
  };
};
