import { combineReducers } from "redux";
import Loader from "./loaderReducer";
import AuthReducer from "./authReducer";
import ArtistsReducer from "./ArtistsReducer";
import VenuesReducer from "./VenuesReducer";
import UpcomingEventReducer from "./UpcomingReducer";
import GigsReducer from "./Gigs";
import BidsReducer from "./BidsReducer";
import MasterDataReducer from "./MasterData";


const RootReducer = combineReducers({
  Loading: Loader,
  Auth: AuthReducer,
  Artists:ArtistsReducer,
  Venues:VenuesReducer,
  Events :UpcomingEventReducer,
  Gigs : GigsReducer,
  Bids : BidsReducer,
  Master: MasterDataReducer,
});
export default RootReducer;
