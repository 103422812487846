import React from "react";

const ProfileDetails = ({ label ,value}) => {
  return (
    <tr>
      <th scope="row">{label}</th>
      <td>{value}</td>
    </tr>
  );
};

export default ProfileDetails;
