import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GettAllEventsForIndivualVeneuePublicAction } from "../../../../Redux/Action/Venues";
import moment from "moment";
import DummyImage from "../../../../Assets/noimg.jpg";
import { Link } from "react-router-dom";
const Events = ({ id }) => {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.Loading.loading);
  const events = useSelector((state) => state.Venues.EventsBasedOnVenueId);
  const authToken = useSelector((state) => state.Auth.authToken);

  useEffect(() => {
    dispatch(GettAllEventsForIndivualVeneuePublicAction(id, authToken));
  }, [id]);

  return (
    <section className="venue-events">
      <div className="container">
        <div className="row py-3">
          <div className="col-md-12">
            {events.length && !loader ? (
              <div>
                <div className="row">
                  {events?.map((item) => {
                    return (
                      <div key={item.id} className=" mb-3">
                        <div className="bid-cards">
                          <div className="row g-0">
                            <div className="col-md-6">
                              <img
                                src={item?.image ? item.image : DummyImage}
                                className="venue-event-img p-3"
                                alt="..."
                              />
                            </div>
                            <div className="col-md-5">
                              <div className="card-body">
                                <div className="table-responsive ">
                                  <table className=" table table-bordered mt-2">
                                    <tbody>
                                      <tr>
                                        <th className="text-danger">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-music-note-beamed mx-2"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M6 13c0 1.105-1.12 2-2.5 2S1 14.105 1 13c0-1.104 1.12-2 2.5-2s2.5.896 2.5 2zm9-2c0 1.105-1.12 2-2.5 2s-2.5-.895-2.5-2 1.12-2 2.5-2 2.5.895 2.5 2z" />
                                            <path
                                              fillRule="evenodd"
                                              d="M14 11V2h1v9h-1zM6 3v10H5V3h1z"
                                            />
                                            <path d="M5 2.905a1 1 0 0 1 .9-.995l8-.8a1 1 0 0 1 1.1.995V3L5 4V2.905z" />
                                          </svg>
                                          Artist{" "}
                                        </th>
                                        <td className="text-capitalize">
                                          {item.artist.name ? (
                                            item.artist.name
                                          ) : (
                                            <span
                                              className="badge bg-warning"
                                              style={{ marginRight: "2px" }}
                                            >
                                              Event not booked yet
                                            </span>
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="text-danger">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-music-note mx-2"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M9 13c0 1.105-1.12 2-2.5 2S4 14.105 4 13s1.12-2 2.5-2 2.5.895 2.5 2z" />
                                            <path
                                              fillRule="evenodd"
                                              d="M9 3v10H8V3h1z"
                                            />
                                            <path d="M8 2.82a1 1 0 0 1 .804-.98l3-.6A1 1 0 0 1 13 2.22V4L8 5V2.82z" />
                                          </svg>
                                          Genre
                                        </th>
                                        <td className="text-capitalize">
                                          {item.genre.map((gn) => (
                                            <span
                                              key={gn.id}
                                              className="badge bg-success"
                                              style={{ marginRight: "2px" }}
                                            >
                                              {gn.name}
                                            </span>
                                          ))}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="text-danger">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-clock-fill mx-2"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                          </svg>{" "}
                                          Start
                                        </th>
                                        <td>
                                          {moment(item?.start_time).format(
                                            "MMMM Do YYYY, h a"
                                          )}
                                        </td>
                                      </tr>

                                      <tr>
                                        <th className=" text-danger">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-clock-fill mx-2"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                          </svg>{" "}
                                          End
                                        </th>
                                        <td>
                                          {moment(item?.end_time).format(
                                            "MMMM Do YYYY, h a"
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          className=" justify-content-center"
                                          colSpan={2}
                                        >
                                          <Link
                                            to={`/event-details/${item.id}`}
                                            className="btn btn-danger mb-2  d-grid gap-2 center"
                                          >
                                            <span className=""> View more</span>
                                          </Link>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col-md-12">
                    <p className="no-data-found">No upcoming event found</p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Events;
