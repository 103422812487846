import React from "react";
import FilledStar from "./FilledStar";
import EmptyStar from "./EmptyStar";
import HalfFillStar from "./HalfFillStar";

const RatingStars = ({ blankStar, fullStars, hasHalfStar }) => {
  const arrblankstar = Array(blankStar ? blankStar : 0).fill(null);
  const arrfilledstar = Array(fullStars ? fullStars : 0).fill(null);

  return (
    <>
      {arrfilledstar?.map((ele, index) => {
        return <FilledStar />;
      })}

      {hasHalfStar && <HalfFillStar />}

      {arrblankstar?.map((ele, index) => {
        return <EmptyStar />;
      })}
    </>
  );
};

export default RatingStars;
