import React from 'react'
import {NavLink } from "react-router-dom";
const CommonLinks = () => {
  return (
    <>
    
    <li className="nav-item">
      <NavLink
        to="/upcoming_events"
        className={({ isActive }) => {
          return isActive ? "active" : "inactive";
        }}
        aria-current="page"
      >
        Upcoming Events
      </NavLink>
    </li>

    <li className="nav-item">
      <NavLink
        to="/venues"
        className={({ isActive }) => {
          return isActive ? "active" : "inactive";
        }}
        aria-current="page"
      >
        Venues
      </NavLink>
    </li>

    <li className="nav-item">
      <NavLink
        to="/artist"
        className={({ isActive }) => {
          return isActive ? "active" : "inactive";
        }}
        aria-current="page"
      >
        Artists
      </NavLink>
    </li>
  
   
  </>
  )
}

export default CommonLinks
