import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { DisplayValidationErrors, errorMessages } from "../../../Utilities";
import { AddArtistAction } from "../../../Redux/Action/Artists";
import toast, { Toaster } from "react-hot-toast";
import LoaderImg from "../../../Assets/loader.gif"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddArtists = ({ showModal, closeModal ,cancelAddModal }) => {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.Loading.loading);
  const authToken = useSelector((state) => state.Auth.authToken);
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();

  const submitHandler = (data) => {
    const val = Object.values(data.postImage);
    const formData = new FormData();
    formData.append("name", data["name"]);
    formData.append("email", data["email"]);
    formData.append("image", val[0]);
    formData.append("address", data["address"]);
    formData.append("city", data["city"]);
    formData.append("state", data["state"]);
    formData.append("zip_code", data["zip_code"]);
    formData.append("phone", data["phone"]);

    dispatch(AddArtistAction(formData, authToken, (type, message) => {
        toast[type](message ,{
          duration: 5000,
        });
        if (type == "success") {
          reset();
          closeModal()
        }}));
  };

  return (
    <>
      <Modal
        open={showModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-form">
          <form onSubmit={handleSubmit(submitHandler)}>
            <>
              <p className=" text-danger text-uppercase h6">Register New Artists / Band</p>
              <button
                type="button"
                onClick={cancelAddModal}
                className="btn close-btn"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </button>
              <div className="row">
                <div className="col-md-6">
                  <label>Name</label>
                  <input
                    placeholder="Artist / Band name"
                    className="login-field"
                    type="text"
                    {...register("name", { required: true })}
                  />
                  {errors["name"] ? (
                    <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
                  ) : null}
                </div>

                <div className="col-md-6">
                  <label>Email</label>
                  <input
                    placeholder="Email"
                    className="login-field"
                    type="email"
                    {...register("email", { required: true })}
                  />
                  {errors["email"] ? (
                    <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
                  ) : null}
                </div>

                <div className="col-md-6">
                  <label>Phone number</label>
                  <input
                    placeholder="Phone number"
                    className="login-field"
                    type="number"
                    {...register("phone", {
                      required: true,
                      minLength: 10,
                      maxLength: 10,
                    })}
                  />
                  {errors?.phone?.type == "required" ? (
                    <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
                  ) : null}
                  {errors?.phone?.type == "maxLength" ||
                  errors?.phone?.type == "minLength" ? (
                    <DisplayValidationErrors message={errorMessages.VALID_NUMBER} />
                  ) : null}
                </div>

                <div className="col-md-6">
                  <label>Address</label>
                  <input
                    placeholder="Address"
                    className="login-field"
                    type="text"
                    {...register("address", { required: true })}
                  />
                  {errors["address"] ? (
                    <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
                  ) : null}
                </div>  

                <div className="col-md-6">
                  <label>City</label>
                  <input
                    placeholder="City"
                    className="login-field"
                    type="text"
                    {...register("city", { required: true })}
                  />
                  {errors["city"] ? (
                    <DisplayValidationErrors message={errorMessages.FILL_FIELD}/>
                  ) : null}
                </div>

                <div className="col-md-6"> 
                  <label>State</label>
                  <input
                    placeholder="State"
                    className="login-field"
                    type="text"
                    {...register("state", { required: true })}
                  />
                  {errors["state"] ? (
                    <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
                  ) : null}
                </div>

                <div className="col-md-6">
                  <label>Zip code</label>
                  <input
                    placeholder="Zip code"
                    className="login-field"
                    type="number"
                    {...register("zip_code", { required: true })}
                  />
                  {errors["zip_code"] ? (
                    <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
                  ) : null}
                </div>

                <div className="col-md-6">
                  <label>Pitcture</label>
                  <input
                    placeholder="Picture"
                    className="login-field"
                    type="file"
                    {...register("postImage", { required: true })}
                  />
                  {errors["postImage"] ? (
                    <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
                  ) : null}
                </div>
              </div>
            </>
            <div className="submission-form-popup">
              <button disabled={loader} type="submit" className="login-btn ">
              {loader ? <img className="loader-image" src={LoaderImg} /> : "Add"}
              </button>
                      
              <button onClick={cancelAddModal} type="button" className="login-btn">
                Cancel
              </button>
            </div>
          </form>
        </Box>
      </Modal>
      <Toaster /> 
    </>
  );
};
export default AddArtists;
