import React from 'react'
import {NavLink } from "react-router-dom";
const PublicNavlinks = () => {
  return (
    <>
    <li className="nav-item">
      <NavLink
        to="/login"
        className={({ isActive }) => {
          return isActive ? "active" : "inactive";
        }}
      >
        Sign In
      </NavLink>
    </li>
   
  </>
  )
}

export default PublicNavlinks
