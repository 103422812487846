import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Toaster } from "react-hot-toast";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DeleteVenue = ({ showModal, closeModal, cancel }) => {
  return (
    <>
      <Modal
        open={showModal}
        onClose={cancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="delete-modal-page"
      >
        <Box sx={style} className="modal-form">
          <>
            <div className="row">
              <button type="button" onClick={cancel} className="btn close-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </button>
              <div className="col-md-12 mb-3 text-center h4">
                Are you sure you want to delete this venue?
              </div>
            </div>
          </>

          <div className="submission-form-popup">
            <button onClick={closeModal} type="button" className="login-btn ">
              Confirm
            </button>

            <button onClick={cancel} type="button" className="login-btn">
              Cancel
            </button>
          </div>
        </Box>
      </Modal>
      <Toaster />
    </>
  );
};
export default DeleteVenue;
