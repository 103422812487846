import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const PrivateNavlinks = ({ LogoutHandler }) => {
  const Profile = useSelector((state) => state.Auth.profile);

  return (
    <>
      <li className="nav-item dropdown btn-group mx-2">
        <a
          className="nav-link dropdown-toggle text-capitalize dropdwon-nav-color"
          href="/"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {`${Profile?.first_name} ${Profile?.last_name}`}
        </a>
        <ul className="dropdown-menu custom-dropdown dropdown-layout-style ">
          {Profile?.artist_or_band === true && (
            <>
              <ul>
                <li>
                  Artist Admin
                  <li className="dropdown-item">
                    <Link to="/bod/artists_list">Manage Artists</Link>
                  </li>
                  <li className="dropdown-item">
                    <Link to="/bod/gigs">Manage GIGs</Link>
                  </li>
                </li>
              </ul>
            </>
          )}
          {Profile?.venue_owner === true && (
            <>
              <ul>
                <li>
                  Venue Admin
                  <li className="dropdown-item">
                    <Link to="/bod/admin_users">Manage Admin Users</Link>
                  </li>
                  <li className="dropdown-item">
                    <Link to="/bod/venues_list">Manage Venues</Link>
                  </li>
                  <li className="dropdown-item">
                    <Link to="/bod/events">Manage Events</Link>
                  </li>
                  <li className="dropdown-item">
                    <Link to="/bod/bids">Manage BIDs</Link>
                  </li>
                </li>
              </ul>
            </>
          )}

          <li className="dropdown-item">
            <Link to="/bod/profile">Edit Profile</Link>
          </li>

          <li className="dropdown-item">
            <Link to="/bod/change_password">Change Password</Link>
          </li>
          <hr />
          <li className="dropdown-item ">
            <p onClick={LogoutHandler} type="button" className="text-white">
              <span className="mx-2"> LOGOUT</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-box-arrow-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
                />
                <path
                  fillRule="evenodd"
                  d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                />
              </svg>
            </p>
          </li>
        </ul>
      </li>
    </>
  );
};

export default PrivateNavlinks;
