import Actions from "../Action/Actions";

const initialState = {
  venues: [],
  indivualUserVenues: [],
  venueBasedOnId: {},
  EventsBasedOnVenueId: [],
  CalenderEventsForVenue: [],
  overallReview: {},
  venuePhotos: [],
  venueVideos: [],
  venueAudios: [],
};

const VenuesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_ALL_VENUES":
      return {
        ...state,
        venues: [...action.payload["result"]],
        total_venues: action.payload["count"],
      };
    case "SAVE_ALL_VENUES_LOGGED_USER":
      return {
        ...state,
        indivualUserVenues: [...action.payload],
      };

    case "SAVE_VENUE_BASED_ON_ID":
      return {
        ...state,
        venueBasedOnId: { ...action.payload },
      };
    case "SAVE_ALL_EVENTS_FOR_INDIVUAL_VENUE_BASED_ON_ID":
      return {
        ...state,
        EventsBasedOnVenueId: [...action.payload],
      };

    case "SAVE_ALL_CALENDAR_EVENT_FOR_VENUE_PUBLIC":
      return {
        ...state,
        CalenderEventsForVenue: [...action.payload],
      };

    case "SAVE_OVERALL_REVIEW_BASED_ON_VENUE_ID":
      return {
        ...state,
        overallReview: { ...action.payload },
      };

    // adding reducers for venue media files
    case "SAVE_VENUE_PHOTOS":
      return {
        ...state,
        venuePhotos: [...action.payload],
      };

    case "SAVE_VENUE_VIDEOS":
      return {
        ...state,
        venueVideos: [...action.payload],
      };

    case "SAVE_VENUE_AUDIOS":
      return {
        ...state,
        venueAudios: [...action.payload],
      };
    default:
      return state;
  }
};
export default VenuesReducer;
