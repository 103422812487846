import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AcceptGigAction,
  AcceptGigActionByArtistManager,
  AcceptGigActionByVeneuManger,
  GetAllGigsForUserAction,
  RejectGigActionByArtistManager,
  RejectGigActionByVenueManger,
} from "../../../Redux/Action/Bidding";
import IndivualGig from "./IndivualGig";
import ConfirmationPopup from "../../../Components/Body/Venues/Bids/Popup";
import { toast } from "react-hot-toast";
import Negotiation from "../../../Components/Body/Venues/Bids/Negotiation";
import AgreementPopup from "../../../Components/Body/Venues/Bids/AgreementPopup";

const MyGigs = () => {
  const dispatch = useDispatch();
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [selectedBid, setSelectedBid] = useState(null);
  const [showNegotiationPopup, setShowNegotiationPopup] = useState(false);
  const [showAgreementPopup, setShowAgreementPopup] = useState(false);
  const authToken = useSelector((state) => state.Auth.authToken);
  const allGigs = useSelector((state) => state.Gigs.allGigs);
  const profile = useSelector((state) => state.Auth.profile);
  const loader = useSelector((state) => state.Loading.loading);

  //Confirmation Popup
  const openConfirmationPopup = (bidId, artist, venue, type) => {
    setSelectedBid({ bidId, type, artist, venue });
    setShowConfirmationPopup(true);
  };
  const closeConfirmationPopup = () => {
    if (selectedBid.type === "Accept" && profile.artist_or_band === true) {
      dispatch(
        AcceptGigActionByArtistManager(
          selectedBid,
          authToken,
          (type, message) => {
            toast[type](`${message}`, {
              duration: 5000,
            });
            dispatch(GetAllGigsForUserAction(authToken));
          }
        )
      );
    }

    if (selectedBid.type === "Accept" && profile.venue_owner === true) {
      dispatch(
        AcceptGigActionByVeneuManger(
          selectedBid,
          authToken,
          (type, message) => {
            toast[type](`${message}`, {
              duration: 5000,
            });
            dispatch(GetAllGigsForUserAction(authToken));
          }
        )
      );
    }

    if (selectedBid.type === "Reject" && profile.artist_or_band === true) {
      dispatch(
        RejectGigActionByArtistManager(
          selectedBid,
          authToken,
          (type, message) => {
            toast[type](`${message}`, {
              duration: 5000,
            });
            dispatch(GetAllGigsForUserAction(authToken));
          }
        )
      );
    }
    if (selectedBid.type === "Reject" && profile.venue_owner === true) {
      dispatch(
        RejectGigActionByVenueManger(
          selectedBid,
          authToken,
          (type, message) => {
            toast[type](`${message}`, {
              duration: 5000,
            });
            dispatch(GetAllGigsForUserAction(authToken));
          }
        )
      );
    }
    setShowConfirmationPopup(false);
    setSelectedBid({});
  };
  const cancelConfiramtionPopup = () => {
    setShowConfirmationPopup(false);
    setSelectedBid({});
  };

  //Negotiation logic
  const openNegotiationPopup = (bidId, artist, venue, type) => {
    setSelectedBid({ bidId, type, artist, venue });
    setShowNegotiationPopup(true);
  };

  const closeNegotiatoinPopup = () => {
    setShowNegotiationPopup(false);
    setSelectedBid({});
    dispatch(GetAllGigsForUserAction(authToken));
  };
  const cancelNegotiationPopup = () => {
    setShowNegotiationPopup(false);
    setSelectedBid({});
  };

  //Agreement logic
  const openAgreementPopup = (bidId, artist, venue, agreement, type) => {
    setSelectedBid({ bidId, type, artist, agreement, venue });
    setShowAgreementPopup(true);
  };
  const closeAgreementPopup = () => {
    setShowAgreementPopup(false);
    setSelectedBid({});
    dispatch(GetAllGigsForUserAction(authToken));
  };
  const cancelAgreementPopup = () => {
    setShowAgreementPopup(false);
    setSelectedBid({});
  };

  useEffect(() => {
    dispatch(GetAllGigsForUserAction(authToken));
  }, [authToken, dispatch]);

  return (
    <>
      <section className="header-tabs mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="tabs-section position-relative">
                <nav className="headr-navs-bg">
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      className="nav-link active"
                      id="My-bids-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-bids"
                      type="button"
                      role="tab"
                      aria-controls="nav-bids"
                      aria-selected="true"
                    >
                      My Bids
                    </button>
                    <button
                      className="nav-link"
                      id="Request-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-request"
                      type="button"
                      role="tab"
                      aria-controls="nav-request"
                      aria-selected="false"
                    >
                      Incoming requests to BID on Event
                    </button>
                    <button
                      className="nav-link"
                      id="In-negotiation-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-negotiation"
                      type="button"
                      role="tab"
                      aria-controls="nav-negotiation"
                      aria-selected="false"
                    >
                      In Negotiation
                    </button>
                    <button
                      className="nav-link"
                      id="accepted-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-accepted"
                      type="button"
                      role="tab"
                      aria-controls="nav-accepted"
                      aria-selected="false"
                    >
                      Accepted
                    </button>
                    <button
                      className="nav-link"
                      id="closed-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-closed"
                      type="button"
                      role="tab"
                      aria-controls="nav-closed"
                      aria-selected="false"
                    >
                      Closed
                    </button>
                    <button
                      className="nav-link"
                      id="Agreements-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-agreements"
                      type="button"
                      role="tab"
                      aria-controls="nav-agreements"
                      aria-selected="false"
                    >
                      Agreements
                    </button>
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-bids"
                    role="tabpanel"
                    aria-labelledby="My-bids-tabs"
                  >
                    <section className="artist-profile-card my-5">
                      <div className="container">
                        <div className="row">
                          {allGigs?.map((item) => {
                            if (
                              item.status === "Pending" &&
                              item.bid_by === "artist"
                            ) {
                              return (
                                <IndivualGig
                                  openNegotiationPopup={openNegotiationPopup}
                                  openConfirmationPopup={openConfirmationPopup}
                                  openAgreementPopup={openAgreementPopup}
                                  key={item.id}
                                  gig={item}
                                />
                              );
                            }
                            return null;
                          })}

                          {!loader &&
                          !allGigs.some((item) => item.status === "Pending") ? (
                            <>
                              <div className="row  ">
                                <div className="col-md-12  text-center">
                                  <p className="text-danger">
                                    No request is send yet.
                                  </p>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </section>
                  </div>
                  <div
                    className="tab-pane"
                    id="nav-request"
                    role="tabpanel"
                    aria-labelledby="Request-tabs"
                  >
                    <section className="artist-profile-card my-5">
                      <div className="container">
                        <div className="row">
                          {allGigs?.map((item) => {
                            if (
                              item.status === "Pending" &&
                              item.bid_by === "venue"
                            ) {
                              return (
                                <IndivualGig
                                  openNegotiationPopup={openNegotiationPopup}
                                  openConfirmationPopup={openConfirmationPopup}
                                  openAgreementPopup={openAgreementPopup}
                                  key={item.id}
                                  gig={item}
                                />
                              );
                            }
                            return null;
                          })}

                          {!loader &&
                          !allGigs.some((item) => item.status === "Pending") ? (
                            <>
                              <div className="row  ">
                                <div className="col-md-12  text-center">
                                  <p className="text-danger">
                                    No request is received yet.
                                  </p>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </section>
                  </div>

                  <div
                    className="tab-pane"
                    id="nav-negotiation"
                    role="tabpanel"
                    aria-labelledby="In-negotiation-tabs"
                  >
                    <section className="artist-profile-card my-5">
                      <div className="container">
                        <div className="row">
                          {allGigs?.map((item) => {
                            if (item.status === "Ongoing") {
                              return (
                                <IndivualGig
                                  openNegotiationPopup={openNegotiationPopup}
                                  openConfirmationPopup={openConfirmationPopup}
                                  openAgreementPopup={openAgreementPopup}
                                  key={item.id}
                                  gig={item}
                                />
                              );
                            }
                            return null;
                          })}

                          {!loader &&
                          !allGigs.some((item) => item.status === "Ongoing") ? (
                            <>
                              <div className="row  ">
                                <div className="col-md-12  text-center">
                                  <p className="text-danger">No data found.</p>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </section>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-accepted"
                    role="tabpanel"
                    aria-labelledby="accepted-tabs"
                  >
                    <section className="artist-profile-card my-5">
                      <div className="container">
                        <div className="row">
                          {allGigs?.map((item) => {
                            if (item.status === "Accepted") {
                              return (
                                <IndivualGig
                                  openNegotiationPopup={openNegotiationPopup}
                                  openConfirmationPopup={openConfirmationPopup}
                                  openAgreementPopup={openAgreementPopup}
                                  key={item.id}
                                  gig={item}
                                  isAgreement={false}
                                />
                              );
                            }
                            return null;
                          })}

                          {!loader &&
                          !allGigs.some(
                            (item) => item.status === "Accepted"
                          ) ? (
                            <>
                              <div className="row  ">
                                <div className="col-md-12  text-center">
                                  <p className="text-danger">No data found.</p>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </section>
                  </div>
                  <div
                    className="tab-pane"
                    id="nav-closed"
                    role="tabpanel"
                    aria-labelledby="closed-tabs"
                  >
                    <section className="artist-profile-card my-5">
                      <div className="container">
                        <div className="row">
                          {allGigs?.map((item) => {
                            if (item.status === "Reject") {
                              return (
                                <IndivualGig
                                  openNegotiationPopup={openNegotiationPopup}
                                  openConfirmationPopup={openConfirmationPopup}
                                  openAgreementPopup={openAgreementPopup}
                                  key={item.id}
                                  gig={item}
                                />
                              );
                            }
                            return null;
                          })}

                          {!loader &&
                          !allGigs.some((item) => item.status === "Reject") ? (
                            <>
                              <div className="row  ">
                                <div className="col-md-12  text-center">
                                  <p className="text-danger">No data found.</p>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </section>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-agreements"
                    role="tabpanel"
                    aria-labelledby="Agreements-tabs"
                  >
                    <section className="artist-profile-card my-5">
                      <div className="container">
                        <div className="row">
                          {allGigs?.map((item) => {
                            if (item.status === "Accepted") {
                              return (
                                <IndivualGig
                                  openNegotiationPopup={openNegotiationPopup}
                                  openConfirmationPopup={openConfirmationPopup}
                                  openAgreementPopup={openAgreementPopup}
                                  key={item.id}
                                  gig={item}
                                  isAgreement={true}
                                />
                              );
                            }
                            return null;
                          })}

                          {!loader &&
                          !allGigs.some(
                            (item) => item.status === "Accepted"
                          ) ? (
                            <>
                              <div className="row  ">
                                <div className="col-md-12  text-center">
                                  <p className="text-danger">No data found.</p>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ConfirmationPopup
        cancel={cancelConfiramtionPopup}
        showModal={showConfirmationPopup}
        closeModal={closeConfirmationPopup}
        selectedBid={selectedBid}
      />
      <Negotiation
        cancel={cancelNegotiationPopup}
        showModal={showNegotiationPopup}
        closeModal={closeNegotiatoinPopup}
        selectedBid={selectedBid}
      />
      <AgreementPopup
        cancel={cancelAgreementPopup}
        showModal={showAgreementPopup}
        closeModal={closeAgreementPopup}
        selectedBid={selectedBid}
        viewAdmin={"artist"}
      />
    </>
  );
};

export default MyGigs;
