import React, { useEffect, useState } from "react";
//import { Calendar, momentLocalizer } from "react-big-calendar";
import Calendar from "../../../../../Components/Calendar/Calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css"; // Import the CSS file
import { useDispatch, useSelector } from "react-redux";
import { GetAllCalendarEventForVenuePublicAction } from "../../../../../Redux/Action/Venues";
import "../../../../../index.css";
import EventProvider from "../../../../../Contexts/CalendarEventContext";

const Calender = ({
  id,
  openAddModal,
  openEventDetailsPopup,
  isVenuePublic,
  venueDetail,
  selectedDate,
}) => {
  const dispatch = useDispatch();
  const calendarEvents = useSelector(
    (state) => state.Venues.CalenderEventsForVenue
  );
  const authToken = useSelector((state) => state.Auth.authToken);

  // Function to handle date selection
  const [setSelectedDate] = useState(null);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    openAddModal(date);
    //  / setShowModal(true);
  };

  const handleEventClick = (event) => {
    // Your code to handle the click on an existing event in "day" view
    openEventDetailsPopup(event);
  };

  useEffect(() => {
    dispatch(GetAllCalendarEventForVenuePublicAction(id, authToken));
  }, []);

  return (
    <section className="review">
      <div className="container">
        <div className="row py-3">
          <div className="col-md-12">
            <h6>Calendar Section</h6>
            <EventProvider
              isVenuePublic={isVenuePublic}
              openEventDetailsPopup={openEventDetailsPopup}
              venueDetail={venueDetail}
              selectedDate={selectedDate}
            >
              <Calendar calendarEvents={calendarEvents} />
            </EventProvider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Calender;
