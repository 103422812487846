import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DummyImage from "../../../../Assets/noimg.jpg";
import { GetBidableEventsForArtist } from "../../../../Redux/Action/UpcomingEvents";
import moment from "moment";

const EventListingForBid = ({ openBidingPopup, ArtistID }) => {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.Loading.loading);
  const bidableEvents = useSelector((state) => state.Bids.bidableEvents);
  const authToken = useSelector((state) => state.Auth.authToken);

  const selectVenue = (id) => {
    openBidingPopup(id);
  };

  useEffect(() => {
    dispatch(GetBidableEventsForArtist(authToken, ArtistID));
  }, []);

  return (
    <>
      {!loader && bidableEvents.length == 0 ? (
        <>
          <div className="row  ">
            <div className="col-md-12  text-center">
              <h4>No data found</h4>
            </div>
          </div>
        </>
      ) : null}
      <section className="venue-events">
        <div className="container">
          <div className="row py-3">
            <div className="col-md-12">
              {bidableEvents.length && !loader ? (
                <div>
                  <div className="row">
                    {bidableEvents?.map((item) => {
                      return (
                        <div key={item.id} className=" mb-3">
                          <div className="bid-cards">
                            <div className="row g-0 align-items-center">
                              <div className="col-md-5">
                                <img
                                  src={item?.image ? item.image : DummyImage}
                                  className="venue-event-img p-3"
                                  alt="..."
                                />
                              </div>
                              <div className="col-md-7">
                                <div className="card-body">
                                  <div className="table-responsive mx-4 ">
                                    <table className=" table table-bordered mt-2">
                                      <tbody>
                                        <tr>
                                          <th className="text-danger table-text-font-size">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="currentColor"
                                              className="bi bi-buildings me-2"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022ZM6 8.694 1 10.36V15h5V8.694ZM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15Z" />
                                              <path d="M2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-2 2h1v1H2v-1Zm2 0h1v1H4v-1Zm4-4h1v1H8V9Zm2 0h1v1h-1V9Zm-2 2h1v1H8v-1Zm2 0h1v1h-1v-1Zm2-2h1v1h-1V9Zm0 2h1v1h-1v-1ZM8 7h1v1H8V7Zm2 0h1v1h-1V7Zm2 0h1v1h-1V7ZM8 5h1v1H8V5Zm2 0h1v1h-1V5Zm2 0h1v1h-1V5Zm0-2h1v1h-1V3Z" />
                                            </svg>
                                            Venue
                                          </th>
                                          <td className="text-capitalize table-text-font-size">
                                            {item.venue.name}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th className="text-danger table-text-font-size">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="currentColor"
                                              className="bi bi-geo-alt-fill me-2"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                            </svg>
                                            Address
                                          </th>
                                          <td className="text-capitalize table-text-font-size">
                                            {item?.venue.address
                                              ? item.venue.address
                                              : "NA"}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th className="text-danger table-text-font-size">
                                            <span className="font-weight-bold">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-alarm-fill me-2"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M6 .5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1H9v1.07a7.001 7.001 0 0 1 3.274 12.474l.601.602a.5.5 0 0 1-.707.708l-.746-.746A6.97 6.97 0 0 1 8 16a6.97 6.97 0 0 1-3.422-.892l-.746.746a.5.5 0 0 1-.707-.708l.602-.602A7.001 7.001 0 0 1 7 2.07V1h-.5A.5.5 0 0 1 6 .5zm2.5 5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5zM.86 5.387A2.5 2.5 0 1 1 4.387 1.86 8.035 8.035 0 0 0 .86 5.387zM11.613 1.86a2.5 2.5 0 1 1 3.527 3.527 8.035 8.035 0 0 0-3.527-3.527z" />
                                              </svg>
                                            </span>
                                            Start date
                                          </th>
                                          <td className="text-capitalize table-text-font-size">
                                            {moment(item?.start).format(
                                              "MMMM Do YYYY, h a"
                                            )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th className="text-danger table-text-font-size">
                                            <span className="font-weight-bold">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-alarm-fill me-2 "
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M6 .5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1H9v1.07a7.001 7.001 0 0 1 3.274 12.474l.601.602a.5.5 0 0 1-.707.708l-.746-.746A6.97 6.97 0 0 1 8 16a6.97 6.97 0 0 1-3.422-.892l-.746.746a.5.5 0 0 1-.707-.708l.602-.602A7.001 7.001 0 0 1 7 2.07V1h-.5A.5.5 0 0 1 6 .5zm2.5 5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5zM.86 5.387A2.5 2.5 0 1 1 4.387 1.86 8.035 8.035 0 0 0 .86 5.387zM11.613 1.86a2.5 2.5 0 1 1 3.527 3.527 8.035 8.035 0 0 0-3.527-3.527z" />
                                              </svg>
                                            </span>
                                            End date
                                          </th>
                                          <td className="text-capitalize table-text-font-size">
                                            {moment(item?.end).format(
                                              "MMMM Do YYYY, h a"
                                            )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th className="text-danger table-text-font-size">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="currentColor"
                                              className="bi bi-music-note me-2"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M9 13c0 1.105-1.12 2-2.5 2S4 14.105 4 13s1.12-2 2.5-2 2.5.895 2.5 2z" />
                                              <path
                                                fillRule="evenodd"
                                                d="M9 3v10H8V3h1z"
                                              />
                                              <path d="M8 2.82a1 1 0 0 1 .804-.98l3-.6A1 1 0 0 1 13 2.22V4L8 5V2.82z" />
                                            </svg>
                                            Genre
                                          </th>
                                          <td className="text-capitalize table-text-font-size">
                                            {item.genre.name
                                              ? item.genre.name
                                              : "NA"}
                                          </td>
                                        </tr>

                                        <tr>
                                          <th className=" text-danger table-text-font-size">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="currentColor"
                                              className="bi bi-currency-exchange me-2"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5zm16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0zm-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787H8.25zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674l.077.018z" />
                                            </svg>
                                            Rate
                                          </th>
                                          <td className="table-text-font-size">
                                            {item.rate_from
                                              ? item.rate_from
                                              : "NA"}
                                            -
                                            {item.rate_to ? item.rate_to : "NA"}
                                          </td>
                                        </tr>
                                        <tr className="text-end border-0">
                                          <td className="border-0 " colSpan={2}>
                                            <button
                                              disabled={item.is_bidded}
                                              onClick={() => selectVenue(item)}
                                              className="btn btn-danger d-block w-100 px-5"
                                            >
                                              <span>
                                                {item.is_bidded
                                                  ? "You have already sent request for this event"
                                                  : "Bid"}
                                              </span>
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EventListingForBid;
