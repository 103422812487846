const initialState = {
    upcomingEvents:[],
    indivualUserUpcomingEvents :[],
    eventBasedOnId :{},
    indivualEventDetail :{}
}

const UpcomingEventReducer =(state=initialState,action)=>{
    
    switch (action.type) {
        case "SAVE_ALL_UPCOMING_EVENT" :
            return{
                ...state,
                upcomingEvents : [...action.payload]
            }
        case "SAVE_ALL_UPCOMING_EVENT_LOGGED_USER" :
            return{
                ...state,
                indivualUserUpcomingEvents : [...action.payload]
            }
        case  "SAVE_EVENT_BASED_ON_ID":
            return{
                ...state,
                eventBasedOnId : {...action.payload}

            }  
        case "SAVE_EVENT_DETAILS_BASED_ON_ID" :
            return{
                ...state,
                indivualEventDetail : {...action.payload}
            }    
        default:
           return state;
    }
}
export default UpcomingEventReducer;

