import Actions from "./Actions";

export const AddVenueAction = (payload, auth, callback) => {
  return {
    type: Actions.ADD_VENUES,
    payload,
    auth,
    callback,
  };
};

export const GetAllVenueAction = (auth, filter) => {
  return {
    type: Actions.GET_ALL_VENUES,
    auth,
    filter,
  };
};

export const SaveAllVenuesAction = (payload) => {
  return {
    type: Actions.SAVE_ALL_VENUES,
    payload,
  };
};

export const SaveAllVenuesLoggedUserAction = (payload) => {
  return {
    type: Actions.SAVE_ALL_VENUES_LOGGED_USER,
    payload,
  };
};

export const GetAllVenuesForLoggedUserAction = (auth, filter) => {
  return {
    type: Actions.GET_ALL_VENUE_FOR_LOGGED_USER,
    auth,
    filter,
  };
};

export const GettingIndivualVenueBasedOnIdAction = (payload, auth) => {
  return {
    type: Actions.GET_INDIVUAL_VENUE_BASED_ON_ID,
    payload,
    auth,
  };
};

export const SaveVenueBasedOnId = (payload) => {
  return {
    type: Actions.SAVE_VENUE_BASED_ON_ID,
    payload,
  };
};

export const UpdateVenueAction = (payload, auth, id, callback) => {
  return {
    type: Actions.EDIT_VENUES,
    payload,
    auth,
    id,
    callback,
  };
};

export const DeleteVenueAction = (payload, auth, callback) => {
  return {
    type: Actions.DELETE_VENUES,
    payload,
    auth,
    callback,
  };
};

export const GettingIndivualVenueBasedOnIdPublicAction = (payload, auth) => {
  return {
    type: Actions.GET_INDIVUAL_VENUE_BASED_ON_ID_PUBLIC,
    payload,
    auth,
  };
};

export const GettAllEventsForIndivualVeneuePublicAction = (payload, auth) => {
  return {
    type: Actions.GET_ALL_EVENTS_FOR_INDIVUAL_VENUE_PUBLIC,
    payload,
    auth,
  };
};

export const SaveAllEventsForIndivaulVenueBasedOnId = (payload) => {
  return {
    type: Actions.SAVE_ALL_EVENTS_FOR_INDIVUAL_VENUE_BASED_ON_ID,
    payload,
  };
};

export const GetAllCalendarEventForVenuePublicAction = (payload, auth) => {
  return {
    type: Actions.GET_ALL_CALENDAR_EVENT_FOR_VENUE_PUBLIC,
    payload,
    auth,
  };
};

export const SaveAllCalendarEventForVenuePublicAction = (payload) => {
  return {
    type: Actions.SAVE_ALL_CALENDAR_EVENT_FOR_VENUE_PUBLIC,
    payload,
  };
};

export const GettingIndivualVenueBasedOnIdPrivateAction = (payload, auth) => {
  return {
    type: Actions.GET_VENUE_DETAILS_BASED_ON_ID_FOR_LOGGED_IN_USER,
    payload,
    auth,
  };
};

export const FollowVenueAction = (payload, auth, callback) => {
  return {
    type: Actions.FOLLOW_VENUE,
    payload,
    auth,
    callback,
  };
};

export const UnFollowVenueAction = (id, auth, callback) => {
  return {
    type: Actions.UNFOLLOW_VENUE,
    id,
    auth,
    callback,
  };
};

export const getOverallReviewForVenueBasedOnIdAction = (payload, auth) => {
  return {
    type: Actions.GET_OVERALL_REVIEW_BASED_ON_VENUE_ID,
    payload,
    auth,
  };
};

export const SaveOverallReviewForVenueBasedOnIdAction = (payload) => {
  return {
    type: Actions.SAVE_OVERALL_REVIEW_BASED_ON_VENUE_ID,
    payload,
  };
};

export const UploadVenueMediaAction = (payload, auth, callback) => {
  return {
    type: Actions.UPLOAD_VENUE_MEDIA,
    payload,
    auth,
    callback,
  };
};

// adding action to get photos
export const getPhotosForVenueAction = (id) => {
  return {
    type: Actions.GET_PHOTOS_FOR_VENUE,
    id,
  };
};

export const saveVenuePhotos = (payload) => {
  return {
    type: Actions.SAVE_VENUE_PHOTOS,
    payload,
  };
};

export const getVideosForVenueAction = (id) => {
  return {
    type: Actions.GET_VIDEOS_FOR_VENUE,
    id,
  };
};

export const saveVideosForVenueAction = (payload) => {
  return {
    type: Actions.SAVE_VENUE_VIDEOS,
    payload,
  };
};

export const getAudiosForVenueAction = (id) => {
  return {
    type: Actions.GET_AUDIOS_FOR_VENUE,
    id,
  };
};

export const saveAudiosForVenueAction = (payload) => {
  return {
    type: Actions.SAVE_VENUE_AUDIOS,
    payload,
  };
};
