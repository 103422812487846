import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    >
      Previous
    </div>
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    >
      Next
    </div>
  );
}

const ImgSlider = ({ media }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <Slider {...settings}>
      {media?.map((media, index) => (
        <div key={index}>
          {media?.media_type == "Photo" ? (
            <img
              className="slider-images"
              src={media?.media}
              alt={`Image ${index + 1}`}
            />
          ) : media?.media_type == "Video" ? (
            <video className="slider-images" width="640" height="360" controls>
              <source src={media?.media} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <audio className="slider-images" controls>
              <source src={media?.media} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          )}
        </div>
      ))}
    </Slider>
  );
};

export default ImgSlider;


