import React, { useState } from "react";
import Aud from "../../../../Assets/audio.mp3";
import Photos from "../../../../Components/Body/Gallery/Photos";
import Videos from "../../../../Components/Body/Gallery/Videos";
import Audio from "../../../../Components/Body/Gallery/Audio";
import DeleteMedia from "../../../../Components/Body/Gallery/DeleteMedia";
import { useDispatch, useSelector } from "react-redux";
import { deleteMediaFilesAction } from "../../../../Redux/Action/Artists";
const ArtistGallery = ({ id, isPublic }) => {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedMediaId, setSelectedMediaId] = useState()
  const authToken = useSelector((state) => state.Auth.authToken);
 
  //page type artist 
  const [activeTab, setActiveTab] = useState("photos");
  
  const handleTabChange = (tab) => {
    setActiveTab(tab); 
  };

  //DELETE venue modal
  const openDeleteModal = (imageId) => {
    setSelectedMediaId(imageId);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    dispatch(deleteMediaFilesAction(selectedMediaId,authToken,(type, message)=>{
       if(type=="success")
       {
        window.location.reload();
       }
    }))
  };

  const cancelDeleteModal = () => {
    setShowDeleteModal(false);
  };

  return (
    <>
      <div className="media-page">
        <div className="media-inner-tab d-flex justify-content-center">
          <ul class="nav nav-tabs mt-3" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                id="photos-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected={activeTab === "photos"}
                class={`nav-link ${activeTab === "photos" ? "active" : ""}`}
                onClick={() => handleTabChange("photos")}
              >
                Photos
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                id="video-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected={activeTab === "videos"}
                class={`nav-link ${activeTab === "videos" ? "active" : ""}`}
                onClick={() => handleTabChange("videos")}
              >
                Videos
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                id="contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#contact"
                type="button"
                role="tab"
                aria-controls="contact"
                aria-selected={activeTab === "audio"}
                class={`nav-link ${activeTab === "audio" ? "active" : ""}`}
                onClick={() => handleTabChange("audio")}
              >
                Audios
              </button>
            </li>
          </ul>
        </div>
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="photos-tab"
          >
            
           { activeTab=="photos" && <Photos openDeleteModal={openDeleteModal} id={id} isPublic={isPublic} />}
          </div>
          <div
            class="tab-pane fade"
            id="profile"
            role="tabpanel"
            aria-labelledby="video-tab"
          >
            {activeTab=='videos' && <Videos id={id} />}
          </div>
          <div
            class="tab-pane fade"
            id="contact"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            {activeTab=='audio' && <Audio id={id} />} 
          </div>
        </div>
      </div>

      {showDeleteModal && (
        <DeleteMedia
          cancel={cancelDeleteModal}
          showModal={showDeleteModal}
          closeModal={closeDeleteModal}
        />
      )}
    </>
  );
};

export default ArtistGallery;
