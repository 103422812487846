import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import LoaderImg from "../../../Assets/loader.gif";
import {
  GettingIndivualArtistsBasedOnIdAction,
  UpdateArtistsAction,
} from "../../../Redux/Action/Artists";
import EditForm from "./EditForm";

const options = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditArtists = ({ showModal, closeModal, cancel }) => {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.Loading.loading);
  const artists = useSelector((state) => state.Artists.artistsBasedOnId);
  const authToken = useSelector((state) => state.Auth.authToken);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
  } = useForm({
    values: {
      name: artists.name,
      email: artists.email,
      image: artists.image,
      address: artists.address,
      state: artists.state,
      city: artists.city,
      zip_code: artists.zip_code,
      phone: artists.phone,
      introduction: artists.introduction,
      bio: artists.bio,
      key_information: artists.key_information,
      more_information: artists.more_information,
      rate_from: artists.rate_from,
      rate_to: artists.rate_to,
      setup_hours: artists.setup_hours,
      website: artists.website,
      facebook: artists.facebook,
      twitter: artists.twitter,
      instagram: artists.instagram,
      published: artists.published,
    },
  });

  const submitHandler = (data) => {
    let val;
    if (data?.image) {
      val = Object?.values(data?.image);
    }

    const formData = new FormData();
    formData.append("name", data["name"]);
    formData.append("email", data["email"]);
    if (data?.image && data?.image != artists?.image) {
      formData.append("image", val[0]);
    }
    formData.append("address", data["address"]);
    formData.append("city", data["city"]);
    formData.append("state", data["state"]);
    formData.append("zip_code", data["zip_code"]);
    formData.append("phone", data["phone"]);
    formData.append(
      "introduction",
      data["introduction"] ? data["introduction"] : ""
    );
    formData.append("bio", data["bio"] ? data["bio"] : "");
    formData.append("key_information", data["key_information"]);
    formData.append("more_information", data["more_information"]);
    formData.append("rate_from", data["rate_from"] ? data["rate_from"] : "");
    formData.append("rate_to", data["rate_to"] ? data["rate_to"] : "");
    formData.append(
      "setup_hours",
      data["setup_hours"] ? data["setup_hours"] : ""
    );
    formData.append("website", data["website"] ? data["website"] : "");
    formData.append("facebook", data["facebook"] ? data["facebook"] : "");
    formData.append("twitter", data["twitter"] ? data["twitter"] : "");
    formData.append("instagram", data["instagram"] ? data["instagram"] : "");
    formData.append("published", data["published"] ? data["published"] : "");
    dispatch(
      UpdateArtistsAction(formData, authToken, artists.id, (type, message) => {
        toast[type](message, {
          duration: 5000,
        });
        if (type == "success") {
          closeModal();
          dispatch(
            GettingIndivualArtistsBasedOnIdAction(artists?.id, authToken)
          );
        }
      })
    );
  };

  useEffect(() => {}, [artists]);

  return (
    <>
      <Modal
        open={showModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="edit-venue-page"
      >
        <Box sx={style} className="modal-form">
          <form onSubmit={handleSubmit(submitHandler)}>
            <>
              <p className=" text-danger text-uppercase h6">Edit Artists</p>
              <button type="button" onClick={cancel} className="btn close-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </button>
              <div className="row">
                <EditForm
                  options={options}
                  artists={artists}
                  Controller={Controller}
                  control={control}
                  register={register}
                  errors={errors}
                />
              </div>
            </>

            <div className="submission-form-popup">
              <button disabled={loader} type="submit" className="login-btn ">
                {loader ? (
                  <img className="loader-image" src={LoaderImg} />
                ) : (
                  "Update"
                )}
              </button>

              <button onClick={cancel} type="button" className="login-btn">
                Cancel
              </button>
            </div>
          </form>
        </Box>
      </Modal>
      <Toaster />
    </>
  );
};
export default EditArtists;
