const initialState ={
    allGigs:[]
}

const GigsReducer =(state=initialState,action)=>{
    
    switch(action.type){
  
        case "SAVE_ALL_GIGS_FOR_USER":
            return{
                allGigs: [...action.payload]
            }

        default:
            return state;
    }
}
export default GigsReducer;