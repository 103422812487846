import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ImgSlider from "./Slider";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
};

const SliderPopup = ({ showSliderPopup, closeSliderPopup, media }) => {

  return (
    <>
      <Modal
        open={showSliderPopup}
        onClose={closeSliderPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="slider-popup"
      >
        <Box sx={style} className="modal-slider">
          <button
            type="button"
            onClick={closeSliderPopup}
            className="btn close-btn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
            </svg>
          </button>

          <ImgSlider media={media} />

        </Box>
      </Modal>
    </>
  );
};
export default SliderPopup;
