import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Toaster } from "react-hot-toast";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import { useForm, Controller } from "react-hook-form";
import { DisplayValidationErrors, errorMessages } from "../../Utilities";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddReviewModal = ({
  selectedId,
  showModal,
  closeModal,
  cancel,
  reviewType,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const submitHandler = (data) => {
    const payload = {
      review: data.message,
      [reviewType]: data.rate,
    };
    closeModal(payload, selectedId);
    reset();
  };
  return (
    <>
      <Modal
        open={showModal}
        onClose={cancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="delete-modal-page"
      >
        <Box sx={style} className="review-form">
          <>
            <h5 className="text-danger" style={{ marginTop: "-19px" }}>
              REVIEW SECTION
            </h5>
            <div className="row">
              <button type="button" onClick={cancel} className="btn close-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </button>
              <div className="col-md-12 mb-3 text-center mt-3">
                <form onSubmit={handleSubmit(submitHandler)}>
                  <div className="row">
                    <div className="col-md-8 m-auto">
                      <label>Please give your valuable feedback</label>
                      <textarea
                        placeholder="Please enter your feedback"
                        className="login-field p-3"
                        type="text"
                        {...register("message", { required: true })}
                      />
                      {errors["message"] ? (
                        <DisplayValidationErrors
                          message={errorMessages.FILL_FIELD}
                        />
                      ) : null}
                    </div>

                    <div className="col-md-8 m-auto">
                      <label>Give ratings</label> <br />
                      <Controller
                        control={control}
                        name="rate"
                        rules={{ required: true }}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Rating
                            inputRef={ref}
                            name="size-large"
                            onChange={(val) => onChange(val)}
                            max={5}
                            size="large"
                          />
                        )}
                      />
                    </div>
                    {errors["rate"] ? (
                      <DisplayValidationErrors
                        message={errorMessages.FILL_FIELD}
                      />
                    ) : null}
                    
                    <div className="review-form-popup mt-3">
                      <button type="submit" className="review-btn rounded-3">
                        Add Review
                      </button>

                      <button
                        onClick={cancel}
                        type="button"
                        className="review-btn rounded-3"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
        </Box>
      </Modal>
      <Toaster />
    </>
  );
};
export default AddReviewModal;
