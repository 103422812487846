import React from "react";

const Testimonial = () => {
  return (
    <>
      <section className="py-5 border-bottom border-black shadow-sm">
        <div className="container-fluid px-5 my-5 px-5">
          <div className="text-center mb-5">
            <h2 className="fw-bolder">Customer testimonials</h2>
            <p className="lead mb-0">Amazing APP and great customer support</p>
          </div>
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-6">
              <div className="card mb-4 shadow-sm">
                <div className="card-body p-4">
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="42"
                        height="42"
                        fill="currentColor"
                        className="bi bi-chat-right-heart-fill text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9.586a1 1 0 0 1 .707.293l2.853 2.853a.5.5 0 0 0 .854-.353V2ZM8 3.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z" />
                      </svg>
                    </div>
                    <div className="ms-4">
                      <p className="mb-1">
                        Thank you for putting together such a great product. We
                        loved working with you and the whole team, and we will
                        be recommending you to others!
                      </p>
                      <div className="small text-muted">
                        - Client Name, Location
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card shadow-sm">
                <div className="card-body p-4">
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="42"
                        height="42"
                        fill="currentColor"
                        className="bi bi-chat-right-heart-fill text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9.586a1 1 0 0 1 .707.293l2.853 2.853a.5.5 0 0 0 .854-.353V2ZM8 3.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z" />
                      </svg>
                    </div>
                    <div className="ms-4">
                      <p className="mb-1">
                        The whole team was a huge help with putting things
                        together for our company and brand. We will be hiring
                        them again in the near future for additional work!
                      </p>
                      <div className="small text-muted">
                        - Client Name, Location
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;
