const Actions = {
  REGISTER_USER: "REGISTER_USER",
  START_LODER: "START_LOADER",
  STOP_LOADER: "STOP_LOADER",
  LOGIN_USER: "LOGIN_USER",
  SAVE_AUTH: "SAVE_AUTH",
  REMOVE_AUTH: "REMOVE_AUTH",
  RESET_PASSWORD: "RESET_PASSWORD",
  SEND_OTP: "SEND_OTP",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  //PROFILE ACTIONS
  GET_PROFILE: "GET_PROFILE",
  EDIT_PROFILE: "EDIT_PROFILE",
  ADD_ADMIN_USER: "ADD_ADMIN_USER",
  GET_USER_PROFILE_FROM_API: "GET_USER_PROFILE_FROM_API",
  SAVE_USER_PROFILE_DETAILS: "SAVE_USER_PROFILE_DETAILS",
  //ARTIST ACTIONS
  ADD_ARTISTS: "ADD_ARTISTS",
  DELETE_ARTISTS: "DELETE_ARTISTS",
  EDIT_ARTISTS: "EDIT_ARTISTS",
  GET_ALL_ARTISTS: "GET_ALL_ARTISTS",
  SAVE_ALL_ARTISTS: "SAVE_ALL_ARTISTS",
  SAVE_ALL_ARTISTS_LOGGED_USER: "SAVE_ALL_ARTISTS_LOGGED_USER",
  GET_ALL_ARTISTS_FOR_LOGGED_USER: "GET_ALL_ARTISTS_FOR_LOGGED_USER",
  GET_INDIVUAL_ARTISTS_BASED_ON_ID: "GET_INDIVUAL_ARTISTS_BASED_ON_ID",
  SAVE_ARTISTS_BASED_ON_ID: "SAVE_ARTISTS_BASED_ON_ID",
  GET_INDIVUAL_ARTISTS_BASED_ON_ID_PUBLIC:
    "GET_INDIVUAL_ARTISTS_BASED_ON_ID_PUBLIC",
  FOLLOW_ARTIST: "FOLLOW_ARTIST",
  UNFOLLOW_ARTIST: "UNFOLLOW_ARTIST",
  GET_FOLLOWING_ARTIST_LIST: "GET_FOLLOWING_ARTIST_LIST",
  SAVE_FOLLOWED_ARTIST: "SAVE_FOLLOWED_ARTIST",
  GET_ALL_CALENDAR_EVENT_FOR_ARTIST: "GET_ALL_CALENDAR_EVENT_FOR_ARTIST",
  SAVE_ALL_CALENDAR_EVENT_FOR_ARTIST: "SAVE_ALL_CALENDAR_EVENT_FOR_ARTIST",
  GET_OVERALL_REVIEW_BASED_ON_ARTIST_ID:
    "GET_OVERALL_REVIEW_BASED_ON_ARTIST_ID",
  SAVE_OVERALL_REVIEW_BASED_ON_ARTIST_ID:
    "SAVE_OVERALL_REVIEW_BASED_ON_ARTIST_ID",
  UPLOAD_ARTIST_MEDIA: "UPLOAD_ARTIST_MEDIA",
  GET_PHOTOS_FOR_ARTIST: "GET_PHOTOS_FOR_ARTIST",
  SAVE_ARTIST_PHOTOS: "SAVE_ARTIST_PHOTOS",
  GET_VIDEOS_FOR_ARTIST: "GET_VIDEOS_FOR_ARTIST",
  SAVE_ARTIST_VIDEOS: "SAVE_ARTIST_VIDEOS",
  GET_AUDIOS_FOR_ARTIST: "GET_AUDIOS_FOR_ARTIST",
  SAVE_ARTIST_AUDIOS: "SAVE_ARTIST_AUDIOS",
  DELETE_MEDIA_FILES: "DELETE_MEDIA_FILES",

  //VENUES ACTIONS
  ADD_VENUES: "ADD_VENUES",
  DELETE_VENUES: "DELETE_VENUES",
  EDIT_VENUES: "EDIT_VENUES",
  GET_ALL_VENUES: "GET_ALL_VENUES",
  SAVE_ALL_VENUES: "SAVE_ALL_VENUES",
  SAVE_ALL_VENUES_LOGGED_USER: "SAVE_ALL_VENUES_LOGGED_USER",
  SAVE_VENUE_BASED_ON_ID: "SAVE_VENUE_BASED_ON_ID",
  GET_ALL_VENUE_FOR_LOGGED_USER: "GET_ALL_VENUE_FOR_LOGGED_USER",
  GET_INDIVUAL_VENUE_BASED_ON_ID: "GET_INDIVUAL_VENUE_BASED_ON_ID",
  GET_INDIVUAL_VENUE_BASED_ON_ID_PUBLIC:
    "GET_INDIVUAL_VENUE_BASED_ON_ID_PUBLIC",
  GET_ALL_EVENTS_FOR_INDIVUAL_VENUE_PUBLIC:
    "GET_ALL_EVENTS_FOR_INDIVUAL_VENUE_PUBLIC",
  SAVE_ALL_EVENTS_FOR_INDIVUAL_VENUE_BASED_ON_ID:
    "SAVE_ALL_EVENTS_FOR_INDIVUAL_VENUE_BASED_ON_ID",
  GET_ALL_CALENDAR_EVENT_FOR_VENUE_PUBLIC:
    "GET_ALL_CALENDAR_EVENT_FOR_VENUE_PUBLIC",
  SAVE_ALL_CALENDAR_EVENT_FOR_VENUE_PUBLIC:
    "SAVE_ALL_CALENDAR_EVENT_FOR_VENUE_PUBLIC",
  GET_VENUE_DETAILS_BASED_ON_ID_FOR_LOGGED_IN_USER:
    "GET_VENUE_DETAILS_BASED_ON_ID_FOR_LOGGED_IN_USER",
  FOLLOW_VENUE: "FOLLOW_VENUE",
  UNFOLLOW_VENUE: "UNFOLLOW_VENUE",
  GET_OVERALL_REVIEW_BASED_ON_VENUE_ID: "GET_OVERALL_REVIEW_BASED_ON_VENUE_ID",
  SAVE_OVERALL_REVIEW_BASED_ON_VENUE_ID:
    "SAVE_OVERALL_REVIEW_BASED_ON_VENUE_ID",
  UPLOAD_VENUE_MEDIA: "UPLOAD_VENUE_MEDIA",
  //UPCOMING EVENTS
  GET_ALL_UPCOMING_EVENT: "GET_ALL_UPCOMING_EVENT",
  SAVE_ALL_UPCOMING_EVENT: "SAVE_ALL_UPCOMING_EVENT",
  SAVE_ALL_UPCOMING_EVENT_LOGGED_USER: "SAVE_ALL_UPCOMING_EVENT_LOGGED_USER",
  DELETE_UPCOMING_EVENT: "DELETE_UPCOMING_EVENT",
  EDIT_UPCOMING_EVENT: "EDIT_UPCOMING_EVENT",
  GET_ALL_EVENTS_FOR_LOGGED_USER: "GET_ALL_EVENTS_FOR_LOGGED_USER",
  ADD_EVENT: "ADD_EVENT",
  GET_INDIVUAL_EVENT_BASED_ON_ID: "GET_INDIVUAL_EVENT_BASED_ON_ID",
  SAVE_EVENT_BASED_ON_ID: "SAVE_EVENT_BASED_ON_ID",
  GET_ALL_BIDABLE_EVENTS: "GET_ALL_BIDABLE_EVENTS",
  GET_EVENT_DETAILS_BASED_ON_ID: "GET_EVENT_DETAILS_BASED_ON_ID",
  SAVE_EVENT_DETAILS_BASED_ON_ID: "SAVE_EVENT_DETAILS_BASED_ON_ID",

  //BIDDING
  BIDDING_ARTIST: "BIDDING_ARTIST",
  GET_ALL_GIGS_FOR_USER: "GET_ALL_GIGS_FOR_USER",
  SAVE_ALL_GIGS_FOR_USER: "SAVE_ALL_GIGS_FOR_USER",
  ACCEPT_GIG_ARTIST_MANAGER: "ACCEPT_GIG_ARTIST_MANAGER",
  REJECT_GIG_ARTIST_MANAGER: "REJECT_GIG_ARTIST_MANAGER",
  NEGOTIATE_GIG_PRICE: "NEGOTIATE_GIG_PRICE",
  GET_MY_BIDS: " GET_MY_BIDS",
  SAVE_MY_BIDS: "SAVE_MY_BIDS",
  ACCEPT_GIG_VENUE_MANAGER: "ACCEPT_GIG_VENUE_MANAGER",
  REJECT_GIG_VENUE_MANAGER: "REJECT_GIG_VENUE_MANAGER",
  SAVE_BIDABLE_BIDS: "SAVE_BIDABLE_BIDS",
  SAVE_AGREEMENT_CONTENT_VENUE_MANAGER: "SAVE_AGREEMENT_CONTENT_VENUE_MANAGER",
  SEND_AGREEMENT_CONTENT_VENUE_MANAGER: "SEND_AGREEMENT_CONTENT_VENUE_MANAGER",
  BID_ON_EVENT_BY_ARTIST_MANAGER: "BID_ON_EVENT_BY_ARTIST_MANAGER",
  ADD_REVIEW: "ADD_REVIEW",

  // adding constant for venue media
  GET_PHOTOS_FOR_VENUE: "GET_PHOTOS_FOR_VENUE",
  GET_VIDEOS_FOR_VENUE: "GET_VIDEOS_FOR_VENUE",
  GET_AUDIOS_FOR_VENUE: "GET_AUDIOS_FOR_VENUE",
  SAVE_VENUE_PHOTOS: "SAVE_VENUE_PHOTOS",
  SAVE_VENUE_VIDEOS: "SAVE_VENUE_VIDEOS",
  SAVE_VENUE_AUDIOS: "SAVE_VENUE_AUDIOS",

  // Master data
  GET_ALL_GENER: "GET_GENER",
  SAVE_ALL_GENER: "SAVE_ALL_GENER",
  GET_BEVERAGE_TYPE: "GET_BEVERAGE_TYPE",
};

export default Actions;
