import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import PublicNavlinks from "./PublicNavlinks";
import { perstore } from "../../Redux/store";
import { RemoveAuth } from "../../Redux/Action/AuthAction";
import PrivateNavlinks from "./PrivateNavlinks";
import CommonLinks from "./CommonLinks";

const Navigation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const LogoutHandler = () => {
    perstore.purge();
    dispatch(RemoveAuth());
    window.location.href = "/"; 
   // navigate("/");
  };
  const authToken = useSelector((state) => state.Auth.authToken);
  return (
    <>
      <div className="">
        <nav className="navbar navbar-expand-lg fixed-top navbar-dark bg-dark" data-bs-theme="dark">
          <div className="container-fluid">
            <Link
              className="navbar-brand"
              to={!authToken ? "/" : "/bod/events"}
            >
              BandzOnDemand
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-end ml-auto"
              id="navbarNavDropdown"
            >
              <ul className="navbar-nav">
                {!authToken ? (
                  <PublicNavlinks />
                ) : (
                  <>
                    <CommonLinks/>
                    <PrivateNavlinks LogoutHandler={LogoutHandler} />
                  </>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navigation;
