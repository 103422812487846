const initialState = {
  artists: [],
  indivualUserArtists: [],
  artistsBasedOnId: [],
  FollwedArtist: [],
  CalenderEventsForArtist: [],
  overallReview: {},
  artistPhotos: [],
  artistVideos: [],
  artistAudios: [],
};

const ArtistsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_ALL_ARTISTS":
      return {
        ...state,
        artists: [...action.payload["result"]],
        total_artists: action.payload["count"],
      };
    case "SAVE_ALL_ARTISTS_LOGGED_USER":
      return {
        ...state,
        indivualUserArtists: [...action.payload],
      };

    case "SAVE_ALL_CALENDAR_EVENT_FOR_ARTIST":
      return {
        ...state,
        CalenderEventsForArtist: [...action.payload],
      };
    case "SAVE_ARTISTS_BASED_ON_ID":
      return {
        ...state,
        artistsBasedOnId: { ...action.payload },
      };
    case "SAVE_FOLLOWED_ARTIST":
      return {
        ...state,
        FollwedArtist: [...action.payload],
      };
    case "SAVE_OVERALL_REVIEW_BASED_ON_ARTIST_ID":
      return {
        ...state,
        overallReview: { ...action.payload },
      };

    case "SAVE_ARTIST_PHOTOS":
      return {
        ...state,
        artistPhotos: [...action.payload],
      };

    case "SAVE_ARTIST_VIDEOS":
      return {
        ...state,
        artistVideos: [...action.payload],
      };

    case "SAVE_ARTIST_AUDIOS":
      return {
        ...state,
        artistAudios: [...action.payload],
      };

    default:
      return state;
  }
};
export default ArtistsReducer;
