import { CardActions } from "@mui/material";
import Actions from "./Actions";

export const AddArtistAction = (payload, auth, callback) => {
  return {
    type: Actions.ADD_ARTISTS,
    payload,
    auth,
    callback,
  };
};

export const GetAllArtistsAction = (auth, filter) => {
  return {
    type: Actions.GET_ALL_ARTISTS,
    auth,
    filter,
  };
};

export const SaveAllArtistsAction = (payload) => {
  return {
    type: Actions.SAVE_ALL_ARTISTS,
    payload,
  };
};

export const SaveAllArtistsForLoggedUserAction = (payload) => {
  return {
    type: Actions.SAVE_ALL_ARTISTS_LOGGED_USER,
    payload,
  };
};

export const GetAllArtistsForLoggedUserAction = (auth, filter) => {
  return {
    type: Actions.GET_ALL_ARTISTS_FOR_LOGGED_USER,
    auth,
    filter,
  };
};

export const GettingIndivualArtistsBasedOnIdAction = (payload, auth) => {
  return {
    type: Actions.GET_INDIVUAL_ARTISTS_BASED_ON_ID,
    payload,
    auth,
  };
};

export const SaveArtistsBasedOnId = (payload) => {
  return {
    type: Actions.SAVE_ARTISTS_BASED_ON_ID,
    payload,
  };
};

export const UpdateArtistsAction = (payload, auth, id, callback) => {
  return {
    type: Actions.EDIT_ARTISTS,
    payload,
    auth,
    id,
    callback,
  };
};

export const GettingIndivualArtistsBasedOnIdPublicAction = (payload, auth) => {
  return {
    type: Actions.GET_INDIVUAL_ARTISTS_BASED_ON_ID_PUBLIC,
    payload,
    auth,
  };
};
export const FollowArtistAction = (payload, auth, callback) => {
  return {
    type: Actions.FOLLOW_ARTIST,
    payload,
    auth,
    callback,
  };
};

export const UnFollowArtistAction = (id, auth, callback) => {
  return {
    type: Actions.UNFOLLOW_ARTIST,
    id,
    auth,
    callback,
  };
};

export const GetFollowingArtistListAction = (auth) => {
  return {
    type: Actions.GET_FOLLOWING_ARTIST_LIST,
    auth,
  };
};

export const SaveFollowingArtistListAction = (auth) => {
  return {
    type: Actions.SAVE_FOLLOWED_ARTIST,
    auth,
  };
};

export const GetAllCalendarEventForArtistAction = (payload) => {
  return {
    type: Actions.GET_ALL_CALENDAR_EVENT_FOR_ARTIST,
    payload,
  };
};

export const SaveAllCalendarEventForArtistAction = (payload) => {
  return {
    type: Actions.SAVE_ALL_CALENDAR_EVENT_FOR_ARTIST,
    payload,
  };
};

export const getOverallReviewForArtistBasedOnIdAction = (payload, auth) => {
  return {
    type: Actions.GET_OVERALL_REVIEW_BASED_ON_ARTIST_ID,
    payload,
    auth,
  };
};

export const SaveOverallReviewForArtistBasedOnIdAction = (payload) => {
  return {
    type: Actions.SAVE_OVERALL_REVIEW_BASED_ON_ARTIST_ID,
    payload,
  };
};

export const DeleteArtistAction = (payload, auth, callback) => {
  return {
    type: Actions.DELETE_ARTISTS,
    payload,
    auth,
    callback,
  };
};

export const UploadArtistMediaAction = (payload, auth, callback) => {
  return {
    type: Actions.UPLOAD_ARTIST_MEDIA,
    payload,
    auth,
    callback,
  };
};

export const getPhotosForArtistAction = (id) => {
  return {
    type: Actions.GET_PHOTOS_FOR_ARTIST,
    id,
  };
};

export const saveArtistPhotos = (payload) => {
  return {
    type: Actions.SAVE_ARTIST_PHOTOS,
    payload,
  };
};

export const getVideosForArtistAction = (id) => {
  return {
    type: Actions.GET_VIDEOS_FOR_ARTIST,
    id,
  };
};

export const saveVideosForArtistAction = (payload) => {
  return {
    type: Actions.SAVE_ARTIST_VIDEOS,
    payload,
  };
};

export const getAudiosForArtistAction = (id) => {
  return {
    type: Actions.GET_AUDIOS_FOR_ARTIST,
    id,
  };
};

export const saveAudiosForArtistAction = (payload) => {
  return {
    type: Actions.SAVE_ARTIST_AUDIOS,
    payload,
  };
};

export const deleteMediaFilesAction = (id, auth, callback) => {
  return {
    type: Actions.DELETE_MEDIA_FILES,
    id,
    auth,
    callback,
  };
};
