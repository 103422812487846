// context/todoContext.tsx
import { AddEventAction } from "../Redux/Action/UpcomingEvents";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import * as React from "react";
import { GetAllCalendarEventForVenuePublicAction } from "../Redux/Action/Venues";

export const EventContext = React.createContext();

const fixDatesAsIso = (event) => {
  const starter = new Date(Date.parse(event.start));
  starter.setMinutes(starter.getMinutes() - starter.getTimezoneOffset());
  const ender = new Date(Date.parse(event.end));
  ender.setMinutes(ender.getMinutes() - ender.getTimezoneOffset());

  return {
    start: starter.toISOString().slice(0, 16),
    end: ender.toISOString().slice(0, 16),
  };
};

const fixDatesAsTimestamps = (event) => {
  const starter = new Date(Date.parse(event.start));
  starter.setMinutes(starter.getMinutes() - starter.getTimezoneOffset());
  const ender = new Date(Date.parse(event.end));
  ender.setMinutes(ender.getMinutes() - ender.getTimezoneOffset());

  return {
    start: Date.parse(starter),
    end: Date.parse(ender),
  };
};

const EventProvider = ({
  children,
  isVenuePublic,
  isArtistPublic,
  openEventDetailsPopup,
  venueDetail,
  selectedDate,
}) => {
  const dispatch = useDispatch();
  const calendarEvents = useSelector(
    (state) => state.Venues.CalenderEventsForVenue
  );
  const authToken = useSelector((state) => state.Auth.authToken);
  const [events, setEvents] = React.useState([]);
  const [selectedEvent, setSelectedEvent] = React.useState({
    _id: "",
    introduction: "",
    description: "",
    min_rate: "",
    max_rate: "",
    start: null,
    end: null,
  });
  const [open, setOpen] = React.useState(false);
  const [formType, setFormType] = React.useState("");

  const handleEventClick = (event) => {
    openEventDetailsPopup(event);
  };

  const handleClickOpen = (event = null) => {
    try {
      if (isVenuePublic || isArtistPublic) {
        setOpen(false);
      } else {
        setOpen(true);
      }
      if (event === null) {
        // Create New Event
        setFormType("add");
      } else if (
        event.hasOwnProperty("start") &&
        event.hasOwnProperty("end") &&
        !event.hasOwnProperty("title") &&
        !event.hasOwnProperty("description")
      ) {
        // Timeslot Select
        setFormType("add");
        const stamps = fixDatesAsIso(event);
        setSelectedEvent(stamps);
        console.log("timeslot", stamps);
      } else {
        // Select Existing
        const stamps = fixDatesAsTimestamps(event);
        setSelectedEvent({ ...stamps, ...event });
        console.log("selectExisting", selectedEvent);
        setFormType("show");
      }
    } catch (e) {}
  };

  const handleClose = () => {
    setFormType("");
    setSelectedEvent(null);
    setOpen(false);
  };
  const saveEvent = async (data) => {
    const formData = new FormData();
    formData.append("start_time", data["startTime"]);
    formData.append("end_time", data["endTime"]);
    formData.append("introduction", data["introduction"]);
    formData.append("description", data["description"]);
    formData.append("rate_from", data["minRate"]);
    formData.append("rate_to", data["maxRate"]);
    formData.append(
      "venue",
      venueDetail?.id ? venueDetail.id : selectedDate?.id
    );
    if (data["image"]) {
      formData.append("image", data["image"]);
    }
    formData.append("genre", JSON.stringify(data["genre"]));
    if (!data["isPublic"]) {
      formData.append("artist", data["artist"]);
    }

    dispatch(
      AddEventAction(formData, authToken, (type, message) => {
        toast[type](message, {
          duration: 5000,
        });
        if (type === "success") {
          handleClose();
          dispatch(
            GetAllCalendarEventForVenuePublicAction(venueDetail.id, authToken)
          );
          setEvents(calendarEvents);
        }
      })
    );
  };

  return (
    <EventContext.Provider
      value={{
        events,
        open,
        saveEvent,
        handleClickOpen,
        handleEventClick,
        handleClose,
        selectedEvent,
        setSelectedEvent,
        formType,
        setFormType,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export default EventProvider;

export function useEventContext() {
  return React.useContext(EventContext);
}
