import Actions from "../Action/Actions";

const initialState = {
  authToken: null,
  refreshToken: null,
  profile: null,
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_AUTH":
      return {
        authToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        profile: action.payload.profile,
      };
    case "REMOVE_AUTH":
      return {
        authToken: null,
        refreshToken: null,
        profile: null,
      };

      case "SAVE_USER_PROFILE_DETAILS":
        return {
          ...state,
          profile: action.payload,
        };
    default:
      return state;
  }
};

export default AuthReducer;
