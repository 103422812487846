import React, { useEffect } from "react";

import Vid from "../../../Assets/vid.mp4";
import { useDispatch, useSelector } from "react-redux";
import { getVideosForArtistAction } from "../../../Redux/Action/Artists";
import { getVideosForVenueAction } from "../../../Redux/Action/Venues";

const Videos = ({ id, roleType }) => {
  const dispatch = useDispatch();
  const videos = useSelector((state) => roleType === "venue" ? state.Venues.venueVideos : state.Artists.artistVideos);
  const loader = useSelector((state) => state.Loading.loading);

  useEffect(() => {
    if (roleType === "venue") {
      dispatch(getVideosForVenueAction(id));
    } else {
      dispatch(getVideosForArtistAction(id));
    }
  }, []);

  return (
    <>
      <div className="media-imgs-sec">
        <div className="container">
          <div className="row">
            {videos?.map((video) => {
              return (
                <div key={video?.id} className="col-md-4 m-0 p-0">
                  <div className=" media-gallary-video">
                    <video className="media-video" src={video?.media} controls />
                  </div>
                </div>
              );
            })} 

            {!loader && !videos.length ? (
              <div className="row">
                <div className="col-md-12 text-center">
                  <p className="text-danger">No data found</p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Videos;
