import Actions from "./Actions";

export const GetAllUpcomingEventsAction = (auth, filter) => {
  return {
    type: Actions.GET_ALL_UPCOMING_EVENT,
    auth,
    filter,
  };
};

export const SaveAllUpcomingEventsAction = (payload) => {
  return {
    type: Actions.SAVE_ALL_UPCOMING_EVENT,
    payload,
  };
};

export const SaveAllUpcomingEventsForLoggedUserAction = (payload) => {
  return {
    type: Actions.SAVE_ALL_UPCOMING_EVENT_LOGGED_USER,
    payload,
  };
};

export const GetAllEventsForLoggedUserAction = (auth, filter) => {
  return {
    type: Actions.GET_ALL_EVENTS_FOR_LOGGED_USER,
    auth,
    filter,
  };
};

export const AddEventAction = (payload, auth, callback) => {
  return {
    type: Actions.ADD_EVENT,
    payload,
    auth,
    callback,
  };
};

export const GettingIndivualEventBasedOnIdAction = (payload, auth) => {
  return {
    type: Actions.GET_INDIVUAL_EVENT_BASED_ON_ID,
    payload,
    auth,
  };
};

export const SaveEventBasedOnId = (payload) => {
  return {
    type: Actions.SAVE_EVENT_BASED_ON_ID,
    payload,
  };
};

export const UpdateEventAction = (payload, auth, id, callback) => {
  return {
    type: Actions.EDIT_UPCOMING_EVENT,
    payload,
    auth,
    id,
    callback,
  };
};

export const DeleteEventAction = (payload, auth, callback) => {
  return {
    type: Actions.DELETE_UPCOMING_EVENT,
    payload,
    auth,
    callback,
  };
};

export const GetBidableEventsForArtist = (auth, artistId) => {
  return {
    type: Actions.GET_ALL_BIDABLE_EVENTS,
    auth,
    artistId,
  };
};

export const GettingEventBasedOnIdPublicAction = (id) => {
  return {
    type: Actions.GET_EVENT_DETAILS_BASED_ON_ID,
    id,
  };
};

export const SaveEventBasedOnIdPublicAction = (payload) => {
  return {
    type: Actions.SAVE_EVENT_DETAILS_BASED_ON_ID,
    payload,
  };
};

export const AddReviewAction = (id, auth, payload, callback) => {
  return {
    type: Actions.ADD_REVIEW,
    id,
    auth,
    payload,
    callback,
  };
};
