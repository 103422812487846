import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EditProfile from "./EditProfile";
import ProfileDetails from "./ProfileDetails";

const Profile = () => {
  const profile = useSelector((state) => state.Auth.profile);
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <section className=" py-5 ">
        <div className="container px-5">
          <div className="row d-flex justify-content-center">
            <div className="col-md-8 card p-4 shadow-sm border border-2 border-black">
              <div className=" d-flex justify-content-between mb-3">
                <h3 className=" text-danger">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      fill="currentColor"
                      className="bi bi-person-bounding-box me-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5z" />
                      <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    </svg>
                  </span>
                  Profile details
                </h3>
                <button onClick={openModal} className=" btn btn-outline-dark">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22  "
                    fill="currentColor"
                    className="bi bi-pencil-square me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fillRule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                  <span>Edit profile</span>
                </button>
              </div>
              <table className="table table-bordered table-responsive">
                <tbody>
                  <ProfileDetails
                    label={"FIRST NAME"}
                    value={profile?.first_name}
                  />
                  <ProfileDetails
                    label={"LAST NAME"}
                    value={profile?.last_name}
                  />
                  <ProfileDetails label={"EMAIL"} value={profile?.email} />
                  <ProfileDetails label={"ADDRESS"} value={profile?.address} />
                  <ProfileDetails label={"CITY"} value={profile?.city} />
                  <ProfileDetails label={"STATE"} value={profile?.state} />
                  <ProfileDetails label={"PHONE"} value={profile?.phone} />
                  <ProfileDetails
                    label={"ZIP CODE"}
                    value={profile?.zip_code}
                  />
                  <ProfileDetails
                    label={"LOOKING FOR LOCAL MUSIC"}
                    value={profile?.looking_for_local_music ? "YES" : "NO"}
                  />
                  <ProfileDetails
                    label={"IAM VANUE WONNER"}
                    value={profile?.venue_owner ? "YES" : "NO"}
                  />
                  <ProfileDetails
                    label={"IAM ARTIST BRAND WONNER"}
                    value={profile?.artist_or_band ? "YES" : "NO"}
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <EditProfile
        profile={profile}
        showModal={showModal}
        closeModal={closeModal}
      />
    </>
  );
};

export default Profile;
