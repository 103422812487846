import React, { useRef, useEffect } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import Img from "../../../Assets/loc.png";
const Map = ({ dataMap }) => {
  let lat = Object.keys(dataMap).length !== 0 ? dataMap?.latitude : null;
  let lng = Object.keys(dataMap).length !== 0 ? dataMap?.longitude : null;
  let latitudeFloat = lat !== null && lat !== 1 ? parseFloat(lat) : 41.6098;
  let longitudeFloat = lng !== null && lng !== 1 ? parseFloat(lng) : -86.7207;
  const mapRef = useRef(null);
  useEffect(() => {
    mapRef.current = L.map("map").setView([latitudeFloat, longitudeFloat], 16);

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: "© OpenStreetMap contributors",
    }).addTo(mapRef.current);

    const customIcon = L.icon({
      iconUrl: Img,
      iconSize: [32, 32],
      iconAnchor: [16, 32],
      popupAnchor: [0, -32],
    });

    L.marker([latitudeFloat, longitudeFloat], { icon: customIcon })
      .addTo(mapRef.current)
      .bindPopup(dataMap?.name)
      .openPopup();

    return () => {
      mapRef.current.remove();
    };
  }, [latitudeFloat, longitudeFloat, dataMap?.name]);
  return <div id="map" style={{ height: "400px", width: "400px" }} />;
};
export default Map;
