import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const Auth = ({ children }) => {
  const authToken = useSelector((state) => state.Auth);
  return <>{authToken.authToken ? children : <Navigate to="/" />}</>;
};

export default Auth;
