import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetMyBidsAction } from "../../../Redux/Action/Bidding";
import AgreementPopup from "../../../Components/Body/Venues/Bids/AgreementPopup";
import IndivualStatus from "./IndivualStatus";
import ConfirmationPopup from "../../../Components/Body/Venues/Bids/Popup";
import Negotiation from "../../../Components/Body/Venues/Bids/Negotiation";
import {
  AcceptGigActionByArtistManager,
  AcceptGigActionByVeneuManger,
  RejectGigActionByArtistManager,
  RejectGigActionByVenueManger,
} from "../../../Redux/Action/Bidding";
import { toast } from "react-hot-toast";

const Mybids = () => {
  const dispatch = useDispatch();
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showNegotiationPopup, setShowNegotiationPopup] = useState(false);
  const [showAgreementPopup, setShowAgreementPopup] = useState(false);
  const [selectedBid, setSelectedBid] = useState(null);
  const authToken = useSelector((state) => state.Auth.authToken);
  const Profile = useSelector((state) => state.Auth.profile);
  const loader = useSelector((state) => state.Loading.loading);
  const myBids = useSelector((state) => state.Bids.allBids);

  //Confirmation Popup
  const openConfirmationPopup = (bidId, artist, venue, type) => {
    setSelectedBid({ bidId, type, venue, artist });
    setShowConfirmationPopup(true);
  };
  const closeConfirmationPopup = () => {
    if (selectedBid.type === "Accept" && Profile.venue_owner === true) {
      dispatch(
        AcceptGigActionByVeneuManger(
          selectedBid,
          authToken,
          (type, message) => {
            toast[type](`${message}`, {
              duration: 5000,
            });
            callMyBidsAction();
          }
        )
      );
    }

    if (selectedBid.type === "Accept" && Profile.artist_or_band === true) {
      dispatch(
        AcceptGigActionByArtistManager(
          selectedBid,
          authToken,
          (type, message) => {
            toast[type](`${message}`, {
              duration: 5000,
            });
            callMyBidsAction();
          }
        )
      );
    }

    if (selectedBid.type === "Reject" && Profile.venue_owner === true) {
      dispatch(
        RejectGigActionByVenueManger(
          selectedBid,
          authToken,
          (type, message) => {
            toast[type](`${message}`, {
              duration: 5000,
            });
            callMyBidsAction();
          }
        )
      );
    }

    if (selectedBid.type === "Reject" && Profile.artist_or_band === true) {
      dispatch(
        RejectGigActionByArtistManager(
          selectedBid,
          authToken,
          (type, message) => {
            toast[type](`${message}`, {
              duration: 5000,
            });
            callMyBidsAction();
          }
        )
      );
    }
    setShowConfirmationPopup(false);
    setSelectedBid({});
  };

  //Negotiation logic
  const openNegotiationPopup = (bidId, artist, venue, type) => {
    setSelectedBid({ bidId, type, artist, venue });
    setShowNegotiationPopup(true);
  };

  const closeNegotiatoinPopup = () => {
    setShowNegotiationPopup(false);
    setSelectedBid({});
    dispatch(GetMyBidsAction(authToken));
  };
  const cancelNegotiationPopup = () => {
    setShowNegotiationPopup(false);
    setSelectedBid({});
  };
  const cancelConfiramtionPopup = () => {
    setShowConfirmationPopup(false);
    setSelectedBid({});
  };

  //Agreement logic
  const openAgreementPopup = (bidId, artist, venue, agreement, type) => {
    setSelectedBid({ bidId, type, artist, agreement, venue });
    setShowAgreementPopup(true);
  };
  const closeAgreementPopup = () => {
    setShowAgreementPopup(false);
    setSelectedBid({});
    dispatch(GetMyBidsAction(authToken));
  };
  const cancelAgreementPopup = () => {
    setShowAgreementPopup(false);
    setSelectedBid({});
  };

  const callMyBidsAction = () => {
    dispatch(GetMyBidsAction(authToken));
  };
  useEffect(() => {
    callMyBidsAction();
  }, []);

  return (
    <>
      <section className="header-tabs mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="tabs-section position-relative">
                <nav className="headr-navs-bg">
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      className="nav-link active"
                      id="Invites-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-invites"
                      type="button"
                      role="tab"
                      aria-controls="nav-invites"
                      aria-selected="true"
                    >
                      Incoming requests
                    </button>
                    <button
                      className="nav-link"
                      id="Pending-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-pending"
                      type="button"
                      role="tab"
                      aria-controls="nav-pending"
                      aria-selected="false"
                    >
                      My Bids
                    </button>
                    <button
                      className="nav-link"
                      id="Ongoing-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-ongoing"
                      type="button"
                      role="tab"
                      aria-controls="nav-ongoing"
                      aria-selected="false"
                    >
                      In Negotiation
                    </button>
                    <button
                      className="nav-link"
                      id="Accepted-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-accept"
                      type="button"
                      role="tab"
                      aria-controls="nav-Accept"
                      aria-selected="false"
                    >
                      Accepted
                    </button>
                    <button
                      className="nav-link"
                      id="Rejected-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-reject"
                      type="button"
                      role="tab"
                      aria-controls="nav-reject"
                      aria-selected="false"
                    >
                      Closed
                    </button>
                    <button
                      className="nav-link"
                      id="Agreements-tabs"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-agreements"
                      type="button"
                      role="tab"
                      aria-controls="nav-agreements"
                      aria-selected="false"
                    >
                      Agreements
                    </button>
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade"
                    id="nav-pending"
                    role="tabpanel"
                    aria-labelledby="Pending-tabs"
                  >
                    <section className="artist-profile-card my-5">
                      <div className="container">
                        <div className="row">
                          {myBids?.map((item) => {
                            if (
                              item.status === "Pending" &&
                              item.bid_by === "venue"
                            ) {
                              return (
                                <IndivualStatus
                                  data={item}
                                  openNegotiationPopup={openNegotiationPopup}
                                  openConfirmationPopup={openConfirmationPopup}
                                  openAgreementPopup={openAgreementPopup}
                                />
                              );
                            }
                            return null;
                          })}

                          {!myBids.some((item) => item.status === "Pending") &&
                          !loader ? (
                            <>
                              <div className="row  ">
                                <div className="col-md-12  text-center">
                                  <p className="text-danger">
                                    No request is send yet.
                                  </p>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </section>
                  </div>
                  <div
                    className="tab-pane fade show active"
                    id="nav-invites"
                    role="tabpanel"
                    aria-labelledby="Invites-tabs"
                  >
                    <section className="artist-profile-card my-5">
                      <div className="container">
                        <div className="row">
                          {myBids?.map((item) => {
                            if (
                              item.status === "Pending" &&
                              item.bid_by === "artist"
                            ) {
                              return (
                                <IndivualStatus
                                  data={item}
                                  openConfirmationPopup={openConfirmationPopup}
                                  openNegotiationPopup={openNegotiationPopup}
                                  openAgreementPopup={openAgreementPopup}
                                />
                              );
                            }
                            return null;
                          })}

                          {!myBids.some((item) => item.status === "Pending") &&
                          !loader ? (
                            <>
                              <div className="row  ">
                                <div className="col-md-12  text-center">
                                  <p className="text-danger">
                                    No request is received yet.
                                  </p>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </section>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-ongoing"
                    role="tabpanel"
                    aria-labelledby="Ongoing-tabs"
                  >
                    <section className="artist-profile-card my-5">
                      <div className="container">
                        <div className="row">
                          {myBids?.map((item) => {
                            if (item.status === "Ongoing") {
                              return (
                                <IndivualStatus
                                  data={item}
                                  openConfirmationPopup={openConfirmationPopup}
                                  openNegotiationPopup={openNegotiationPopup}
                                  openAgreementPopup={openAgreementPopup}
                                />
                              );
                            }
                            return null;
                          })}

                          {!myBids.some((item) => item.status === "Ongoing") &&
                          !loader ? (
                            <>
                              <div className="row  ">
                                <div className="col-md-12  text-center">
                                  <p className="text-danger">No data found.</p>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </section>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-accept"
                    role="tabpanel"
                    aria-labelledby="Accept-tabs"
                  >
                    <section className="artist-profile-card my-5">
                      <div className="container">
                        <div className="row">
                          {myBids?.map((item) => {
                            if (item.status === "Accepted") {
                              return (
                                <IndivualStatus
                                  data={item}
                                  openConfirmationPopup={openConfirmationPopup}
                                  openNegotiationPopup={openNegotiationPopup}
                                  openAgreementPopup={openAgreementPopup}
                                  isAgreement={false}
                                />
                              );
                            }
                            return null;
                          })}

                          {!myBids.some((item) => item.status === "Accepted") &&
                          !loader ? (
                            <>
                              <div className="row  ">
                                <div className="col-md-12  text-center">
                                  <p className="text-danger">No data found.</p>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </section>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-reject"
                    role="tabpanel"
                    aria-labelledby="Rejected-tabs"
                  >
                    <section className="artist-profile-card my-5">
                      <div className="container">
                        <div className="row">
                          {myBids?.map((item) => {
                            if (item.status === "Reject") {
                              return (
                                <IndivualStatus
                                  data={item}
                                  openConfirmationPopup={openConfirmationPopup}
                                  openNegotiationPopup={openNegotiationPopup}
                                  openAgreementPopup={openAgreementPopup}
                                />
                              );
                            }
                            return null;
                          })}

                          {!myBids.some((item) => item.status === "Reject") &&
                          !loader ? (
                            <>
                              <div className="row  ">
                                <div className="col-md-12  text-center">
                                  <p className="text-danger">No data found.</p>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </section>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-agreements"
                    role="tabpanel"
                    aria-labelledby="Agreements-tabs"
                  >
                    <section className="artist-profile-card my-5">
                      <div className="container">
                        <div className="row">
                          {myBids?.map((item) => {
                            if (item.status === "Accepted") {
                              return (
                                <IndivualStatus
                                  data={item}
                                  isAgreement={true}
                                  openConfirmationPopup={openConfirmationPopup}
                                  openNegotiationPopup={openNegotiationPopup}
                                  openAgreementPopup={openAgreementPopup}
                                />
                              );
                            }
                            return null;
                          })}

                          {!myBids.some((item) => item.status === "Accepted") &&
                          !loader ? (
                            <>
                              <div className="row  ">
                                <div className="col-md-12  text-center">
                                  <p className="text-danger">No data found.</p>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ConfirmationPopup
        cancel={cancelConfiramtionPopup}
        showModal={showConfirmationPopup}
        closeModal={closeConfirmationPopup}
        selectedBid={selectedBid}
      />
      <Negotiation
        cancel={cancelNegotiationPopup}
        showModal={showNegotiationPopup}
        closeModal={closeNegotiatoinPopup}
        selectedBid={selectedBid}
      />
      <AgreementPopup
        cancel={cancelAgreementPopup}
        showModal={showAgreementPopup}
        closeModal={closeAgreementPopup}
        selectedBid={selectedBid}
        viewAdmin={"venue"}
      />
    </>
  );
};

export default Mybids;
