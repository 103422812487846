import React, { useState } from "react";
import { Toaster } from "react-hot-toast";
import EventListingForBid from "./EventListingForBid";
import BiddingArtistPopup from "./BidingArtistPopup";

const BidArtistTab = ({ id, artistName }) => {
  const [bidingPopup, setBiddingPopup] = useState(false);
  const [bidData, setBidData] = useState({});

  //Show Calender  Events
  const openBidingPopup = (val) => {
    setBidData({
      artist: id,
      event: val,
      artistName: artistName,
    });
    setBiddingPopup(true);
  };
  const closeBidingPopup = () => {
    setBiddingPopup(false);
  };

  return (
    <>
      <section className="review">
        <div className="container">
          <div className="row py-3">
            <div className="col-md-12">
              <h6>BID on upcoming events</h6>
              <EventListingForBid
                ArtistID={id}
                openBidingPopup={openBidingPopup}
              />
            </div>
          </div>
        </div>
      </section>
      <BiddingArtistPopup
        bidingPopup={bidingPopup}
        closeBidingPopup={closeBidingPopup}
        bidData={bidData}
      />
      <Toaster />
    </>
  );
};

export default BidArtistTab;
