import React from "react";

const HeaderRandomImages = () => {
  return (
    <>
      <section className="bg-dark py-5 headerRandomImages">
        <div className="container px-5">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-6">
              <div className="text-center my-5">
                <div className="d-grid gap-3 d-sm-flex justify-content-sm-center">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeaderRandomImages;