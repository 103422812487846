import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import toast, { Toaster } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { BidOnEventByArtistManager } from "../../../../Redux/Action/Bidding";
import { GetBidableEventsForArtist } from "../../../../Redux/Action/UpcomingEvents";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
};

const BiddingArtistPopup = ({ bidingPopup, closeBidingPopup, bidData }) => {
  const { artist, event } = bidData;
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.Auth.authToken);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const submitHandler = (data) => {
    const formData = new FormData();
    formData.append("venue", event.venue?.id);
    formData.append("artist", artist);
    formData.append("event", event?.id);
    formData.append("artist_band_bidding_amount", data["offer_price"]);
    formData.append("artist_band_message", data["message"]);

    dispatch(
      BidOnEventByArtistManager(formData, authToken, (type, message) => {
        toast[type](message, {
          duration: 500,
        });
        if (type === "success") {
          closeBidingPopup();
          dispatch(GetBidableEventsForArtist(authToken, artist));
        }
      })
    );
  };

  return (
    <>
      <Modal
        open={bidingPopup}
        onClose={closeBidingPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-bid-popup">
          <button
            type="button"
            onClick={closeBidingPopup}
            className="btn close-btn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
            </svg>
          </button>

          <div className="  table-responsive py-4 px-4">
            <h5 className=" text-danger mb-4">BID FOR EVENTS</h5>
            <form onSubmit={handleSubmit(submitHandler)}>
              <div className="position-relative">
                <div className="bid-fields">
                  <label className=" fw-bold px-2">Offered Price</label>

                  <input
                    className="bid-fields-input"
                    placeholder="Price"
                    type={"number"}
                    {...register("offer_price", { required: true })}
                  />
                  {errors["offer_price"] ? (
                    <>
                      <p className="error_register">Please fill this field</p>
                    </>
                  ) : null}
                </div>
              </div>

              <div className="position-relative">
                <div className="bid-fields">
                  <label className=" fw-bold px-2">Message</label>

                  <textarea
                    className="bid-fields-input"
                    placeholder="Message for Event manager"
                    type={"text"}
                    {...register("message", { required: true })}
                  />
                  {errors["message"] ? (
                    <>
                      <p className="error_register">Please fill this field</p>
                    </>
                  ) : null}
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <button type="submit" className="btn btn-danger">
                  Send Offer to Event Manager
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
      <Toaster />
    </>
  );
};
export default BiddingArtistPopup;
