import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ResetPassword, SendOtpAction } from "../../Redux/Action/AuthAction";
import { DisplayValidationErrors } from "../../Utilities";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import LoaderImage from "../../Assets/loader.gif"

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const [hide, setHide] = useState(true);
  const [hideConfirm, setHideConfirm] = useState(true);
  const loader = useSelector((state) => state.Loading.loading);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const submitHandler = (data) => {
    const payload = {
      email: localStorage.getItem("reset_email"),
      password: data.password,
      otp: data.otp,
      confirm_password:data.c_password
    };
    
      dispatch(
      ResetPassword(payload, (type,message) => {
        toast[type](message);
        localStorage.removeItem('reset_email')
      })
    );
  };

  return (
    <section className="login_backround">
      <div className=" container-fluid py-5">
        <div className="row d-flex justify-content-center">
          <div className=" col-md-4">
            <div className="card login-card">
              <div className=" card-title text-center login-head">
                Welcome to BandsOnDemand
              </div>
              <div className=" card-body">
                <form onSubmit={handleSubmit(submitHandler)}>
                  <div className="position-relative">
                    <label>New Password</label>
                    <input
                      className="login-field"
                      placeholder="Password"
                      type={hide ? "password" : "text"}
                      {...register("password", {
                        required: true,
                        pattern:
                          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/,
                      })}
                    />

                    {!hide ? (
                      <span
                        onClick={() => {
                          setHide(true);
                        }}
                        className="pass-icon"
                      >
                        <i className="fa-solid fa-eye fa-sm"></i>
                      </span>
                    ) : (
                      <span
                        onClick={() => {
                          setHide(false);
                        }}
                        className="pass-icon"
                      >
                        <i className="fa-solid fa-eye-slash fa-sm"></i>
                      </span>
                    )}

                    {errors?.password?.type == "required" ? (
                      <DisplayValidationErrors message="Please fill this field" />
                    ) : null}
                    {errors?.password?.type == "pattern" ? (
                      <DisplayValidationErrors
                        message="Password must have Minimum eight characters, at
                              least one letter and one number and one sepcial
                              character."
                      />
                    ) : null}
                  </div>

                  <div className="position-relative">
                    <label>Confirm Password</label>
                    <input
                      className="login-field"
                      placeholder="New Password"
                      type={hideConfirm ? "password" : "text"}
                      {...register("c_password", {
                        required: true,
                        validate: (val) => {
                          if (watch("password") != val) {
                            return "Your password not match";
                          }
                        },
                      })}
                    />
                   {errors["c_password"]?.type == "validate" ? (
                            <DisplayValidationErrors message="Password not matched" />
                          ) : null}
                          {errors["c_password"]?.type == "required" ? (
                            <DisplayValidationErrors message="Please fill this field" />
                          ) : null}
                    {!hideConfirm ? (
                      <span
                        onClick={() => {
                          setHideConfirm(true);
                        }}
                        className="pass-icon"
                      >
                        <i className="fa-solid fa-eye fa-sm"></i>
                      </span>
                    ) : (
                      <span
                        onClick={() => {
                          setHideConfirm(false);
                        }}
                        className="pass-icon"
                      >
                        <i className="fa-solid fa-eye-slash fa-sm"></i>
                      </span>
                    )}
                  </div>

                  <div>
                    <label>OTP</label>
                    <input
                      placeholder="OTP code"
                      className="login-field"
                      type="number"
                      {...register("otp", { required: true })}
                    />
                    {errors["otp"] ? (
                      <DisplayValidationErrors message="Please fill this field" />
                    ) : null}
                  </div>

                  <div>
                    <button disabled={loader} type="submit" className="login-btn">
                      {loader?<img src={LoaderImage} alt="loader Img" className="loader-image"/> :"Reset password" }
                     
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </section>
  );
};

export default ForgetPassword;
