import React from "react";
import GetInTouchSection from "./GetInTouchSection";
import HeroSection from "./HeroSection";
import Cards from "./Cards";
import Benifits from "./Benifits";
import Testimonial from "./Testimonial";
const LandingPage = () => {
  return (
    <>
      <HeroSection />
      <Cards />
      <Benifits />
      <Testimonial />
      <GetInTouchSection />
    </> 
  );
};

export default LandingPage;
