import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { DisplayValidationErrors, errorMessages, InputNames, Constants } from "../../../Utilities";
import { EditUserProfile, GetUserProfileFromApi } from "../../../Redux/Action/ProfileAction";
import toast, { Toaster } from "react-hot-toast";
import LoaderImg from "../../../Assets/loader.gif"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function EditProfile({ showModal, closeModal, profile }) {
  const dispatch = useDispatch()
  const loader = useSelector((state) => state.Loading.loading);
  const authToken = useSelector(state=>state.Auth.authToken)
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fname: profile.first_name,
      lname: profile.last_name,
      address: profile.address,
      city: profile.city,
      state: profile.state,
      zip_code: profile.zip_code,
      phone: profile.phone,
      email: profile.email,
      artist_or_band: profile.artist_or_band,
      venue_owner: profile.venue_owner,
      looking_for_local_music: profile.looking_for_local_music,
    },
  });

  const submitHandler = (data) => {
    const payload = {
      email: data.email,
      first_name: data.fname,
      last_name: data.lname,
      address: data.address,
      city: data.city,
      state: data.state,
      zip_code: data.zip_code,
      phone: data.phone,
      artist_or_band: data.artist_or_band,
      venue_owner: data.venue_owner,
      looking_for_local_music: data.looking_for_local_music,
    };
    dispatch(EditUserProfile(payload,authToken,(type,message)=>{
      toast[type](message);
      closeModal()
      dispatch(GetUserProfileFromApi(authToken))
    }))
    
  };

  return (<>
    <Modal
      open={showModal}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="modal-form">
        <form onSubmit={handleSubmit(submitHandler)}>
          <>
            <button
              type="button"
              onClick={closeModal}
              className="btn close-btn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </button>
            <div className="row">
              <div className="col-md-6">
                <label>First name</label>
                <input
                  placeholder="First name"
                  className="login-field"
                  type="text"
                  {...register("fname", { required: true })}
                />
                {errors["fname"] ? (
                  <DisplayValidationErrors message={errorMessages.FILL_FIELD}/>
                ) : null}
              </div>

              <div className="col-md-6">
                <label>Last name</label>
                <input
                  placeholder="Last name"
                  className="login-field"
                  type="text"
                  {...register("lname", { required: true })}
                />
                {errors["lname"] ? (
                  <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
                ) : null}
              </div>

              <div className="col-md-6">
                <label>Email</label>
                <input
                  disabled
                  placeholder="Email"
                  className="login-field"
                  type="email"
                  {...register("email", { required: true })}
                />
                {errors["email"] ? (
                  <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
                ) : null}
              </div>

              <div className="col-md-6">
                <label>Phone number</label>
                <input
                  placeholder="Phone number"
                  className="login-field"
                  type="number"
                  {...register("phone", {
                    required: true,
                    minLength: 10,
                    maxLength: 10,
                  })}
                />
                {errors?.phone?.type == "required" ? (
                  <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
                ) : null}
                {errors?.phone?.type == "maxLength" ||
                errors?.phone?.type == "minLength" ? (
                  <DisplayValidationErrors message={errorMessages.VALID_NUMBER} />
                ) : null}
              </div>

              <div className="col-md-6">
                <label>Address</label>
                <input
                  placeholder="Address"
                  className="login-field"
                  type="text"
                  {...register("address", { required: true })}
                />
                {errors["address"] ? (
                  <DisplayValidationErrors message={errorMessages.FILL_FIELD}/>
                ) : null}
              </div>

              <div className="col-md-6">
                <label>City</label>
                <input
                  placeholder="City"
                  className="login-field"
                  type="text"
                  {...register("city", { required: true })}
                />
                {errors["city"] ? (
                  <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
                ) : null}
              </div>

              <div className="col-md-6">
                <label>State</label>
                <input
                  placeholder="State"
                  className="login-field"
                  type="text"
                  {...register("state", { required: true })}
                />
                {errors["state"] ? (
                  <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
                ) : null}
              </div>

              <div className="col-md-6">
                <label>Zip code</label>
                <input
                  placeholder="Zip code"
                  className="login-field"
                  type="number"
                  {...register("zip_code", { required: true })}
                />
                {errors["zip_code"] ? (
                  <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
                ) : null}
              </div>
            </div>
            
            <div className="ps-1 pb-1 mt-2 mb-3">
              <div className="position-relative d-flex align-items-baseline">
                <input
                  type="checkbox"
                  defaultChecked
                  checked
                  onClick={(e) => e.preventDefault()}
                  {...register("looking_for_local_music")}
                />
                <span className="signin-check-box-lable">{Constants.LOOKING_FOR_LOCAL_MUSIC}</span>
              </div>
              
              <div className="position-relative d-flex align-items-baseline">
                <input
                  type="checkbox"
                  {...register("venue_owner", { required: false })}
                />
                <span className="signin-check-box-lable">{Constants.VANUE_WONNERS}</span>
              </div>
              
              <div className="position-relative d-flex align-items-baseline">
                <input
                  type="checkbox"
                  {...register("artist_or_band", { required: false })}
                />
                <span className="signin-check-box-lable">{Constants.ARTIST_BANDS}</span>
              </div>
            </div>
          </>

          <div className="submission-form-popup">
            <button disabled={loader} type="submit" className="login-btn ">
           {loader ? <img className="loader-image" src={LoaderImg} /> : "Update"} 
            </button>

            <button onClick={closeModal} type="button" className="login-btn">
              Cancel
            </button>
          </div>
        </form>
      </Box>
      
    </Modal>
    <Toaster />
    </>
  );
}
export default EditProfile;
