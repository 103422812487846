import React from "react";
import DummyImage from "../../../Assets/noimg.jpg";
import moment from "moment";
import { Link } from "react-router-dom";

const IndivualEvents = ({ events }) => {
    
  return (
    <div className="col-md-4">
      <div className="upcoming-event-card mt-5">
        <div className="artist-img">
          <img
            src={`${events?.image ? events?.image : DummyImage}`}
            alt="Image"
          />
        </div>
        <div className="artist-info">
          <h6 className="card-title text-capitalize mb-3">
            {events?.artist?.name ? events.artist.name:"NA"}
          </h6>
          <p className="mt-0 mb-1">
            <span className="font-weight-bold">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-alarm-fill me-3"
                viewBox="0 0 16 16"
              >
                <path d="M6 .5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1H9v1.07a7.001 7.001 0 0 1 3.274 12.474l.601.602a.5.5 0 0 1-.707.708l-.746-.746A6.97 6.97 0 0 1 8 16a6.97 6.97 0 0 1-3.422-.892l-.746.746a.5.5 0 0 1-.707-.708l.602-.602A7.001 7.001 0 0 1 7 2.07V1h-.5A.5.5 0 0 1 6 .5zm2.5 5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5zM.86 5.387A2.5 2.5 0 1 1 4.387 1.86 8.035 8.035 0 0 0 .86 5.387zM11.613 1.86a2.5 2.5 0 1 1 3.527 3.527 8.035 8.035 0 0 0-3.527-3.527z" />
              </svg>
            </span>
            {moment(events?.start_time).format("MMMM Do YYYY, h a")}
          </p>
          <p className="">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-geo-alt-fill me-3"
                viewBox="0 0 16 16"
              >
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
              </svg>
            </span>
            {events?.venue?.address ? events.venue.address : "NA"}
          </p>
          <p className="">{events?.description}</p>
          <Link to={`/event-details/${events.id}`} className="btn btn-light btn-sm">
            View more
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-right"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
              />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default IndivualEvents;
