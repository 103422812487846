import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../Assets/spinner.svg";
import {
  DeleteEventAction,
  GetAllEventsForLoggedUserAction,
  GettingIndivualEventBasedOnIdAction,
} from "../../../Redux/Action/UpcomingEvents";
import EditEvents from "./EditEvent";
import DeleteEvent from "./DeleteEvent";
import { Toaster, toast } from "react-hot-toast";
import MyIndivualEvents from "../../../Components/Body/UpcomingEvents/MyIndivualEvent";

const UpcomeEvents = () => {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const EventList = useSelector(
    (state) => state.Events.indivualUserUpcomingEvents
  );
  const loader = useSelector((state) => state.Loading.loading);
  const authToken = useSelector((state) => state.Auth.authToken);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedVenueId, setSelectedVenueId] = useState(null);
  const [filter, SetFilters] = useState({
    genre__id__in: "",
    venue__id__in: "",
    venue__venue_type__in: "",
    food_beverage__in: "",
    search: "",
    from: "",
    to: "",
    radius: "",
    review_rating: 0,
    rate_from: "",
    rate_to: "",
  });

  const handleFilter = (e) => {
    const { name, value } = e.target;
    SetFilters({ ...filter, [name]: value });
  };

  const SubmitFilters = () => {
    dispatch(GetAllEventsForLoggedUserAction(authToken, filter));
  };

  //EDIT Event modal
  const openEditModal = (eventId) => {
    dispatch(GettingIndivualEventBasedOnIdAction(eventId, authToken));
    setShowEditModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    dispatch(GetAllEventsForLoggedUserAction(authToken, {}));
  };
  const cancelEditModal = () => {
    setShowEditModal(false);
  };

  //DELETE venue modal

  const openDeleteModal = (eventId) => {
    setSelectedVenueId(eventId);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    dispatch(
      DeleteEventAction(selectedVenueId, authToken, (type, message) => {
        toast[type](`${message}`, {
          duration: 5000,
        });
        if (type === "success")
          dispatch(GetAllEventsForLoggedUserAction(authToken, {}));
      })
    );
  };
  const cancelDeleteModal = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    dispatch(GetAllEventsForLoggedUserAction(authToken, {}));
  }, []);

  return (
    <>
      {loader ? (
        <div className="spinner-loader">
          <img className="spinner" src={Spinner} alt="" />
        </div>
      ) : null}

      <section className=" py-5 artist-list ">
        <div className="container-fluid px-5">
          <div className="row">
            <div className="col-md-6 artist-heading">
              <h3 className="text-danger">Events</h3>
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <input
                type="search"
                placeholder="Search"
                name="search"
                onChange={handleFilter}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    SubmitFilters();
                  }
                }}
                className=" form-control event-search-input"
              />
              <button
                type="button"
                onClick={SubmitFilters}
                className="btn event-search-icon"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </button>
            </div>
          </div>
          {EventList.length && !loader ? (
            <div className="inner-artists mt-4">
              <div className="row">
                {EventList?.map((item) => {
                  return (
                    <MyIndivualEvents
                      openDeleteModal={openDeleteModal}
                      openEditModal={openEditModal}
                      key={item.id}
                      events={item}
                    />
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
        <Toaster />
      </section>

      {!loader && EventList.length === 0 ? (
        <div className="row  ">
          <div className="col-md-12  text-center">
            <h4>No data found</h4>
          </div>
        </div>
      ) : null}

      {loader ? (
        ""
      ) : (
        <EditEvents
          cancel={cancelEditModal}
          showModal={showEditModal}
          closeModal={closeEditModal}
        />
      )}

      <DeleteEvent
        cancel={cancelDeleteModal}
        showModal={showDeleteModal}
        closeModal={closeDeleteModal}
      />
    </>
  );
};

export default UpcomeEvents;
