import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAudiosForArtistAction } from "../../../Redux/Action/Artists";
import { getAudiosForVenueAction } from "../../../Redux/Action/Venues";

const Audio = ({ id, roleType }) => {
  const dispatch = useDispatch();
  const audios = useSelector((state) =>
    roleType === "venue" ? state.Venues.venueAudios : state.Artists.artistAudios
  );
  const loader = useSelector((state) => state.Loading.loading);

  useEffect(() => {
    if (roleType === "venue") {
      dispatch(getAudiosForVenueAction(id));
    } else {
      dispatch(getAudiosForArtistAction(id));
    }
  }, []);
  return (
    <>
      <div className="media-imgs-sec">
        <div className="container">
          <div className="row">
            {audios?.map((audio) => {
              return (
                <div className="col-md-4 m-0 p-0">
                  <div className="media-gallary-audio">
                    <div className="player">
                      <div className="imgbx">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="50"
                          width="50"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="#fff"
                            d="M256 80C149.9 80 62.4 159.4 49.6 262c9.4-3.8 19.6-6 30.4-6c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48c-44.2 0-80-35.8-80-80V384 336 288C0 146.6 114.6 32 256 32s256 114.6 256 256v48 48 16c0 44.2-35.8 80-80 80c-26.5 0-48-21.5-48-48V304c0-26.5 21.5-48 48-48c10.8 0 21 2.1 30.4 6C449.6 159.4 362.1 80 256 80z"
                          />
                        </svg>
                      </div>
                      <audio controls>
                        <source src={audio?.media} />
                      </audio>
                    </div>
                  </div>
                </div>
              );
            })}

            {!loader && !audios.length ? (
              <div className="row">
                <div className="col-md-12 text-center">
                  <p className="text-danger">No data found</p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Audio;
